import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { deTranslations } from './i18n/de';
import { enTranslations } from './i18n/en';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: false,
    fallbackLng: 'de',
    load: 'languageOnly',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    detection: {
      order: ['localStorage', 'path', 'navigator'],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
      caches: ['localStorage'],
      lookupLocalStorage: 'i18nextLng'
    },
    resources: {
      de: {
        translation: deTranslations
      },
      en: {
        translation: enTranslations
      }
    }
  });

export default i18n;