export const enTranslations = {
  general: {
    siteTitle: 'Your Online Laundry & Tailoring | WÖS.CH',
  },
  "home": {
    "metaTitle": "Your Online Laundry & Tailoring | WÖS.CH",
    "metaDescription": "WÖS.CH is your online laundry, textile cleaning and tailoring service in Zurich - We pick up your laundry within 24h & deliver it back - whenever you want.",
    "heroImageAlt": "WÖS.CH Hero Image",
    "logoAlt": "WÖS.CH Logo",
    "heroTitle": "Collect Moments",
    "heroSubtitle": "(not dirty laundry)",
    "heroText1": "Your online laundry, textile cleaning and tailoring service in Zurich.",
    "heroText2": "Pick-up and delivery within 24 hours, whenever you want.",
    "ctaBasket": "Fill Laundry Basket",
    "ctaHowItWorks": "How It Works",

    "howItWorksTitle": "How It Works",
    "howItWorksStep1Title": "Order",
    "howItWorksStep1Text": "Place your order from wherever you are. You decide the day and time of pickup and delivery. Secure payments with all common payment methods like Twint & credit card.",
    "howItWorksStep2Title": "Pick-up",
    "howItWorksStep2Text": "Pick-up whenever you want. Prepare your laundry and accessories. Your butler is always ready.",
    "howItWorksStep3Title": "Service",
    "howItWorksStep3Text": "We care for your high-quality laundry, clothing and accessories with environmentally friendly, high-quality and individually tailored processes.",
    "howItWorksStep4Title": "Delivery",
    "howItWorksStep4Text": "Delivery whenever you want. We always have an open ear for your wishes and look forward to your feedback.",
    "showProducts": "Show Products",

    "whyTitle": "Why wös.ch?",
    "whySubtitle": "wös.ch is human and extremely digital at the same time.",
    "whyImageAlt": "Why WÖS.CH Illustration",
    "whyText1": "Your digital butler is within reach and offers you a unique and emotional service experience with a strong human side centered around you: Everything revolves around you! We give you time for the beautiful things in life. Because we take care of your clothes and accessories.",
    "whyText2": "Let us pamper you:",
    "whyText3": "With a simple click, you tell us 'what to do when and where'. We give your clothes and accessories 'tailored care' because they are as close to our hearts as they are to yours. Swiss craftsmanship with flair and attention to detail. wös.ch is a service from people for people, where technology is used as an instrument of facilitation but never replaces the dynamics of listening and interpersonal relationships: The human touch remains the key to our unique, excellent and individual service offering.",

    "serviceTitle": "Incomparable service whenever you want",
    "serviceDescription": "We offer you an environmentally sustainable, individual quality service with attention to detail. You place the order whenever and wherever you want, at home, on the go, from the office or at the laundry. Pick-up and delivery from 7:00 AM to 7:00 PM, six days a week, whenever you want. Real-time order tracking. Secure payments. Your trusted concierge!",

    "features": {
      "quality": {
        "title": "Quality",
        "text": "We pay attention to every little detail to provide you with the best possible individual service."
      },
      "green": {
        "title": "Green",
        "text": "Ecological sustainability through the use of 100% renewable energy, biodegradable detergents and recyclable packaging."
      },
      "smart": {
        "title": "Smart",
        "text": "Our service is always available mobile, no matter where you are, whether at home, in the office or at the gym."
      },
      "hours24": {
        "title": "24 Hours",
        "text": "Pick-up and delivery within 24 hours on six days of the week at the time of your choice. Our all-round carefree service for you!"
      }
    },

    "history": {
      "title": "Our History",
      "text": "wös.ch is an online project for personal services, such as laundry and alteration and repair tailoring, which was launched in 2018. First, we were looking for someone who would reliably, simply and punctually take care of our laundry and accessories, and today we do the same with lots of love for you. We always offer you the best quality in line with your values and requirements. Our service has a strong human and social side: handcraft work with attention to the smallest details, use of biodegradable detergents and materials, delivery with environmentally friendly means of transport, individual concierge service and much more. Our constant cooperation with our carefully selected partners and suppliers enables us to guarantee high standards, individual service and attention to every little detail. Our mission at wös.ch is to create a unique service experience where passion, care for people, attention to quality and eye for detail come to the fore."
    },

    "values": {
      "title": "Our Values",
      "description": "Our values represent our culture, our attitude and our dedication to what we do. These values help us to always be what we want to be and make the right decisions: From the laundry we wash, to handling acceptance and output, to the online experience we offer our customers.",
      "valuesImageAlt": "Our Values Illustration",
      "value1": {
        "title": "Create Things and Solutions",
        "text": "with added value for everyone: for the customer, for the company, for the community"
      },
      "value2": {
        "title": "Think Sustainably,",
        "text": "create, manage and decide for lasting sustainability in everything we do"
      },
      "value3": {
        "title": "Stay Human",
        "text": "and always develop and maintain authentic, individual personal relationships"
      },
      "value4": {
        "title": "Set a Sign for Solid Work,",
        "text": "devise creative and intelligent solutions, always surpass yourself"
      }
    }
  },
  "header": {
    "logoAlt": "WÖS.CH Logo",
    "nav": {
      "howItWorks": "How It Works",
      "services": "Services",
      "prices": "Prices",
      "forCompanies": "For Companies",
      "ecoFriendly": "Eco-Friendly",
      "contact": "Contact"
    },
    "products": "Products",
    "showProducts": "Show Products",
    "account": {
      "yourAccount": "Your Account",
      "viewProfile": "View Profile",
      "adminOrders": "Admin - Orders",
      "adminUsers": "Admin - Users",
      "logout": "Logout"
    },
    "cart": {
      "laundryBasket": "Laundry Basket"
    }
  },
  "footer": {
    "logoAlt": "WÖS.CH Logo",
    "links": {
      "terms": "Terms",
      "privacy": "Privacy",
      "imprint": "Imprint",
      "faq": "FAQ"
    },
    "hours": {
      "title": "Opening Hours",
      "weekdays": "Monday — Friday",
      "weekdayHours": "06:00 — 18:00",
      "saturday": "Saturday",
      "saturdayHours": "09:00 — 15:00",
      "online": "www.wös.ch",
      "onlineHours": "24/7"
    },
    "payments": {
      "mastercard": "Mastercard",
      "visa": "Visa",
      "postfinance": "PostFinance",
      "twint": "TWINT",
      "paypal": "PayPal",
      "invoice": "Invoice",
      "prepaidCard": "Prepaid Card",
      "giftCard": "Gift Card"
    }
  },
  services: {
    meta: {
        title: "This Service Awaits You | WÖS.CH",
        description: "Fast laundry & tailoring service in one, designed for you. Here you'll learn which services are available to you.",
        image: "https://www.wös.ch/images/buegeleisen.png",
        url: "https://www.wös.ch/services"
    },
    hero: {
        title: "your butler",
        text1: "Ring for the butler, whenever and wherever you want.",
        text2: "Fast laundry and tailoring service in one, designed for you.",
        text3: "Call your butler via smartphone, tablet or computer.",
        imageAlt: "Your Butler Illustration"
    },
    laundry: {
        title: "Laundry",
        subtitle: "Eco-friendly Care",
        textHeader: "We love your clothes just as much as you do.",
        text: "We carefully care for your laundry with biodegradable detergents, renewable energy and recyclable packaging. An environmentally friendly decision for your skin and our world!",
        imageAlt: "Laundry Service"
    },
    ironing: {
        title: "Ironing Service",
        subtitle: "Wrinkle-free Perfection",
        textHeader: "With us, luxury is tangible.",
        text: "Impeccably ironed with the highest quality and attention to every detail — we guarantee a unique and high-quality result. Experience the beauty of perfection!",
        imageAlt: "Ironing Service"
    },
    tailoring: {
        title: "Tailoring",
        subtitle: "Precision down to the smallest detail",
        textHeader: "We work with an eye for every detail.",
        text: "Perfect adjustment of your clothes through high-precision tailoring. Hems, zippers, seams, monogram embroidery and button replacement. The fine difference lies in the detail!",
        imageAlt: "Tailoring Service"
    },
    boxes: {
        simple: {
            title: "simple",
            text: "With just a few clicks via smartphone, tablet or computer, wherever you are! We'll take care of the rest.",
            image: "smart",
            imageAlt: "Simple Icon"
        },
        comfortable: {
            title: "comfortable",
            text: "Pick-up and delivery in Zurich within 24 hours. Wherever and whenever you want.",
            image: "bequem",
            imageAlt: "Comfortable Icon"
        },
        topPrice: {
            title: "top price",
            text: "We guarantee you an excellent price-performance ratio.",
            image: "preise-leistungs",
            imageAlt: "Top Price Icon"
        },
        helpful: {
            title: "helpful",
            text: "We look forward to your feedback, suggestions and wishes. We're here to provide you with the best service!",
            image: "hilfsbereit",
            imageAlt: "Helpful Icon"
        }
    }
  },
  forCompanies: {
    metaTitle: 'The Laundry Service for Your Company | WÖS.CH',
    metaDescription: 'Extremely favorable conditions for companies - A concierge service that is available 24/7, without additional costs.',
    title: 'wös.ch for companies',
    subtitle: 'We offer your employees a concierge service that is available 24/7 via\nsmartphone, tablet or computer.',
    description1: 'Agreements with extremely favorable conditions, with pickup and delivery directly at the office or at home.',
    description2: 'There are no additional costs for the company.',
    requestAgreement: 'Request an agreement',
    emailPlaceholder: 'Email*',
    send: 'SEND',
    sending: 'SENDING...',
    sent: 'SENT',
    invalid: 'INVALID',
    error: 'ERROR'
  },
  ecoFriendly: {
    metaTitle: 'Our Laundry is Eco-Friendly | WÖS.CH',
    metaDescription: 'From biodegradable detergents & packaging to working with renewable energy – WÖS.CH is Eco-Friendly!',
    title: 'Our laundry is eco-friendly!',
    subtitle: 'An important decision for your skin and our world!',
    description1: 'We work environmentally friendly. Because we use biodegradable detergents and care for the environment. In everything we do.',
    description2: 'Your laundry and clothing have the highest priority with us, we care for them with lots of love while being mindful of the world we live in. As we always act with prudence, we have succeeded in making all our activities environmentally friendly.',
    description3: 'In our environmentally friendly laundry, you, your clothes, and the environment are the focus. We care for your laundry carefully, with renewable energies and biodegradable detergents and packaging.',
    description4: 'Because only respect for the entire ecosystem enables us to learn to live sustainably without disturbing nature\'s balance. Therefore, every person should feel responsible for a healthier environment.',
    description5: 'For this exact reason, we at wös.ch want to lead by example and orient ourselves on the model of natural systems that are able to efficiently regulate themselves.'
  },
  contact: {
    metaTitle: 'Questions? Contact Us | WÖS.CH',
    metaDescription: 'Do you have questions or are you just curious? Contact us now by email or phone.',
    title: 'Hello!',
    description: 'If you have questions or are just curious and want to learn more,\nfeel free to write to us at info@woes.ch or call us at 044 304 22 98.\nOr simply visit our social channels.',
    form: {
      title: 'Request Quote',
      firstName: 'First Name',
      lastName: 'Last Name',
      email: 'Email Address',
      phone: 'Phone',
      selectService: 'Select Service',
      comment: 'Comment',
      requestQuote: 'Request Quote',
      sending: 'Sending...',
      sent: 'Successfully sent',
      services: {
        laundry: 'Laundry',
        tailoring: 'Tailoring',
        other: 'Other'
      },
      errors: {
        lastNameRequired: 'Last name is required',
        emailRequired: 'Email is required',
        invalidEmail: 'Invalid email address',
        nameRequired: 'Name is required',
        messageRequired: 'Comment is required',
        submitError: 'Something went wrong, please try again later'
      }
    }
  },
  products: {
    metaTitle: 'From Shirts to Daily Laundry - Create Your Order | WÖS.CH',
    metaDescription: 'In our shop overview, you can easily put together your personal order with dozens of different garments and textiles.',
    title: 'Choose your laundry',
    helpText: 'Here you will find a list of all our washable textiles. After adding, you can set how you want your textile to be treated exactly - laundry, cleaning and tailoring. If an article has several variants, you can choose your material after adding it so that we can treat your laundry in the best possible way.',
    showFullHelp: 'Show full explanation',
    searchPlaceholder: 'Search...',
    search: 'Search',
    reset: 'reset',
    proceedToCart: 'Proceed to Cart'
  },
  productCard: {
    addToCart: 'Add to Cart',
    addedToCart: 'Added to Cart',
    configure: 'Configure',
    productImage: 'Product',
    variants: '{{count}} variants'
  },
  categories: {
    "all": "All",
    "clothing": "Clothing",
    "outdoor-sports": "Outdoor / Sports",
    "festive-wear": "Festive Wear",
    "accessories": "Accessories",
    "home-textiles": "Home Textiles",
    "leather": "Leather",
    "curtain-cleaning": "Curtain Cleaning",
    "pets": "Pets",
    "working-clothes": "Working Clothes",
    "childrens-clothing": "Children's Clothing 0-10 Years",
    "gift-card": "Gift Card"
  },
  cart: {
    overlay: {
      productAdded: "Product added.",
      configureProduct: "Here you can configure your item.",
      configureTitle: "Configure Product",
      configureSubtitle: "Choose your desired service",
      addMore: "+ Add more items",
      toBasket: "Continue to cart",
      addToBasket: "Add to cart"
    },
    notes: {
      title: "Note",
      cancel: "CANCEL",
      confirm: "CONFIRM"
    },
    perKilo: {
      title: "Only includes following items",
      content: "Bath towels/towels, bathrobes, tea towels and kitchen towels, undershirts, underwear, bras, socks, tights, leggings, swimwear, bodies, pyjamas (cotton), training bottoms, training bottoms"
    },
    title: 'Cart',
    subtotal: 'Subtotal',
    emptyCart: 'Your cart is empty, add items to your order now!',
    addMoreItems: 'Add more items',
    proceedToDelivery: 'Choose Pickup and Delivery',
    proceedToPayment: 'Proceed to Payment',
    sections: {
      laundry: 'Laundry',
      tailoring: 'Tailoring',
      delivery: 'Delivery',
      variant: 'Variant',
      surcharge: 'Surcharge'
    },
    options: {
      noLaundry: 'Only Tailoring',
      noLaundryTitle: 'Tailoring',
      washAndIron: 'Wash & Iron',
      washAndIronExpress: 'Wash & Iron Express',
      cleanAndRefresh: 'CLEAN AND REFRESH',
      cleanNotWash: 'Clean & Iron',
      button: 'Button',
      hem: 'Hem',
      zipper: 'Zipper',
      folded: 'Folded',
      onHanger: 'On Hanger',
      withDeco: 'WITH DECORATION',
      withSequins: 'WITH RHINESTONES, SEQUINS',
      napa: 'NAPPA - AND NAPPALAN',
      velour: 'VELOUR',
      plated: 'with Pleats',
      washOnly: 'Wash only, not ironed'
    },
    quantity: {
      label: 'Quantity',
      m2: 'm2',
      noScale: {
        title: 'No scale at hand?',
        description: 'No problem! We weigh your laundry and only charge you for the effective weight. If your estimate was too high, you get money back - if it was too low, you can easily pay the missing amount.'
      }
    },
    totals: {
      orderAmount: 'Order Amount',
      shippingCosts: 'Shipping Costs',
      shippingNote: 'Free pickup and delivery for orders over CHF 30.00',
      totalWithShipping: 'Total including shipping',
      usedCredits: 'Used credits and vouchers',
      remainingCredit: 'Remaining credit',
      finalAmount: 'Amount to pay'
    },
    bonus: {
      title: 'Voucher, Gift Card, Prepaid Card',
      placeholder: 'Enter code',
      confirm: 'CONFIRM',
      sending: 'SENDING...',
      invalid: 'Invalid code'
    },
    promo: {
      title: 'Promo Code',
      placeholder: 'Got a promo code? Please enter it here',
      alreadyUsed: 'You have already used this promo code',
      invalid: 'Invalid promo code'
    },
    checkout: {
      pickup: {
        title: 'Pickup',
        inStore: 'Drop off in store',
        chooseDate: 'Choose date',
        chooseTime: 'Choose time'
      },
      delivery: {
        title: 'Delivery',
        inStore: 'Pick up in store',
        chooseDate: 'Choose date',
        chooseTime: 'Choose time'
      },
      payment: {
        title: 'Payment',
        confirm: 'CONFIRM AND PAY',
        processing: 'Processing...',
        success: 'Success!',
        error: 'Payment error'
      },
      dateTime: {
        title: 'Date and Time Pickup',
        close: 'Close',
        morning: 'Morning',
        afternoon: 'Afternoon',
        evening: 'Evening'
      },
      summary: {
        title: 'Order Summary',
        items: 'Items',
        total: 'Total'
      },
      steps: {
        cart: 'Cart',
        pickup: 'Pickup',
        delivery: 'Delivery',
        payment: 'Payment'
      },
      address: {
        info: 'Info: please confirm pickup before setting delivery!',
        confirm: 'CONFIRM',
        change: 'CHANGE',
        edit: 'Edit',
        title: 'Edit Address',
        pickupLocation: 'Pickup location (e.g. Office, House..)',
        locationPlaceholder: 'Address',
        street: 'Street/Square and house number',
        streetPlaceholder: 'Street',
        phone: 'Phone',
        phonePlaceholder: 'Phone',
        city: 'City',
        cityPlaceholder: 'City',
        note: 'Note',
        notePlaceholder: 'Note',
        date: 'Date',
        from: 'From',
        to: 'To',
        submit: 'SUBMIT',
        cancel: 'CANCEL',
        validation: {
          required: 'This field is required',
          invalidPhone: 'Invalid phone number',
          invalidPostcode: 'Invalid postal code'
        }
      }
    },
    removeItem: 'Remove item',
    free: 'Free',
    delivery: {
      differentTimes: 'Your cart contains items that could have different processing times.\nDo you prefer a combined delivery or two separate deliveries?',
      combined: 'Combined Delivery',
      separate: 'Separate Delivery',
      itemCount: 'Delivery: {{count}} items'
    },
    giftCard: {
      title: 'Gift your loved ones the services of wös.ch!',
      recipientName: 'First and last name of the person receiving the gift',
      email: 'Email',
      emailCheck: 'Repeat email',
      message: 'Personal message',
      instructions: 'Fill out the form with the name and email address of the person who will receive the gift and enter a personal message.',
      confirm: 'Confirm',
      change: 'Change',
      errors: {
        nameRequired: 'First and last name is required',
        emailRequired: 'Email is required',
        invalidEmail: 'Invalid email address',
        emailCheckRequired: 'Repeated email is required',
        emailMismatch: 'The entered emails do not match',
        messageRequired: 'The comment is required'
      }
    },
    laundry: {
      cleanAndRefreshInfo: 'With our innovative deep cleaning processes, your furs are preserved in the best possible way and the shape of these noble garments is maintained. Without any residues, irritants or odors.',
      washAndIronInfo: 'Handcrafted quality in environmentally friendly processing and attention to detail, excellent value for money.',
      expressInfo: 'Pickup & delivery of your clothes within one day for additional CHF 5.00 per item. Offer valid only for the city of Zurich.',
      noLaundryInfo: 'We offer you high-precision tailoring work. Shortening, zipper and button replacement. Only cleaned clothes can be handed in for tailoring.'
    }
  },
  howItWorks: {
    meta: {
      title: 'How the laundry service works | WÖS.CH',
      description: 'In four simple steps from placing your order to delivering your freshly washed & tailored laundry if needed.'
    },
    header: {
      title: 'Carefree all around',
      description: 'Laundry and tailoring service in one, designed for you.\nDrop-off and pick-up whenever you want.\nQuality, comfort, elegance. We give you a way of life.'
    },
    tryNow: 'TRY NOW !',
    steps: {
      order: {
        title: 'Order',
        description: 'Place your order wherever you are. You decide the day and time of pickup and delivery. Secure payments with all common payment methods like Twint & credit card.'
      },
      pickup: {
        title: 'Pickup',
        description: 'Pickup whenever you want. Put together your laundry and accessories. Your butler is always there.'
      },
      service: {
        title: 'Service',
        description: 'We care for your high-quality laundry, clothing and accessories with environmentally friendly, high-quality and individually tailored processes.'
      },
      delivery: {
        title: 'Delivery',
        description: 'Delivery whenever you want. We always have an open ear for your wishes and look forward to your feedback.'
      }
    }
  },
  prices: {
    meta: {
      title: 'Prices | WÖS.CH',
      description: 'Fair prices for high-quality services. Laundry and tailoring in one.'
    },
    header: {
      title: 'Fair Prices',
      subtitle: 'For high-quality services.\nLaundry and tailoring in one.'
    },
    categories: {
      title: 'Categories',
      description: 'Choose the category that matches your garment.'
    },
    perKilo: {
      title: 'Per Kilo',
      description: 'Wash & Iron per kilo',
      note: 'Only includes following items: Bath towel, dish and kitchen towel, undershirt, underwear, socks, tights, pajamas, jeans, t-shirt, polo shirt, training jacket and training pants.',
      price: 'From CHF 7.90/kg'
    },
    perPiece: {
      title: 'Per Piece',
      description: 'Wash & Iron per piece',
      note: 'All other items are calculated per piece.',
      price: 'From CHF 6.90/piece'
    },
    sortiment: {
      link: 'Products',
      showAll: 'Show complete product range',
      findPrices: 'You can find all prices for items and their services in the'
    },
    sections: {
      mostOrdered: 'Most ordered',
      men: 'Men',
      women: 'Women',
      tailoring: 'Tailoring'
    },
    pricePrefix: 'from',
    priceHidden: 'Price cleaning/laundry',
    overview: {
      title: "Our attractive prices for you",
      description: "Here you will find an overview of our prices for professional dry cleaning, laundry and tailoring - reliable and fast in and around Zurich.",
      findPrices: "You can find all prices for items and their services in the"
    }
  },
  auth: {
    modal: {
      login: {
        title: 'Login',
        email: 'Email',
        password: 'Password',
        submit: 'LOGIN',
        submitting: 'LOGIN...',
        forgotPassword: 'Forgot password?',
        noAccount: 'No account yet?',
        register: 'Register',
        emailPlaceholder: 'Enter email address',
        passwordPlaceholder: 'Enter password',
        resetPassword: 'RESET PASSWORD',
        errors: {
          invalidCredentials: 'Invalid email or password.',
          serverError: 'An error occurred. Please try again later.',
          emailNotFound: 'Email not found. Please register to access.',
          passwordMismatch: 'Password and email do not match',
          generalError: 'Something went wrong, please try again later or contact us'
        },
        validation: {
          required: 'Required',
          invalidEmail: 'Invalid email address'
        }
      },
      register: {
        title: 'Register',
        personalData: 'Personal Data',
        company: 'Company name',
        firstName: 'First name',
        lastName: 'Last name',
        email: 'Email *',
        password: 'Password',
        confirmPassword: 'Confirm password',
        gender: {
          male: 'male',
          female: 'female'
        },
        address: {
          title: 'Address',
          pickupLocation: 'Pickup location (e.g. home, office ...)',
          street: 'Street and house number',
          zipCity: 'ZIP/City *',
          phone: 'Phone',
          notes: 'Notes',
          hint: 'Please don\'t forget to include in the notes: stairs, floor, interior area and other information useful for pickup!'
        },
        terms: {
          text: 'By registering you accept the',
          agb: 'Terms & Conditions',
          forServices: 'for wös.ch services.'
        },
        buttons: {
          submit: 'REGISTER',
          submitting: 'REGISTERING...',
          cancel: 'CANCEL'
        },
        errors: {
          emailExists: 'Email already exists',
          generalError: 'Something went wrong, please try again later or contact us'
        },
        locationName: 'Pickup location (e.g. home, office ...)',
        street: 'Street and house number',
        city: 'ZIP/City *',
        phone: 'Phone',
        note: 'Notes',
        passwordCheck: 'Confirm password',
        hint: 'Please don\'t forget to include in the notes: stairs, floor, interior area and other information useful for pickup!',
        validation: {
          required: 'This field is required',
          invalidEmail: 'Invalid email address',
          passwordMismatch: 'Passwords do not match',
          minLength: 'Minimum {{count}} characters',
          invalidPhone: 'Invalid phone number',
          invalidCity: 'Invalid city',
          passwordRequired: 'Password is required',
          passwordMinLength: 'Password must be at least {{count}} characters',
          passwordsMatch: 'Passwords must match',
          emailRequired: 'Email is required',
          firstNameRequired: 'First name is required',
          lastNameRequired: 'Last name is required',
          phoneRequired: 'Phone number is required',
          streetRequired: 'Street is required',
          cityRequired: 'City is required',
          locationNameRequired: 'Pickup location is required'
        }
      },
      forgotPassword: {
        title: 'Forgot password',
        email: 'Email',
        submit: 'SEND',
        back: 'Back to login'
      },
      validation: {
        required: 'This field is required',
        invalidEmail: 'Invalid email address',
        passwordMismatch: 'Passwords do not match',
        minLength: 'Minimum {{count}} characters'
      },
      wrapper: {
        title: 'Login or Register',
        description: 'Please login or register to continue.',
        login: 'LOGIN',
        register: 'REGISTER',
        or: 'or',
        notRegistered: 'Not registered yet?',
        registerBonus: {
          line1: 'Register and',
          line2: 'get your CHF 10.00',
          line3: 'welcome bonus!'
        }
      },
      resetPassword: {
        title: 'Reset Password',
        checkingEmail: 'Checking email...',
        errors: {
          emailNotFound: 'Email not found.',
          passwordMismatch: 'Password and email do not match'
        },
        buttons: {
          cancel: 'CANCEL',
          submit: 'SEND',
          submitting: 'SENDING...'
        }
      },
      newPassword: {
        title: "Enter new password",
        password: "New Password",
        passwordCheck: "Repeat Password",
        buttons: {
          cancel: "CANCEL",
          confirm: "CONFIRM",
          confirming: "CONFIRMING..."
        },
        validation: {
          passwordRequired: "Password is required",
          passwordMinLength: "Password must be at least 5 characters",
          passwordCheckRequired: "Password confirmation is required",
          passwordMismatch: "The entered passwords do not match"
        },
        errors: {
          emailNotFound: "Email not found. Please register to access",
          passwordMismatch: "Password and email do not match",
          generalError: "Something went wrong, please try again later or contact us"
        }
      }
    }
  },
  processingTime: {
    days: '14 days',
    hours: '48 hours'
  },
  address: {
    validation: {
      locationRequired: 'Pickup location is required',
      streetRequired: 'Street and house number is required',
      cityRequired: 'City is required',
      phoneRequired: 'Phone is required'
    },
    form: {
      save: {
        submit: 'SAVE',
        submitting: 'SAVING...'
      },
      defaults: {
        billing: 'This is the default billing address',
        pickup: 'This is the default pickup address',
        delivery: 'This is the default delivery address'
      }
    },
    loading: 'Loading...',
    addNew: 'ADD ADDRESS',
    actions: {
      delete: 'DELETE',
      edit: 'EDIT'
    }
  },
  userProfile: {
    personalData: 'Personal Data',
    form: {
      company: 'Company name',
      firstName: 'First name',
      lastName: 'Last name',
      email: 'Email',
      password: 'Password',
      passwordCheck: 'Confirm password',
      gender: {
        male: 'male',
        female: 'female'
      },
      save: {
        submit: 'SAVE',
        submitting: 'SAVING...'
      }
    },
    validation: {
      emailRequired: 'Email is required',
      invalidEmail: 'Invalid email address',
      emailExists: 'Email already registered!',
      generalError: 'Something went wrong, please try again later or contact us',
      firstNameRequired: 'First name is required',
      lastNameRequired: 'Last name is required',
      passwordRequired: 'Password is required',
      passwordMinLength: 'Password must be at least 5 characters',
      passwordCheckRequired: 'Password confirmation is required',
      passwordMismatch: 'Passwords do not match',
      genderRequired: 'Gender is required'
    },
    tabs: {
      profile: 'PROFILE',
      addresses: 'ADDRESSES',
      orders: 'ORDERS',
      bonuses: 'MY BONUSES'
    }
  },
  order: {
    orderTime: 'Order time',
    orderNumber: 'Order number',
    status: 'Status',
    showDetails: 'Show details',
    totals: {
      orderAmount: 'Order Amount',
      shippingCosts: 'Shipping Costs',
      shippingNote: 'Free pickup and delivery for orders over CHF 30.00',
      totalWithShipping: 'Total including shipping',
      usedCredits: 'Used credits and vouchers',
      amountToPay: 'Amount to pay'
    },
    details: {
      backToOrders: 'Back to Orders',
      details: 'Details',
      orderNumber: 'Order No.:',
      date: 'Date:',
      total: 'Total:',
      status: 'Status:',
      giftCard: 'Gift Card:',
      welcomeCode: 'Welcome Code:',
      bonus: 'Bonus:',
      customer: {
        title: 'Customer',
        fullName: 'Full Name:',
        email: 'Email:',
        billingLocation: 'Billing Location:',
        street: 'Street:',
        city: 'City:',
        phone: 'Phone:',
        notes: 'Notes:'
      },
      pickup: {
        title: 'Pickup',
        dropOffInStore: 'Drop off in store',
        location: 'Pickup Location:',
        dateAndTime: 'Date and Time:'
      },
      delivery: {
        title: 'Delivery',
        pickupInStore: 'Pick up in store',
        location: 'Delivery Location:',
        dateAndTime: 'Date and Time:'
      },
      orderDetails: 'Order Details'
    }
  },
  pagination: {
    previous: 'Previous',
    next: 'Next'
  },
  bonus: {
    orderTime: 'Order time',
    code: 'Code',
    status: 'Status',
    expiryDate: 'Expiry date',
    unlimited: 'Unlimited',
    balance: {
      total: 'Balance',
      remaining: 'Remaining balance'
    },
    message: {
      title: 'Personal message'
    },
    welcome: {
      title: "Welcome Bonus",
      description: "Welcome bonus for registration"
    }
  },
  messages: {
    contact: {
      subject: "WÖS.CH - New Message from contact form"
    }
  },
  validation: {
    register: {
      required: "{{field}} is required",
      email: "Please enter a valid email address",
      passwordLength: "Password must be at least 5 characters long",
      passwordMatch: "Passwords do not match",
      zipCity: "Please enter a valid ZIP code and city",
      phone: "Please enter a valid phone number"
    }
  },
  faq: {
    meta: {
      title: "FAQ - Frequently Asked Questions | WÖS.CH",
      description: "Frequently asked questions about WÖS.CH - maybe you'll find what you're looking for here."
    },
    pageTitle: "faq - frequently asked questions",
    sections: {
      general: "I general",
      beforePickup: "Before the first pickup",
      pricesAndBilling: "Prices & billing",
      service: "The wös.ch service",
      washingAndDrycleaning: "Regular washing and dry cleaning",
      accountIssues: "Account issues",
      customerService: {
        title: "Customer service: Please write to us at info@woes.ch",
        contact: "Do you have any questions, suggestions, feedback on quality or service, would you like to give us a compliment or simply talk to us? You can reach us at info@woes.ch."
      }
    },
    questions: {
      whatIsWoesch: {
        question: "What does wös.ch offer?",
        answer: "wös.ch is a simple, practical and cost-effective solution for laundry and tailoring services: Orders around the clock, pickups and deliveries six days a week and 12 hours a day, highest quality and 100% environmentally friendly."
      },
      processingTime: {
        question: "Order processing time",
        answer: "We guarantee a processing time of 24 hours for ordinary laundry and ironing work, 48 hours for special work (washing of delicate textiles and tailoring services, etc.) and 14 days for more complex services (cleaning of leather, carpets, curtains, etc.). If your order includes textiles or items with different processing times, you can choose between a collective delivery or two separate deliveries. The choice is yours. For orders of CHF 30.00 or more, collection and delivery are FREE (applies to each individual delivery if you opt for separate deliveries)."
      },
      howToRegister: {
        question: "How can I register?",
        answer: "Simply create an account on www.wös.ch. You will be asked to enter your email address, pickup and delivery address with details such as floor, stairs/elevator, etc., and your mobile number. We need this information to provide you with the best possible service."
      },
      isWoeschOfferedInYourArea: {
        question: "Is wös.ch offered in your area?",
        answer: "To find out if your location is served, you can simply enter the postal code on our website. If we don't yet cover the area, please provide your email address so we can notify you as soon as the service becomes available in your region."
      },
      howToPlaceAnOrder: {
        question: "How can I place an order?",
        answer: "It's very simple: After registration, enter your username and password on www.wös.ch and click 'LOGIN' to access the order page. There you can select all desired services (washing, ironing and tailoring service). Don't forget to specify your special requests for each individual garment (folding, stain treatment, etc.), and personalize your order with selected fragrances if desired. Choose the day and time for pickup and delivery and make sure you are at home during the chosen time window or let us know where we can safely deposit the delivery. Leave a comment or feedback after the order is completed. This helps us to continuously improve our service."
      },
      howToChangePickupAndDeliveryTime: {
        question: "How can I change the day and time of pickup and delivery?",
        answer: "Have your plans changed? No problem! After placing the order, you can send us an email to info@woes.ch up to 12 hours before the planned pickup or delivery with your new preferred appointment."
      },
      canYouUseTheServiceEveryWeekAtTheSameTime: {
        question: "Can I use the wös.ch service every week on the same day and at the same time?",
        answer: "Yes, we also offer subscriptions. With our prepaid rates of CHF 100.00, CHF 150.00, CHF 200.00 or CHF 250.00, you can have the order executed weekly, every two weeks, every three weeks or every four weeks - at the best possible conditions. Simply choose the desired frequency and preferred day for pickup and delivery. It couldn't be easier!"
      },
      whatHappensIfTheWoeschCourierIsLate: {
        question: "What happens if the wös.ch courier is late?",
        answer: "We do our best to keep to the agreed time window. However, due to external factors, we may still be delayed. We understand that you have other commitments. If we are delayed and you cannot wait for us, please send an email to info@woes.ch. We will be happy to reschedule your order free of charge."
      },
      whatToDoIfYouHaveTechnicalDifficulties: {
        question: "What can I do if I have difficulties with the website?",
        answer: "Our website www.wös.ch has been optimized for mobile devices. Our programmers are constantly working to improve it. If you still experience technical difficulties, please report them to info@woes.ch."
      },
      howToContactWoesch: {
        question: "How can I contact wös.ch?",
        answer: "Do you have questions, suggestions, feedback on quality or service, would you like to give praise or just talk to us? You can write to us at info@woes.ch or call us at any time. We are happy to answer any questions about wös.ch."
      },
      doYouNeedToSortYourClothes: {
        question: "Do I need to sort my clothes?",
        answer: "Yes. But don't worry: it's very simple! The wös.ch courier brings suitable bags to collect your garments or items for textile cleaning, washing and ironing, or tailoring service. It's enough if you separate the items accordingly to make sorting easier for us. We do not accept personal items and assume no liability for watches, jewelry, or other items that are lost during cleaning."
      },
      canYouSpecifySpecialRequests: {
        question: "Can I specify special requests?",
        answer: "Of course! You can specify your preferences for each individual garment or for multiple garments of the same type. After selecting the garment or item to be cleaned or repaired, you can add additional services such as ironing, folding, or stain treatment in the corresponding areas. These special requests are FREE and included in the order price. You can also order tailoring work for garments or items that you have us clean. Our price list for the tailoring service applies. You can enter all other requests in the 'Note' field. We know how much you care about your clothes, accessories, and personal items. That's why we do our best to fulfill your special requests."
      },
      canThePickupOrDeliveryTakePlaceIfYouAreAbsent: {
        question: "Can the pickup or delivery take place if I am absent?",
        answer: "Yes, that's possible. Please provide us with the corresponding instructions when placing your order. In the last step, you can specify whether the wös.ch-Mitarbeiter Ihre Kleidung im Eingang, an der Rezeption usw. hinterlassen darf, wenn Sie am gewählten Tag und zur gewählten Zeit nicht zuhause oder an der angegebenen Adresse sind."
      },
      howToPay: {
        question: "How can I pay? What payment options are available?",
        answer: "Payment is made securely via credit card, PayPal and PostFinance. wös.ch relies on the most important providers in the payment systems sector. After payment is received, you will immediately receive an order confirmation with all details. Do you have questions about our prices? You can contact our customer service at any time via info@woes.ch."
      },
      isThereAMinimumOrderValue: {
        question: "Is there a minimum order value?",
        answer: "We do not have a minimum order value."
      },
      isThereAPickupOrDeliveryFee: {
        question: "Is there a pickup or delivery fee?",
        answer: "Pickup and delivery are FREE for orders over CHF 30.00. For orders under CHF 30.00, we charge CHF 7.50 for pickup and CHF 7.50 for delivery."
      },
      howToRedeemARabattCoupon: {
        question: "How can I redeem a discount coupon?",
        answer: "You can redeem discount coupons such as the welcome bonus when registering, and those from special promotions or invitations from your friends at any time. You can find all discount coupons in your account in the MY BONUSES section. Don't forget to invite your loved ones, friends and colleagues to try wös.ch. It's worth it! When you recommend us, both your friends and you receive a bonus of CHF 5.00 to test our service, and with their first order, you receive a welcome bonus worth CHF 10.00 as a thank you! It's that simple. This way you always have discount bonuses for your favorite service."
      },
      howToUseAGiftCard: {
        question: "How can I use a gift card?",
        answer: "Someone has gifted you free time with a wös.ch voucher? Great! To use the credit, simply enter the voucher code in the corresponding section during payment. The credit will be deducted immediately and has no expiry date."
      },
      howMuchDoesTheWoeschServiceCost: {
        question: "How much does the wös.ch service cost?",
        answer: "wös.ch offers full transparency and excellent value for money with very favorable conditions. On wös.ch you will find all our prices, so you know exactly how much you pay for the desired service and always have your expenses under control."
      },
      whatWashingProductsAreUsed: {
        question: "What washing products are used?",
        answer: "We use environmentally friendly and biodegradable detergents because it's important to us to protect the planet, your health, and your skin!"
      },
      isTheWoeschServiceEcological: {
        question: "Is the wös.ch service ecological?",
        answer: "We rely on renewable energy, biodegradable and gentle detergents, and recyclable packaging. All this to protect the environment. This is what defines us and is part of our values: We think and act sustainably and ecologically because we want to set a positive example!"
      },
      areThereMaterialsYouCannotHandle: {
        question: "Are there materials you cannot handle?",
        answer: "In our specialized laundry, we clean and wash all types of textiles, including leather, nubuck, suede, cashmere, and eco-leather. We are also equipped to treat carpets, curtains, and other home textiles, as well as repair and clean bags. We handle normal stain treatments. Don't forget to inform us when placing your order if you want this treatment. If the stain has already penetrated deep into the fabric, it may be difficult or impossible to remove, as repeated treatment would lead to color loss or visible damage such as rough or frayed areas. There are also some special synthetic materials that we cannot treat. If our team cannot wash or repair a garment or item, we will contact you via email or phone. Of course, we will credit you the amount you paid for the corresponding service."
      },
      whatHappensIfAnAnnoyingStainCannotBeRemoved: {
        question: "What happens if an annoying stain cannot be removed?",
        answer: "We do our best to remove stains, but unfortunately some are permanent. For stains that have already penetrated deep into the fabric, repeated treatments lead to color loss or visible damage such as rough or frayed areas. If our team cannot wash or clean a garment or item, we will contact you via email or phone."
      },
      liabilityForButtonsAndAccessories: {
        question: "Liability for buttons and accessories",
        answer: "As is common in the industry, we are not liable for buttons and accessories entrusted to us during the cleaning or treatment of textiles. Please inform us if a button is not holding well, or remove it before cleaning to avoid inconvenience or possible damage."
      },
      whatIsRegularWashing: {
        question: "What is regular washing?",
        answer: "Regular washing is the ideal cleaning method for all garments and is done with environmentally friendly detergents. If you choose the standard service without special requests, your garments will be washed, dried, and delivered hanging on hangers. If you want us to fold your garments, you can specify this in the special requests."
      },
      whatIsDryCleaning: {
        question: "What is dry cleaning, and how do I know if a garment should be dry cleaned?",
        answer: "Certain garments are made of delicate materials (silk, wool, cashmere, etc.) and should only be dry cleaned. At wös.ch, we use biodegradable agents for this, which protect the environment and guarantee an environmentally friendly washing service."
      },
      doYouNeedToSpecifyWhenSortingClothes: {
        question: "Do I need to specify when ordering whether the clothes should be washed or dry cleaned?",
        answer: "No. wös.ch chooses the best cleaning method for your garments. We guarantee cleaning according to the instructions on the label and optimal care of the fabrics. If, for example, the label shows the symbol for dry cleaning, we treat the garment accordingly. If you have special requests, please enter them in the 'Note' field after selecting the garment. We will follow these and inform you if there are any difficulties. For specific questions, you can contact our customer service, who will connect you with a specialist."
      },
      whereCanYouFindYourOrders: {
        question: "Where can I find my orders?",
        answer: "Would you like to view an old order? Go to wös.ch with your computer or smartphone and log in. Click on \"Orders\" to see all previous transactions with details of individual orders."
      },
      whatHappensIfYouForgetToPickUp: {
        question: "What happens if I forget the pickup?",
        answer: "We understand that life can be hectic sometimes and plans can suddenly change. If you know in advance that you cannot be at the specified address at the agreed time, please inform us at least 12 hours before the chosen pickup or delivery time via info@woes.ch. If you do not inform us within this deadline, we will charge a fee of CHF 15.00 to cover our courier costs."
      },
      whatToDoIfYouHaveProblemsWithYourAccountOrPassword: {
        question: "What should I do if I have problems with my account or forgot my password?",
        answer: "Have you forgotten your password? Can't log in? Try resetting your password. If it still doesn't work, send us an email at info@woes.ch. One of our staff members will contact you immediately."
      }
    }
  },
  status: {
    order: {
      unpaid: "Unpaid",
      received: "Received",
      processing: "Processing",
      completed: "Completed"
    },
    giftcard: {
      usable: "Usable",
      used: "Used",
      processing: "Processing",
      completed: "Completed"
    }
  }
}; 