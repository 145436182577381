import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { formatNumber } from '../../../Utils'
import { withTranslation } from 'react-i18next';

const PerKiloItem = ({
    washingAndIroningPerKiloPrice,
    washingPerKiloPrice,
    t
}) => (
    <ListGroup flush className="shopping-item-section">
        <ListGroupItem className="item-main-header">
            <strong>{t('cart.sections.laundry')}</strong>
        </ListGroupItem>
        {
            washingAndIroningPerKiloPrice > 0 &&
            <ListGroupItem className="item-button single active">
                <div className="d-flex item-header-font">
                    <div className="item-header">{t('cart.options.washAndIron')}</div>
                    <div className="small-price">
                        {formatNumber(washingAndIroningPerKiloPrice)}
                    </div>
                </div>
            </ListGroupItem>
        }
        {
            washingPerKiloPrice > 0 &&
            <ListGroupItem className="item-button active">
                <div className="d-flex item-header-font">
                    <div className="item-header">{t('cart.options.washOnly')}</div>
                    <div className="small-price">
                        {formatNumber(washingPerKiloPrice)}
                    </div>
                </div>
            </ListGroupItem>
        }
    </ListGroup>
)

export default withTranslation()(PerKiloItem)