import React, { Component } from 'react'
import { Card, CardBody, CardFooter, CardHeader } from "reactstrap";
import AddressBox from './AddressBox';
import { withTranslation } from 'react-i18next';
import {UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

class CheckoutPageStepBox extends Component {

    render() {

        const { address,
            icon,
            headerText,
            needsConfirmation,
            confirmed,
            confirm,
            editVisible,
            editClick,
            updateFromToDateTime,
            date,
            time,
            disabledButton,
            confirmWithoutAddress,
            noAddress,
            noAddressButtonText,
            t,
            expressPrice,
            onToggleExpress,
            isExpress
        } = this.props;

        if (!address) {
            return null
        }

        let bodyContent;
        if (!confirmed && !noAddress) {
            bodyContent = (
                <div className="info">
                    <img src={require('../../img/baseline-face-24px.svg')} alt="" />
                    <span>
                        {t('cart.checkout.address.info')}
                    </span>
                </div>
            )
        }

        if (confirmed && noAddress) {
            bodyContent = (
                <div className="info">
                    <img src={require('../../img/baseline-face-24px.svg')} alt="" /><br />
                    <span>
                        {noAddressButtonText}
                    </span>
                </div>
            )
        }
        
        return (
            <Card className="checkout-step-box">
                <CardHeader>
                    <div class="d-flex align-items-center">
                        <img alt="" src={icon} />
                        <div>
                            {headerText}
                            { expressPrice > 0 &&
                                <div className="d-flex express">
                                    <input id="toggleExpress" type="checkbox" checked={isExpress} onChange={() => { onToggleExpress();}}></input>
                                    <div class="d-flex align-items-center">
                                        <label htmlFor="toggleExpress">
                                            EXPRESS<span class="hidden-mobile">&nbsp;{t('cart.options.washAndIron')}</span>: +{expressPrice}
                                        </label>
                                        <FontAwesomeIcon className="ml-1" style={{ color: "#2a4a91" }} icon={faInfoCircle} id="express-hint" />
                                        <UncontrolledPopover trigger="hover" placement="left" target="express-hint">
                                            <PopoverHeader>{t('cart.options.washAndIronExpress')}</PopoverHeader>
                                            <PopoverBody>{t('cart.laundry.expressInfo')}</PopoverBody>
                                        </UncontrolledPopover>
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    </CardHeader>
                <CardBody>

                    {
                        needsConfirmation || noAddress ?
                            <div style={{ display: "flex", height: "100%" }}>{bodyContent}</div> : <AddressBox {...address} date={date} time={time} editVisible={editVisible} editClick={editClick} updateFromToDateTime={updateFromToDateTime} />
                    }
                </CardBody>
                <CardFooter>
                    <div className="d-flex flex-row">
                        {
                            <React.Fragment>
                                {
                                    !confirmed &&
                                    <React.Fragment>
                                        <button className="btn btn-info checkout-btn text-uppercase" onClick={() => confirm(true)} disabled={disabledButton}>
                                            {t('cart.checkout.address.confirm')}
                                        </button>
                                        &nbsp;&nbsp;
                                        <button className="btn btn-info checkout-btn text-uppercase" onClick={confirmWithoutAddress} disabled={disabledButton}>
                                            {noAddressButtonText}
                                        </button>
                                    </React.Fragment>
                                }
                                {
                                    confirmed &&
                                    <button className="btn btn-info checkout-btn text-uppercase" onClick={() => confirm(false)}>
                                        {t('cart.checkout.address.change')}
                                    </button>
                                }
                            </React.Fragment>
                        }
                    </div>
                </CardFooter>
            </Card>
        );
    }
}

export default withTranslation()(CheckoutPageStepBox);