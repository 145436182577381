import React from 'react';
import MetaTags from 'react-meta-tags';
import Background from '../img/eco.png';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

const EcoFriendly = () => {
    const { t } = useTranslation();

    return (
        <div>
            <MetaTags>
                <title>{t('ecoFriendly.metaTitle')}</title>
                <meta name="description" content={t('ecoFriendly.metaDescription')} />
                <meta name="image" content="https://www.wös.ch/images/waschbrett.png" />
                <meta itemProp="name" content={t('ecoFriendly.metaTitle')} />
                <meta itemProp="description" content={t('ecoFriendly.metaDescription')} />
                <meta itemProp="image" content="https://www.wös.ch/images/waschbrett.png" />
                <meta name="og:title" content={t('ecoFriendly.metaTitle')} />
                <meta name="og:description" content={t('ecoFriendly.metaDescription')} />
                <meta name="og:image" content="https://www.wös.ch/images/waschbrett.png" />
                <meta name="og:url" content="https://www.wös.ch/eco-friendly" />
            </MetaTags>
            <Helmet>
                {i18next.language === 'de' ? (
                    <link rel="alternate" href="https://wös.ch/en/eco-friendly" hreflang="en-gb" />
                ) : (
                    <link rel="alternate" href="https://wös.ch/eco-friendly" hreflang="de" />
                )}
            </Helmet>
            <div style={{ background: `url(${Background}) no-repeat`, backgroundSize: 'cover', minHeight: "calc(100vh - 65px)", backgroundPosition: "50% 50%" }} >
                <div className="container" style={{ paddingBottom: "10px" }}>
                    <h1 className="carousel-text-header h1" style={{ textAlign: "center", paddingTop: "100px", paddingBottom: "10px" }}>
                        {t('ecoFriendly.title')}
                    </h1>
                    <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                        {t('ecoFriendly.subtitle')}
                    </p>
                    <img alt="" src={require('../img/stitching_ecofriendly.png')} className="img-eco" />
                    <p className="carousel-text-content t1" style={{ textAlign: "center", paddingTop: "100px" }}>
                        {t('ecoFriendly.description1')}
                    </p>
                    <br />
                    <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                        {t('ecoFriendly.description2')}
                    </p>
                    <br />
                    <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                        {t('ecoFriendly.description3')}
                    </p>
                    <br />
                    <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                        {t('ecoFriendly.description4')}
                    </p>
                    <br />
                    <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                        {t('ecoFriendly.description5')}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default EcoFriendly;