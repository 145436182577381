import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { formatNumber } from '../../../Utils'
import { withTranslation } from 'react-i18next';

const DeliveryItem = ({
    packed,
    packedSelected,
    packedPrice,
    hanger,
    hangerSelected,
    t
}) => (
    <ListGroup flush className="shopping-item-section">
        <ListGroupItem className="item-main-header">
            <strong>{t('cart.sections.delivery')}</strong>
        </ListGroupItem>
        {
            packedSelected &&
            <ListGroupItem className="item-button" active={packedSelected}>
                <div className="d-flex item-header-font">
                    <div className="item-header">{t('cart.options.folded')}</div>
                    {
                        packedPrice === 0 &&
                        <div className="ml-auto" style={{ textTransform: "none" }}>{t('cart.free')}</div>
                    }
                    {
                        packedPrice > 0 &&
                        <div className="small-price">
                            {formatNumber(packedPrice)}
                        </div>
                    }
                </div>
            </ListGroupItem>
        }
        {
            hangerSelected &&
            <ListGroupItem className="item-button" active={hangerSelected}>
                <div className="d-flex item-header-font">
                    <div className="item-header">{t('cart.options.onHanger')}</div>
                    <div className="ml-auto" style={{ textTransform: "none" }}>{t('cart.free')}</div>
                </div>
            </ListGroupItem>
        }
    </ListGroup>
)

export default withTranslation()(DeliveryItem)