import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Background from '../img/beige/und_so_gehts_b.jpg'
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

// import PricingCard from './howItWorks/PricingCard';
// import priceImageS from '../img/prices/s.png';
// import priceImage50 from '../img/prices/50.png'
// import priceImageM from '../img/prices/m.png';
// import priceImage100 from '../img/prices/100.png';
// import priceImage150 from '../img/prices/150.png';
// import priceImage200 from '../img/prices/200.webp';
// import priceImageL from '../img/prices/l.png'
// import priceImageXL from '../img/prices/xl.png'
// import {Button} from "react-bootstrap";
class HowItWorks extends Component {
    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{t('howItWorks.meta.title')}</title>
                    <meta name="description" content={t('howItWorks.meta.description')} />
                    <meta name="image" content="https://www.wös.ch/images/naehmaschine.png" />
                    <meta itemProp="name" content={t('howItWorks.meta.title')} />
                    <meta itemProp="description" content={t('howItWorks.meta.description')} />
                    <meta itemProp="image" content="https://www.wös.ch/images/naehmaschine.png" />
                    <meta name="og: title" content={t('howItWorks.meta.title')} />
                    <meta name="og:description" content={t('howItWorks.meta.description')} />
                    <meta name="og:image" content="https://www.wös.ch/images/naehmaschine.png" />
                    <meta name="og:url" content="https://www.wös.ch/how-it-works" />
                </MetaTags>
                <Helmet>
                    {i18next.language === 'de' ? (
                        <link rel="alternate" href="https://wös.ch/en/how-it-works" hreflang="en-gb" />
                    ) : (
                        <link rel="alternate" href="https://wös.ch/how-it-works" hreflang="de" />
                    )}
                </Helmet>
                <div style={{ background: `url(${Background}) no-repeat`, backgroundSize: 'cover', minHeight: "calc(100vh - 65px)", backgroundPosition: "50% 50%" }} >
                    <div className="container" style={{ paddingBottom: "10px", paddingTop: "calc(50vh - 196px)" }}>
                        <h1 className="carousel-text-header h1" style={{ textAlign: "center", paddingBottom: "30px" }}>
                            {t('howItWorks.header.title')}
                        </h1>
                        <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                            {t('howItWorks.header.description')}
                        </p>
                        <img alt="stitching rundum sorglos" src={require('../img/stitching_rundum_sorglos.png')} className="img-how-it-works" />
                        <button onClick={() => this.props.history.push('/products')} className="button-services">
                            {t('howItWorks.tryNow')}
                        </button>
                    </div>
                </div>
                <div className="pricing-page-steps-block">
                    <div className="container">
                        <div className="row" style={{ paddingBottom: "60px", paddingTop: "70px" }}>
                            <div className="col-lg-3 services-box" style={{ textAlign: "center" }}>
                                <img alt="" src={require('../img/how-it-works/1.png')} style={{ height: "140px", paddingLeft: "46px" }} />
                                <h2 className="service-box-header-text h2" style={{ lineHeight: "50px" }}>{t('howItWorks.steps.order.title')}</h2>
                                <p className="service-box-text t2">
                                    {t('howItWorks.steps.order.description')}
                                </p>
                            </div>
                            <div className="col-lg-3 services-box">
                                <img alt="" src={require('../img/how-it-works/24.png')} style={{ height: "140px" }} />
                                <h2 className="service-box-header-text h2" style={{ lineHeight: "50px" }}>{t('howItWorks.steps.pickup.title')}</h2>
                                <p className="service-box-text t2">
                                    {t('howItWorks.steps.pickup.description')}
                                </p>
                            </div>
                            <div className="col-lg-3 services-box">
                                <img alt="" src={require('../img/how-it-works/3.png')} style={{ height: "140px" }} />
                                <h2 className="service-box-header-text h2" style={{ lineHeight: "50px" }}>{t('howItWorks.steps.service.title')}</h2>
                                <p className="service-box-text t2">
                                    {t('howItWorks.steps.service.description')}
                                </p>
                            </div>
                            <div className="col-lg-3 services-box">
                                <img alt="" src={require('../img/how-it-works/24.png')} style={{ height: "140px" }} />
                                <h2 className="service-box-header-text h2" style={{ lineHeight: "50px" }}>{t('howItWorks.steps.delivery.title')}</h2>
                                <p className="service-box-text t2">
                                    {t('howItWorks.steps.delivery.description')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <section>
                    <div style={{textAlign: 'center', marginBottom: '50px'}}>
                        <p className="pricing-page-main-header" style={{ marginBottom: '0px'}}>
                            bezahle pro wäschestück oder service,
                        </p>
                        <p className="pricing-page-main-header" style={{ marginTop: '0px'}}>
                            wann immer du willst.
                        </p>
                    </div>
                    <div style={{textAlign: 'center', padding: '2%'}}>
                        <Button className="price-card-btn" type="button">
                            sofort anfordern
                        </Button>
                    </div>
                    <div style={{textAlign: 'center', paddingBottom: '5%'}}>
                        <p className="pricing-page-main-header">
                            wös.ch nach mass
                        </p>
                        <p className="pricing-page-text">
                            Wähle einen unserer monatlichen Prepaid-Tarife für
                        </p>
                        <p className="pricing-page-text">
                            CHF 50, CHF 100, CHF 150 oder CHF 200
                        </p>
                        <p className="pricing-page-text">
                            für ein besonderes Rundum-sorglos-Service-Erlebnis zu vorteilhaften
                        </p>
                        <p className="pricing-page-text">
                            Bedingungen oder erteile einzelne Aufträge, wann immer Du willst
                        </p>
                    </div>
                        <div className="row p-0 m-0 justify-content-md-center">
                        <div className="col-lg-2 p-2" style={{minWidth: "200px"}}>
                            <div className="d-flex justify-content-center">
                                <PricingCard
                                    image={priceImageS}
                                    text="5% Ermässigung auf Aufträge:
                                        Bezahle CHF 50 und
                                        erhalte monatlich CHF 52.50 Mindestguthaben."
                                    priceImage={priceImage50}
                                />
                            </div>
                        </div>
                        <div className="col-lg-2 p-2" style={{minWidth: "200px"}}>
                        <div className="d-flex justify-content-center">
                            <PricingCard
                                image={priceImageM}
                                text="10% Ermässigung auf Aufträge:
                                        Bezahle CHF 100 und
                                        erhalte monatlich CHF 110 Mindestguthaben."
                                priceImage={priceImage100}
                            />
                            </div>
                        </div>
                        <div className="col-lg-2 p-2" style={{minWidth: "200px"}}>
                        <div className="d-flex justify-content-center">
                            <PricingCard
                                image={priceImageL}
                                text="15% Ermässigung auf Aufträge:
                                    Bezahle CHF 150 und
                                    erhalte monatlich CHF 172.50 Mindestguthaben."
                                priceImage={priceImage150}
                            />
                            </div>
                        </div>
                        <div className="col-lg-2 p-2" style={{minWidth: "200px"}}>
                        <div className="d-flex justify-content-center">
                            <PricingCard
                                image={priceImageXL}
                                text="20% Ermässigung auf Aufträge:
                                        Bezahle CHF 200 und
                                        erhalte monatlich CHF 240 Mindestguthaben."
                                priceImage={priceImage200}
                            />
                            </div>
                        </div>
                        </div>
                </section> */}
            </React.Fragment>
        )
    }

}

export default withRouter(withTranslation()(HowItWorks));