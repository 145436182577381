import React, { Component } from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { connect } from 'react-redux'
import { formatNumber } from '../../Utils'
import { withTranslation } from 'react-i18next';

import * as actions from '../../store/actions'

class DeliveryItem extends Component {

    render() {
        const {
            packed,
            packedSelected,
            packedPrice,
            hanger,
            hangerSelected,
            OnDeliveryCheck,
            itemIndex,
            t
        } = this.props

        return (
            <ListGroup flush className="shopping-item-section">
                <ListGroupItem className="item-main-header">
                    <strong>{t('cart.sections.delivery')}</strong>
                </ListGroupItem>
                {
                    packed &&
                    <ListGroupItem className="item-button single" active={packedSelected}
                        onClick={() => OnDeliveryCheck(itemIndex)}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">{t('cart.options.folded')}</div>
                            {
                                packedPrice === 0 &&
                                <div className="ml-auto" style={{ textTransform: "none" }}>{t('cart.free')}</div>
                            }
                            {
                                packedPrice > 0 &&
                                <div className="small-price">
                                    {formatNumber(packedPrice)}
                                </div>
                            }
                        </div>
                    </ListGroupItem>
                }
                {
                    hanger &&
                    <ListGroupItem className="item-button single" active={hangerSelected}
                        onClick={() => OnDeliveryCheck(itemIndex)}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">{t('cart.options.onHanger')}</div>
                            <div className="ml-auto" style={{ textTransform: "none" }}>{t('cart.free')}</div>
                        </div>
                    </ListGroupItem>
                }
            </ListGroup>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        OnDeliveryCheck: (itemIndex) => dispatch(actions.deliveryCheck(itemIndex)),
    };
};

export default connect(null, mapDispatchToProps)(withTranslation()(DeliveryItem));