import React from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

const LanguageSwitcher = () => {
  const { i18n } = useTranslation();
  const history = useHistory();
  const [dropdownOpen, setDropdownOpen] = React.useState(false);
  const [hasLanguagePreference, setHasLanguagePreference] = React.useState(!!localStorage.getItem('userLanguageChoice'));

  const toggle = () => setDropdownOpen(prevState => !prevState);

  // Get the simplified language code
  const currentLanguage = i18n.language.split('-')[0];

  const changeLanguage = (language) => {
    // Get current path
    const currentPath = window.location.pathname;
    
    // Calculate new path based on language
    let newPath;
    if (language === 'en') {
      // If switching to English, add /en/ prefix
      newPath = currentPath === '/' ? '/en' : '/en' + currentPath.replace(/^\/en|^\/de/, '');
    } else {
      // If switching to German, remove language prefix
      newPath = currentPath.replace(/^\/en|^\/de/, '');
      if (newPath === '') newPath = '/';
    }
    

    // Change language in i18next
    i18n.changeLanguage(language).then(() => {
      fetch('/api/language', {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ language: language.split('-')[0] })
      }).then(() => {
        setHasLanguagePreference(true);
        window.location.href = newPath;
      })
      
    });
  };

  
  
  //localStorage.setItem('userLanguageChoice', i18n.language);

  return (
    <Dropdown class="language-dropdown" isOpen={dropdownOpen} toggle={toggle}>
      <DropdownToggle caret className="language-switcher">
        {currentLanguage === 'en' ? 'EN' : 'DE'}
      </DropdownToggle>
      <DropdownMenu>
        <DropdownItem 
          onClick={() => changeLanguage('de')} 
          active={currentLanguage === 'de'}
        >
          Deutsch
        </DropdownItem>
        <DropdownItem 
          onClick={() => changeLanguage('en')} 
          active={currentLanguage === 'en'}
        >
          English
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default LanguageSwitcher;