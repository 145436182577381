import React, { Component, useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom'
import { connect } from 'react-redux';
import { getItemsCount } from '../store/reducers/shoppingCart';
import { getUsername } from '../store/reducers/auth';
import { formatNumber } from '../Utils'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';

import * as actions from '../store/actions'

class Header extends Component {

    state = {
        dropdownOpen: false,
        isScrolled: false,
        currentPath: window.location.pathname
            .replace(/^\/en\//, '')
            .replace(/^\//, '')
            .split('/')[0]
    };


    componentDidMount() {
        document.querySelector('.content').addEventListener('scroll', this.handleScroll);
    }

    componentDidUpdate(prevProps) {
        if (this.props.location.pathname !== prevProps.location.pathname) {
            const currentPath = window.location.pathname
                .replace(/^\/en\//, '')
                .replace(/^\//, '')
                .split('/')[0];
            this.setState({ currentPath });
        }
    }

    componentWillUnmount() {
        document.querySelector('.content').removeEventListener('scroll', this.handleScroll);
    }

    handleScroll = () => {
        this.setState({ isScrolled: document.querySelector('.content').scrollTop > 150 });
    };

    render() {

        const { totalPrice, itemsCount, userName, OnAuthModalToggle, OnUserLogout, isAdmin, t, i18n } = this.props
        const { currentPath } = this.state;
        const langPrefix = i18n.language === 'en' ? '/en' : '';


        return (
            <React.Fragment>
                <header className={`App-header page--${currentPath}`}>
                    <nav className={`navbar navbar-expand-xl ${this.state.isScrolled ? 'scrolled' : ''}`}>
                        <div className="container-fluid">
                            <button
                                type="button"
                                aria-controls="navbarCollapse"
                                aria-expanded="false"
                                className="navbar-toggler navbar-toggler-right"
                                onClick={() => { this.props.toggleSideBar() }}
                            >
                                <i className="fa fa-bars" />
                            </button>
                            <NavLink to={`${langPrefix}/`} className="navbar-brand">
                                <img style={{ height: '34px' }} src={require("../img/wos_logo.png")} alt={t('header.logoAlt')} />
                            </NavLink>
                            <div id="navbarCollapse" className="collapse navbar-collapse">
                                <ul className="navbar-nav navbar-margin" style={{ whiteSpace: "nowrap" }}>
                                    
                                    {/* <li className="nav-item">
                                        <NavLink to="/" className="nav-link" activeClassName="active" exact>Home</NavLink>
                                    </li> */}
                                    <li className="nav-item">
                                        <NavLink to={`${langPrefix}/how-it-works`} className="nav-link" activeClassName="active">{t('header.nav.howItWorks')}</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={`${langPrefix}/services`} className="nav-link" activeClassName="active">{t('header.nav.services')}</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={`${langPrefix}/price`} className="nav-link" activeClassName="active">{t('header.nav.prices')}</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={`${langPrefix}/for-companies`} className="nav-link" activeClassName="active">{t('header.nav.forCompanies')}</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={`${langPrefix}/eco-friendly`} className="nav-link" activeClassName="active">{t('header.nav.ecoFriendly')}</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to={`${langPrefix}/contact`} className="nav-link" activeClassName="active">{t('header.nav.contact')}</NavLink>
                                    </li>
                                </ul>
                            </div>
                            <div className="right-col d-flex align-items-center flex-row flex-lg-row ml-auto" style={{ whiteSpace: "nowrap" }}>
                                <NavLink to={`${langPrefix}/products`} className="nav-link shopping-button" activeClassName="active" style={{ marginLeft: "0px" }}>
                                   <span className="text-small">{t('header.products')}</span>
                                   <span>{t('header.showProducts')}</span>
                                </NavLink>
                                <LanguageSwitcher />
                                <div className={`user ml-auto ${userName ? 'logged-in' : ''}`}>
                                    <a
                                        href="/#"
                                        className="user-link"
                                        alt={t('header.account.yourAccount')}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            if (userName) {
                                                this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }))
                                            } else {
                                                OnAuthModalToggle()
                                            }
                                        }}>
                                        <div className="user-section">
                                            <i className="icon-profile"></i>
                                            {
                                            userName &&
                                                <span style={{ color: "white", fontFamily: "Brendon-Grotseque-regular", fontSize: "14px" }}>
                                                    <span className="desktop-text">{userName.split(' ')[0]}</span><span className="mobile-text">{Array.from(userName)[0] + '.'}</span>
                                                </span>
                                            }     
                                        </div>
                                       
                                    </a>
                                    <Dropdown isOpen={this.state.dropdownOpen}
                                        toggle={() => this.setState(prevState => ({
                                            dropdownOpen: !prevState.dropdownOpen
                                        }))}>
                                        <DropdownToggle caret style={{ display: "none" }} />
                                        <DropdownMenu className="user-login-menu">
                                            <DropdownItem tag={Link} to={`${langPrefix}/profile`}>{t('header.account.viewProfile')}</DropdownItem>
                                            {isAdmin && <DropdownItem tag={Link} to={`${langPrefix}/admin/orders`}>{t('header.account.adminOrders')}</DropdownItem>}
                                            {isAdmin && <DropdownItem tag={Link} to={`${langPrefix}/admin/users`}>{t('header.account.adminUsers')}</DropdownItem>}
                                            <DropdownItem tag={Link} to="#" onClick={() => OnUserLogout()}>{t('header.account.logout')}</DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                </div>
                                <div className="cart dropdown show">
                                    <Link
                                        to={`${langPrefix}/shopping-cart/page1`}
                                        className="dropdown-toggle d-flex"
                                        alt={t('header.cart.laundryBasket')}
                                    >
                                        <i className="icon-cart">
                                        </i>
                                        {
                                            itemsCount > 0 &&
                                            <div className="cart-no">{itemsCount}</div>
                                        }
                                        <span className="price-wrapper">
                                        <span style={{ color: "white", fontFamily: "Brendon-Grotseque-regular", fontSize: "14px" }}>
                                            {formatNumber(totalPrice)}
                                        </span>
                                        <span style={{ color: "white", fontFamily: "Brendon-Grotseque-regular", fontSize: "14px" }} className="ml-1">CHF</span>
                                        </span>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </nav>
                </header>
            </React.Fragment>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        OnAuthModalToggle: () => dispatch(actions.authModalToggle()),
        OnUserLogout: () => dispatch(actions.logout()),
        toggleSideBar: () => dispatch(actions.toggleSideBarModal())
    };
};

const mapStateToProps = state => ({
    isAdmin: state.auth.isAdmin,
    userName: getUsername(state),
    itemsCount: getItemsCount(state),
    totalPrice: state.shoppingCart.totalPrice
});

export default withTranslation()(withRouter(connect(mapStateToProps, mapDispatchToProps)(Header)));