import React, { Component } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form, FormGroup, Button } from 'reactstrap'
import { connect } from 'react-redux';
import { FORM_ERROR } from "final-form";
import { TextField } from 'final-form-material-ui';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import * as actions from '../../store/actions'

// eslint-disable-next-line no-useless-escape, no-control-regex
const validateEmail = (t) => value => (value ? (( /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i.test(value)) ? undefined : t('auth.modal.validation.invalidEmail')) : t('auth.modal.validation.required'))
const required = (t) => value => value ? undefined : t('auth.modal.validation.required')

class Login extends Component {
    onSubmit = async (values) => {
        const { OnAuth, history, location, t } = this.props
        try {
            await new Promise((resolve, reject) => {
                OnAuth(values.email, values.password, resolve, reject);
            });

            if (location.pathname === '/shopping-cart/page1') {
                history.push('/shopping-cart/page2');
            }
        } catch (error) {
            switch (error) {
                case 1:
                    return { [FORM_ERROR]: t('auth.modal.login.errors.emailNotFound') };
                case 2:
                    return { [FORM_ERROR]: t('auth.modal.login.errors.passwordMismatch') };
                default:
                    return { [FORM_ERROR]: t('auth.modal.login.errors.generalError') };
            }
        }
    }

    render() {
        const { OnPasswordResetClick } = this.props
        const { t } = this.props;

        return (
            <React.Fragment>
                <h4>{t('auth.modal.login.title')}</h4>
                <div style={{ height: "53px" }}></div>
                <FinalForm
                    onSubmit={this.onSubmit}
                    render={({
                        submitError,
                        handleSubmit,
                        submitting,
                        pristine,
                        validating,
                        hasValidationErrors
                    }) => (
                            <Form onSubmit={handleSubmit}>
                                <FormGroup>
                                    <Field
                                        validate={validateEmail(t)}
                                        fullWidth
                                        required
                                        name="email"
                                        component={TextField}
                                        type="text"
                                        placeholder={t('auth.modal.login.emailPlaceholder')}
                                        autoComplete="username"
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Field
                                        validate={required(t)}
                                        fullWidth
                                        required
                                        name="password"
                                        component={TextField}
                                        type="password"
                                        placeholder={t('auth.modal.login.passwordPlaceholder')}
                                        autoComplete="current-password"
                                    />
                                </FormGroup>
                                {submitError && <div className="error">{submitError}</div>}
                                <br />
                                <div className="row justify-content-center">
                                    <Button
                                        style={{ paddingLeft: "40px", paddingRight: "40px" }}
                                        color="primary"
                                        type="submit"
                                        disabled={submitting || validating || pristine || hasValidationErrors}>
                                        {submitting ? t('auth.modal.login.submitting') : t('auth.modal.login.submit')}
                                    </Button>
                                </div>
                                <div className="row justify-content-center mt-4">
                                    <Button
                                        style={{ color: "#000000", opacity: "0.6", fontSize: "12px" }}
                                        color="link"
                                        onClick={(e) => {
                                            e.preventDefault()
                                            e.stopPropagation()
                                            OnPasswordResetClick()
                                        }}>
                                        {t('auth.modal.login.resetPassword')}
                                    </Button>
                                </div>
                            </Form>
                        )
                    }
                />
            </React.Fragment>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        OnAuth: (email, password, resolve, reject) => dispatch(actions.auth(email, password, resolve, reject)),
        OnPasswordResetClick: () => dispatch(actions.resetPasswordModalShow()),
    };
};

export default connect(null, mapDispatchToProps)(withRouter(withTranslation()(Login)))