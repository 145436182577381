import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import ServicePageSection from "./ServicesPageSection";
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
class Services extends Component {
    render() {
        const { t } = this.props;
        return (
            <React.Fragment>
                <MetaTags>
                    <title>{t('services.meta.title')}</title>
                    <meta name="description" content={t('services.meta.description')} />
                    <meta name="image" content={t('services.meta.image')} />
                    <meta itemProp="name" content={t('services.meta.title')} />
                    <meta itemProp="description" content={t('services.meta.description')} />
                    <meta itemProp="image" content={t('services.meta.image')} />
                    <meta name="og:title" content={t('services.meta.title')} />
                    <meta name="og:description" content={t('services.meta.description')} />
                    <meta name="og:image" content={t('services.meta.image')} />
                    <meta name="og:url" content={t('services.meta.url')} />
                </MetaTags>
                <Helmet>
                    {i18next.language === 'de' ? (
                        <link rel="alternate" href="https://wös.ch/en/services" hreflang="en-gb" />
                    ) : (
                        <link rel="alternate" href="https://wös.ch/services"hreflang="de" />
                    )}
                </Helmet>
                <div>
                    <div className="services-page jumbotron" style={{ paddingBottom: "60px"}}>
                        <h1 className="carousel-text-header h1" style={{ paddingBottom: "20px" }}>{t('services.hero.title')}</h1>
                        <p className="carousel-text-content py-20 t1">
                            {t('services.hero.text1')}<br/>                        
                            {t('services.hero.text2')}<br/>    
                            {t('services.hero.text3')}
                        </p>
                        <img alt={t('services.hero.imageAlt')} src={require('../img/stitching_dein_butler.png')} className="img-services" />
                    </div>
                </div>
                <div className='container mobile-center' style={{textAlign: 'center'}}>
                    <div className="row">
                        <div className="col-lg-2"></div>
                        <div className="col-lg-8">
                            <ServicePageSection
                                image={<img alt={t('services.laundry.imageAlt')} className="service-page-section-img" src={require('../img/services/Schermata.png')}/>}
                                header={t('services.laundry.title')}
                                subHeader={t('services.laundry.subtitle')}
                                textHeader={t('services.laundry.textHeader')}
                                text={t('services.laundry.text')}
                            />
                            <br/>
                            <div className="row mobile-center" style={{textAlign: 'end'}}>
                                <div className="col-lg-5 lg-order-1">
                                    <img alt={t('services.ironing.imageAlt')} className="service-page-section-img" src={require('../img/services/iron.png')}/>
                                </div>
                                <div className="col-lg-7 lg-order-0">
                                    <span>
                                        <h2 className='service-page-section-header h2-service'>
                                           {t('services.ironing.title')}
                                        </h2>
                                        <p className="service-page-section-sub-header">
                                            {t('services.ironing.subtitle')}
                                        </p>
                                    </span>
                                </div>
                                <div
                                    style={{
                                        transformOrigin: 'center 0.5px',
                                        width: '852px',
                                        height: '5px',
                                        borderBottom: '1px solid black',
                                        marginBottom: "20px"
                                    }}/>
                                <span className="service-page-text-container-right">
                                    <p className='service-page-section-text-header'>
                                        {t('services.ironing.textHeader')}
                                    </p>
                                    <p className="service-page-section-text t2 mobile-center" style={{textAlign: 'end'}}>
                                        {t('services.ironing.text')}
                                    </p>
                                </span>
                            </div>
                            <br/>
                            <ServicePageSection
                                image={<img alt={t('services.tailoring.imageAlt')} className="service-page-section-img" src={require('../img/services/sewing.png')}/>}
                                header={t('services.tailoring.title')}
                                subHeader={t('services.tailoring.subtitle')}
                                textHeader={t('services.tailoring.textHeader')}
                                text={t('services.tailoring.text')}
                            />
                        </div>
                        <div className="col-lg-2"></div>
                    </div>
                </div>
                <br/>
                <div style={{backgroundColor:"#ececea", paddingTop: "50px", paddingBottom:"50px"}}>
                    <div className="services-block container">
                        <div className="row">
                            {['simple', 'comfortable', 'topPrice', 'helpful'].map((service) => (
                                <div key={service} className="col-lg-3 services-box">
                                    <img alt={t(`services.boxes.${service}.imageAlt`)} 
                                         className="services-icon" 
                                         src={require(`../img/wos/${t(`services.boxes.${service}.image`)}.png`)}/>
                                    <p className="service-box-header-text h2">{t(`services.boxes.${service}.title`)}</p>
                                    <p className="service-box-text t2">
                                        {t(`services.boxes.${service}.text`)}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withTranslation()(Services);