import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { Col, Row } from 'reactstrap'
import { withTranslation } from 'react-i18next';

class Footer extends Component {
    render() {
        const { t } = this.props;
        return (
            <footer className="main-footer">
                <div className="main-block">
                    <div className="container pt-3 pb-3">
                        <Row className="justify-content-md-center">
                            <Col sm="4">
                                <Row className="align-items-center">
                                    <Col md="auto">
                                        <img style={{ height: '120px', width: '120px' }}
                                            src={require("../img/logo_white.png")}
                                            alt={t('footer.logoAlt')}
                                        />
                                    </Col>
                                    <Col className="align-self-center" md="auto" style={{ fontSize: "15px", color: "white" }}>
                                        <Row><Col>Hohlstrasse 192</Col></Row>
                                        <Row><Col>8004 Zürich</Col></Row>
                                        <Row><Col>044 304 22 98</Col></Row>
                                        <Row><Col>info@woes.ch</Col></Row>
                                    </Col>
                                </Row>
                            </Col>
                            <Col className="align-self-center" sm="2" style={{ fontSize: "15px", color: "white" }}>
                                <Row>
                                    <Link to="/agb" style={{ color: "white" }}>{t('footer.links.terms')}</Link>
                                </Row>
                                <Row>
                                    <Link to="/privacy" style={{ color: "white" }}>{t('footer.links.privacy')}</Link>
                                </Row>
                                <Row>
                                    <Link to="/imprint" style={{ color: "white" }}>{t('footer.links.imprint')}</Link>
                                </Row>
                                <Row className="Link2">
                                    <Link to={this.props.i18n.language === 'en' ? '/en/faq' : '/faq'} style={{ color: "white" }}>{t('footer.links.faq')}</Link>
                                </Row>
                            </Col>
                            <Col className="align-self-center" sm="4" style={{ fontSize: "15px", color: "white" }}>
                                <Row><Col>{t('footer.hours.title')}</Col></Row>
                                <Row>
                                    <Col>{t('footer.hours.weekdays')}</Col>
                                    <Col>{t('footer.hours.weekdayHours')}</Col>
                                </Row>
                                <Row>
                                    <Col>{t('footer.hours.saturday')}</Col>
                                    <Col>{t('footer.hours.saturdayHours')}</Col>
                                </Row>
                                <Row>
                                    <Col>{t('footer.hours.online')}</Col>
                                    <Col>{t('footer.hours.onlineHours')}</Col>
                                </Row>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center" style={{ marginTop: "40px" }}>
                  
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/mastercard.png")}
                                alt={t('footer.payments.mastercard')}
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/visa.png")}
                                alt={t('footer.payments.visa')}
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/postfinance.png")}
                                alt={t('footer.payments.postfinance')}
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/twint.png")}
                                alt={t('footer.payments.twint')}
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/paypal.png")}
                                alt={t('footer.payments.paypal')}
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/rechnung.png")}
                                alt={t('footer.payments.invoice')}
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/prepaid_card.png")}
                                alt={t('footer.payments.prepaidCard')}
                            />
                            <img style={{ width: '70px', height: "50px", padding: "10px" }}
                                src={require("../img/payment_methods/gift_card.png")}
                                alt={t('footer.payments.giftCard')}
                            />
                        </Row>
                    </div>
                </div>
            </footer>
        );
    }
}

export default withTranslation()(Footer);