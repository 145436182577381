import React, { Component } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form, FormGroup, Button } from 'reactstrap'
import { connect } from 'react-redux';
import { FORM_ERROR } from "final-form";
import { TextField } from 'final-form-material-ui';
import { withTranslation } from 'react-i18next';

import * as actions from '../../store/actions'

const validate = (values, props) => {
    const { t } = props;
    const errors = {};

    if (!values.password) {
        errors.password = t('auth.modal.newPassword.validation.passwordRequired');
    } else if (values.password.length < 5) {
        errors.password = t('auth.modal.newPassword.validation.passwordMinLength');
    }
    if (!values.passwordCheck) {
        errors.passwordCheck = t('auth.modal.newPassword.validation.passwordCheckRequired');
    }
    if (values.password && values.passwordCheck) {
        if (values.password !== values.passwordCheck) {
            errors.passwordCheck = t('auth.modal.newPassword.validation.passwordMismatch');
        }
    }

    return errors;
}

class NewPassword extends Component {

    onSubmit = async (values) => {
        const { OnSetNewPassword, t } = this.props;
        try {
            await new Promise((resolve, reject) => {
                OnSetNewPassword(values.token, values.password, resolve, reject);
            });
        } catch (error) {
            switch (error) {
                case 1:
                    return { [FORM_ERROR]: t('auth.modal.newPassword.errors.emailNotFound') };
                case 2:
                    return { [FORM_ERROR]: t('auth.modal.newPassword.errors.passwordMismatch') };
                default:
                    return { [FORM_ERROR]: t('auth.modal.newPassword.errors.generalError') };
            }
        }
    }

    render() {
        const { token, OnNewPasswordModalToggle, t } = this.props;

        return (
            <React.Fragment>
                <div style={{ height: "10px" }}></div>
                <FinalForm
                    onSubmit={this.onSubmit}
                    validate={(values) => validate(values, this.props)}
                    initialValues={{ token: token }}
                    render={({
                        submitError,
                        handleSubmit,
                        submitting,
                        pristine,
                        validating,
                        hasValidationErrors
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            <FormGroup>
                                <Field
                                    fullWidth
                                    required
                                    name="password"
                                    component={TextField}
                                    type="password"
                                    placeholder={t('auth.modal.newPassword.password')}
                                    label={t('auth.modal.newPassword.password')}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Field
                                    fullWidth
                                    required
                                    name="passwordCheck"
                                    component={TextField}
                                    type="password"
                                    placeholder={t('auth.modal.newPassword.passwordCheck')}
                                    label={t('auth.modal.newPassword.passwordCheck')}
                                />
                            </FormGroup>
                            {submitError && <div className="error">{submitError}</div>}
                            <br />
                            <div className="row justify-content-between">
                                <Button
                                    className="ml-3"
                                    color="secondary"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        OnNewPasswordModalToggle()
                                    }}>
                                    {t('auth.modal.newPassword.buttons.cancel')}
                                </Button>
                                <Button
                                    className="mr-3"
                                    color="primary"
                                    type="submit"
                                    disabled={submitting || validating || pristine || hasValidationErrors}>
                                    {submitting ? t('auth.modal.newPassword.buttons.confirming') : t('auth.modal.newPassword.buttons.confirm')}
                                </Button>
                            </div>
                        </Form>
                    )}
                />
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return {
        token: state.auth.passwordResetToken,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        OnSetNewPassword: (token, password, resolve, reject) => dispatch(actions.setNewPassword(token, password, resolve, reject)),
        OnNewPasswordModalToggle: () => dispatch(actions.newPasswordModalToggle())
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NewPassword));