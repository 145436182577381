import React from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { formatNumber } from '../../../Utils'
import { withTranslation } from 'react-i18next';

const AdditionalItem = ({
    decoration,
    decorationSelected,
    decorationPrice,
    plated,
    platedSelected,
    platedPrice,
    sequined,
    sequinedSelected,
    sequinedPrice,
    t
}) => (
    <ListGroup flush className="shopping-item-section">
        <ListGroupItem className="item-main-header">
            <strong>{t('cart.sections.surcharge')}</strong>
        </ListGroupItem>
        {
            decorationSelected &&
            <ListGroupItem className="item-button" active={decorationSelected}>
                <div className="d-flex item-header-font">
                    <div className="item-header">{t('cart.options.withDeco')}</div>
                    <div className="small-price">
                        {formatNumber(decorationPrice)}
                    </div>
                </div>
            </ListGroupItem>
        }
        {
            platedSelected &&
            <ListGroupItem className="item-button" active={platedSelected}>
                <div className="d-flex item-header-font">
                    <div className="item-header">{t('cart.options.withPlated')}</div>
                    <div className="small-price">
                        {formatNumber(platedPrice)}
                    </div>
                </div>
            </ListGroupItem>
        }
        {
            sequinedSelected &&
            <ListGroupItem className="item-button" active={sequinedSelected}>
                <div className="d-flex">
                    <div className="item-header">{t('cart.options.withSequins')}</div>
                    <div className="small-price">
                        {formatNumber(sequinedPrice)}
                    </div>
                </div>
            </ListGroupItem>
        }
    </ListGroup>
)

export default withTranslation()(AdditionalItem)