import { put, call } from "redux-saga/effects";
import i18next from 'i18next';

import * as actions from "../actions";

export function* GetProducts() {

    yield put(actions.getProductsStart());

    try {
        const response = yield call(fetch, '/api/products', {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept-Language": i18next.language.split('-')[0] === 'en' ? 'en-GB' : 'de-DE'
            }
        });

        const products = yield response.json();

        yield put(actions.getProductsSuccess(products));

    } catch (e) {
        yield put(actions.getProductsFail(e));
        return;
    }

}