import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';

import NewPassword from './NewPassword'
import * as actions from '../../store/actions'

class NewPasswordModal extends React.Component {
  render() {
    const { newPasswordShowed, OnNewPasswordModalToggle } = this.props;
    const { t } = this.props;  // Get t from props

    return (
      <Modal isOpen={newPasswordShowed} toggle={() => OnNewPasswordModalToggle()} centered size="sm">
        <ModalHeader toggle={() => OnNewPasswordModalToggle()}>
          {t('auth.modal.newPassword.title')}
        </ModalHeader>
        <ModalBody>
          <NewPassword />
        </ModalBody>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    newPasswordShowed: state.auth.newPasswordShowed
  };
};

const mapDispatchToProps = dispatch => {
  return {
    OnNewPasswordModalToggle: () => dispatch(actions.newPasswordModalToggle())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(NewPasswordModal));