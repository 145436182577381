import React from 'react'
import MetaTags from 'react-meta-tags';

const Datenschutz = () => (
    <div className="container mx-auto">
        <MetaTags>
            <title>Datenschutz | WÖS.CH</title>
            <meta name="description" content="Auf dieser Seite findest Du unsere Datenschutzbestimmungen." />
            <meta name="image" content="https://www.wös.ch/images/logo.png" />
            <meta itemProp="name" content="Unsere Datenschutzbestimmungen | WÖS.CH" />
            <meta itemProp="description" content="Auf dieser Seite findest Du unsere Datenschutzbestimmungen." />
            <meta itemProp="image" content="https://www.wös.ch/images/logo.png" />
            <meta name="og:title" content="Unsere Datenschutzbestimmungen | WÖS.CH" />
            <meta name="og:description" content="Auf dieser Seite findest Du unsere Datenschutzbestimmungen." />
            <meta name="og:image" content="https://www.wös.ch/images/logo.png" />
            <meta name="og:url" content="https://www.wös.ch/datenschutz" />
            <meta name="og:site_name" content="wös.ch" />
            <meta name="og:locale" content="de_CH" />
            <meta name="og:type" content="website" />
        </MetaTags>
        <div data-packed="false" data-min-height="91"
            style={{
                minHeight: "91px",
                marginTop: "85px",
                marginBottom: "0px",
                verticalAlign: "bottom"
            }}>
            <h1 className="font_3" style={{ textAlign: "center", marginBottom: "0px", marginTop: "0px", verticalAlign: "bottom", color: "rgb(0, 0, 0)" }}>
                Datenschutz
            </h1>
        </div>
        <div data-packed="false" data-min-height="392" className="mx-auto"
            style={{
                maxWidth: "747px",
                minHeight: "392px", 
                marginTop: "0px"
            }} >
            <p className="font_8" style={{
                color: "#000000",
                fontSize: "20px",
                textAlign: "center",
                fontFamily: "Brendon-Grotseque-light"
            }}>
                Unsere Datenschutzbestimmungen
            </p>

            <div dangerouslySetInnerHTML={{__html:`
                <style>
            #jmdcontent h1, #jmdcontent h2, #jmdcontent h3, #jmdcontent h4, #jmdcontent h5, #jmdcontent h6 {
                margin: 1.5em 0 1em 0;
            }

            #jmdcontent {
                font-size: 14px;
                line-height: 1.5;
                text-align: left;
            }

            #jmdcontent a {
                word-break: break-word;
            }

            #jmdcontent code {
                background-color: #f5f5f5;
                color: #bd4147;
                box-shadow: 0 2px 1px -1px rgba(0,0,0,.2), 0 1px 1px 0 rgba(0,0,0,.14), 0 1px 3px 0 rgba(0,0,0,.12);
                display: inline-block;
                border-radius: 3px;
                white-space: pre-wrap;
                font-size: 85%;
                font-weight: 900;
            }

            #jmdcontent code:after, #jmdcontent code:before {
                content: "\A0";
                letter-spacing: -1px;
            }

            #jmdcontent table {
                border: solid 1px #EEEEEE;
                border-collapse: collapse;
                border-spacing: 0;
                font-size: 0.9em;
                line-height: normal;
            }

            #jmdcontent table thead th {
                background-color: #EFEFEF;
                padding: 10px;
                text-align: left;
                color: black;
                margin: revert;
                width: revert;
                height: revert;
            }

            #jmdcontent table tbody td {
                border: solid 1px #EEEEEE;
                padding: 10px;
            }

            #jmdcontent .cancellation-policy, #jmdcontent .jmd-bordered {
                border: solid 1px;
                padding: 0em 1em;
            }

            #jmdcontent ol {
              counter-reset: item;
            }

            #jmdcontent ol > li {
              display: block;
            }
                        
            #jmdcontent li {
             list-style-position: outside !important;
            }

            #jmdcontent ol > li >*:first-child:before {
              content: counters(item, ".") ". ";
              counter-increment: item
            }

            @media (max-width: 1600px) {

                #jmdcontent table, #jmdcontent thead, #jmdcontent tbody, #jmdcontent tfoot,
                #jmdcontent tr, #jmdcontent th, #jmdcontent td {
                    display: block;
                }

                #jmdcontent thead tr, #jmdcontent tfoot tr > th {
                    display: none;
                }

                #jmdcontent tr {
                    border: 1px solid #ccc;
                    margin-bottom: 1em;
                }

                #jmdcontent tr:last-child {
                    margin-bottom: 0;
                }

                #jmdcontent table tbody  td {
                    border: none;
                    border-bottom: 1px solid #eee;
                    position: relative;
                    white-space: normal;
                    text-align: left;
                    padding: 5px 10px 5px calc(30% + 20px);
                }

                #jmdcontent table tbody td:before {
                    content: attr(data-header);
                    position: absolute;
                    top: 1px;
                    left: 1px;
                    width: 30%;
                    padding: 5px 10px;
                    white-space: nowrap;
                    text-align: left;
                    font-weight: bold;
                }
            }

</style>
<div id='jmdcontent' data-jid="598823" data-jtype="policy" lang="de">
<p>Wir freuen uns über Ihren Besuch auf unserer Internetseite wös.ch und Ihr Interesse an unserem Unternehmen.</p>
<p>Der Schutz Ihrer personenbezogenen Daten, wie z.B. Geburtsdatum, Name, Telefonnummer, Anschrift etc., ist uns ein wichtiges Anliegen.</p>
<p>Der Zweck dieser Datenschutzerklärung besteht darin, Sie über die Bearbeitung Ihrer personenbezogenen Daten zu informieren, die wir bei einem Seitenbesuch von Ihnen sammeln.
Unsere Datenschutzpraxis steht im Einklang mit den gesetzlichen Regelungen des Bundesgesetzes über den Datenschutz (DSG) der Schweiz.
Die nachfolgende Datenschutzerklärung dient der Erfüllung der Informationspflichten aus dem DSG.
Diese finden sich z.B. in Art. 19 ff. DSG.</p>
<h2 id="jsectionanameresponsibleentityainhaber"><a name="responsible-entity"></a> Inhaber</h2>
<p>Verantwortlicher der Daten im Sinne von Art. 5 Bst. j DSG sind private Personen oder das Bundesorgan, die über den Zweck und die Mittel der Bearbeitung entscheiden.</p>
<p>Im Hinblick auf unsere Internetseite ist der Inhaber:</p>
<p>wös.ch GmbH<br />
Hohlstrasse 192<br />
8004 Zürich<br />
Schweiz<br />
E-Mail: <a href="mailto:info@woes.ch" rel="noopener noreferrer" target="_blank">info@woes.ch</a><br />
Tel.: 044 304 22 98  </p>
<h2 id="jsectionbereitstellungderwebsiteunderstellungvonlogfiles">Bereitstellung der Website und Erstellung von Logfiles</h2>
<p>Bei jedem Aufruf unserer Internetseite erfasst unser System automatisiert Daten und Informationen des jeweils abrufenden Gerätes (z.B. Computer, Mobiltelefon, Tablet, etc.).</p>
<h4 id="jsectionwelchepersonenbezogenendatenwerdenerhobenundinwelchemumfangwerdendiesebearbeitet">Welche personenbezogenen Daten werden erhoben und in welchem Umfang werden diese bearbeitet?</h4>
<p>(1) Informationen über den Browsertyp und die verwendete Version;<br />
(2) Das Betriebssystem des Abrufgerätes;<br />
(3) Hostname des zugreifenden Rechners;<br />
(4) Die IP-Adresse des Abrufgerätes;<br />
(5) Datum und Uhrzeit des Zugriffs;<br />
(6) Websites und Ressourcen (Bilder, Dateien, weitere Seiteninhalte), die auf unserer Internetseite aufgerufen wurden;<br />
(7) Websites, von denen das System des Nutzers auf unsere Internetseite gelangte (Referrer-Tracking);<br />
(8) Meldung, ob der Abruf erfolgreich war;<br />
(9) Übertragene Datenmenge  </p>
<p>Diese Daten werden in den Logfiles unseres Systems gespeichert.
Eine Speicherung dieser Daten zusammen mit personenbezogenen Daten eines konkreten Nutzers findet nicht statt, so dass eine Identifizierung einzelner Seitenbesucher nicht erfolgt.</p>
<h4 id="jsectionrechtsgrundlagefrdiebearbeitungpersonenbezogenerdaten">Rechtsgrundlage für die Bearbeitung personenbezogener Daten</h4>
<p>Die Bearbeitung personenbezogener Daten erfolgt nach dem Prinzip der Rechtmässigkeit (Art. 6 Abs. 1 DSG) und nach dem Gebot von Treu und Glauben (Art. 6 Abs. 2 DSG bzw. Art. 2 ZGB).</p>
<h4 id="jsectionzweckderdatenbearbeitung">Zweck der Datenbearbeitung</h4>
<p>Die vorübergehende (automatisierte) Speicherung der Daten ist für den Ablauf eines Websitebesuchs erforderlich, um eine Auslieferung der Website zu ermöglichen.
Die Speicherung und Bearbeitung der personenbezogenen Daten erfolgt zudem zur Erhaltung der Kompatibilität unserer Internetseite für möglichst alle Besucher und zur Missbrauchsbekämpfung und Störungsbeseitigung.
Hierfür ist es notwendig, die technischen Daten des abrufenden Rechners zu loggen, um so frühestmöglich auf Darstellungsfehler, Angriffe auf unsere IT-Systeme und/oder Fehler der Funktionalität unserer Internetseite reagieren zu können.
Zudem dienen uns die Daten zur Optimierung der Website und zur generellen Sicherstellung der Sicherheit unserer informationstechnischen Systeme.</p>
<h4 id="jsectiondauerderspeicherung">Dauer der Speicherung</h4>
<p>Die Löschung der vorgenannten technischen Daten erfolgt, sobald sie nicht mehr benötigt werden, um die Kompatibilität der Internetseite für alle Besucher zu gewährleisten, spätestens aber 3 Monate nach Abruf unserer Internetseite.</p>
<h4 id="jsectioneinschrnkungswiderspruchsberichtigungsundlschungsmglichkeit">Einschränkungs-, Widerspruchs-, Berichtigungs- und Löschungsmöglichkeit</h4>
<p>Sie können jederzeit eine Berichtigung oder Löschung der Daten verlangen.
Welche Rechte Ihnen zustehen und wie Sie diese geltend machen, finden Sie im unteren Bereich dieser Datenschutzerklärung.</p>
<h2 id="jsectionbesonderefunktionenderinternetseite">Besondere Funktionen der Internetseite</h2>
<p>Unsere Seite bietet Ihnen verschiedene Funktionen, bei deren Nutzung von uns personenbezogene Daten erhoben, bearbeitet und gespeichert werden.
Nachfolgend erklären wir, was mit diesen Daten geschieht:</p>
<h3 id="jsectionbestellformular">Bestellformular</h3>
<ul>
<li><h4 id="jsectionwelchepersonenbezogenendatenwerdenerhobenundinwelchemumfangwerdendiesebearbeitet-1">Welche personenbezogenen Daten werden erhoben und in welchem Umfang werden diese bearbeitet?</h4>
<p>Die von Ihnen in die Formularfelder eingegebenen Daten, wie z.B. Adresse, Name, Vorname, etc., werden von uns zur Erfüllung des unten genannten Zwecks bearbeitet.</p></li>
<li><h4 id="jsectionrechtsgrundlagefrdiebearbeitungpersonenbezogenerdaten-1">Rechtsgrundlage für die Bearbeitung personenbezogener Daten</h4>
<p>Die Bearbeitung personenbezogener Daten erfolgt nach dem Prinzip der Rechtmässigkeit (Art. 6 Abs. 1 DSG) und nach dem Gebot von Treu und Glauben (Art. 6 Abs. 2 DSG bzw. Art. 2 ZGB).</p></li>
<li><h4 id="jsectionzweckderdatenbearbeitung-1">Zweck der Datenbearbeitung</h4>
<p>Der Zweck der Datenbearbeitung besteht in der Bearbeitung Ihrer Bestellung, damit wir den mit Ihnen geschlossenen Vertrag erfüllen bzw. anbahnen können.</p></li>
<li><h4 id="jsectiondauerderspeicherung-2">Dauer der Speicherung</h4>
<p>Die Löschung der Daten erfolgt, sobald diese für die Bearbeitung der Bestellung nicht mehr benötigt werden und keine gesetzlichen Aufbewahrungspflichten mehr bestehen.
In der Regel sieht der Gesetzgeber eine Aufbewahrungspflicht von 10 Jahren vor.</p></li>
<li><h4 id="jsectionerforderlichkeitderangabepersonenbezogenerdaten">Erforderlichkeit der Angabe personenbezogener Daten</h4>
<p>Die Angaben im Bestellformular sind zum Abschluss eines Vertrages notwendig.
Sofern Sie die vorhandenen Pflichtfelder nicht oder nicht vollständig ausfüllen, kann die von Ihnen gewünschte Bestellung nicht ausgeführt werden.</p></li>
</ul>

<h3 id="jsectionkontaktformulare">Kontaktformular(e)</h3>
<ul>
<li><h4 id="jsectionwelchepersonenbezogenendatenwerdenerhobenundinwelchemumfangwerdendiesebearbeitet-2">Welche personenbezogenen Daten werden erhoben und in welchem Umfang werden diese bearbeitet?</h4>
<p>Die von Ihnen in unsere Kontaktformulare eingegebenen Daten, die Sie in die Eingabemaske des Kontaktformulares eingetragen haben.</p></li>
<li><h4 id="jsectionrechtsgrundlagefrdiebearbeitungpersonenbezogenerdaten-3">Rechtsgrundlage für die Bearbeitung personenbezogener Daten</h4>
<p>Die Bearbeitung personenbezogener Daten erfolgt nach dem Prinzip der Rechtmässigkeit (Art. 6 Abs. 1 DSG) und nach dem Gebot von Treu und Glauben (Art. 6 Abs 2 DSG bzw. Art. 2 ZGB).</p></li>
<li><h4 id="jsectionzweckderdatenbearbeitung-3">Zweck der Datenbearbeitung</h4>
<p>Die über unser Kontaktformular bzw. über unsere Kontaktformulare aufgenommenen Daten werden wir nur für die Bearbeitung der konkreten Kontaktanfrage verwenden, die durch das Kontaktformular eingeht.
Bitte beachten Sie, dass wir zur Erfüllung ihrer Kontaktanfrage Ihnen unter Umständen auch E-Mails an die angegebene Adresse senden können.
Dies hat den Zweck, dass Sie von uns eine Bestätigung erhalten können, dass Ihre Anfrage an uns korrekt weitergeleitet wurde.
Der Versand dieser Bestätigungs-E-Mail ist jedoch für uns nicht verpflichtend und dient nur Ihrer Information.</p></li>
<li><h4 id="jsectiondauerderspeicherung-4">Dauer der Speicherung</h4>
<p>Nach Bearbeitung Ihrer Anfrage werden die erhobenen Daten unverzüglich gelöscht, soweit keine gesetzlichen Aufbewahrungsfristen bestehen.</p></li>
<li><h4 id="jsectioneinschrnkungswiderspruchsberichtigungsundlschungsmglichkeit-1">Einschränkungs-, Widerspruchs-, Berichtigungs- und Löschungsmöglichkeit</h4>
<p>Sie können jederzeit eine Berichtigung oder Löschung der Daten verlangen.
Welche Rechte Ihnen zustehen und wie Sie diese geltend machen, finden Sie im unteren Bereich dieser Datenschutzerklärung.</p></li>
<li><h4 id="jsectionerforderlichkeitderangabepersonenbezogenerdaten-2">Erforderlichkeit der Angabe personenbezogener Daten</h4>
<p>Die Nutzung der Kontaktformulare erfolgt auf freiwilliger Basis.
Sie sind nicht verpflichtet mit uns über das Kontaktformular Kontakt aufzunehmen, sondern können auch die weiteren, auf unserer Seite angegebenen Kontaktmöglichkeiten, nutzen.
Sofern Sie unser Kontaktformular nutzen möchten, müssen Sie die als Pflichtangaben gekennzeichneten Felder ausfüllen.
Sofern Sie die notwendigen Angaben des Kontaktformulares nicht ausfüllen, können Sie entweder die Anfrage nicht absenden oder wir können Ihre Anfrage mangels Angaben nicht bearbeiten.</p></li>
</ul>
<h3 id="jsectionloginbereichregistrierung">Login-Bereich / Registrierung</h3>
<ul>
<li><h4 id="jsectionumfangderbearbeitungpersonenbezogenerdatenunderhobenepersonenbezogenedaten">Umfang der Bearbeitung personenbezogener Daten und erhobene personenbezogene Daten</h4>
<p>Die von Ihnen bei uns eingegebenen oder die Ihnen mitgeteilten Registrierungs- und Login-Daten.</p></li>
<li><h4 id="jsectionrechtsgrundlagefrdiebearbeitungpersonenbezogenerdaten-4">Rechtsgrundlage für die Bearbeitung personenbezogener Daten</h4>
<p>Die Bearbeitung personenbezogener Daten erfolgt nach dem Prinzip der Rechtmässigkeit (Art. 6 Abs. 1 DSG) und nach dem Gebot von Treu und Glauben (Art. 6 Abs. 2 DSG bzw. Art. 2 ZGB).</p></li>
<li><h4 id="jsectionzweckderdatenbearbeitung-4">Zweck der Datenbearbeitung</h4>
<p>Sie haben auf unserer Internetseite die Möglichkeit, einen separaten Login-Bereich zu nutzen.
Damit wir Ihre Berechtigung zur Nutzung des geschützten Bereichs bzw. der geschützten Dokumente prüfen können, müssen Sie Ihre Login-Daten (E-Mail bzw. Nutzername und Passwort) in das entsprechende Formular eingeben.
Sofern benötigt, können wir Ihnen auf Anfrage Ihre Login-Daten bzw. die Möglichkeit das Passwort zurücksetzen zu lassen per E-Mail zusenden.</p></li>
<li><h4 id="jsectiondauerderspeicherung-5">Dauer der Speicherung</h4>
<p>Die erhobenen Daten werden so lange gespeichert, wie Sie ein Benutzerkonto bei uns unterhalten.</p></li>
<li><h4 id="jsectioneinschrnkungswiderspruchsberichtigungsundlschungsmglichkeit-2">Einschränkungs-, Widerspruchs-, Berichtigungs- und Löschungsmöglichkeit</h4>
<p>Sie können jederzeit eine Berichtigung oder Löschung der Daten verlangen.
Welche Rechte Ihnen zustehen und wie Sie diese geltend machen, finden Sie im unteren Bereich dieser Datenschutzerklärung.</p></li>
<li><h4 id="jsectionerforderlichkeitderangabepersonenbezogenerdaten-3">Erforderlichkeit der Angabe personenbezogener Daten</h4>
<p>Bestimmte Seiten und deren Inhalte sind nicht öffentlich zugänglich.
Via Login-Bereich auf unserer Seite können bestimmte Anwender Zugang in den geschützten Bereich erhalten.
Die Nutzung des durch den Login-Bereich geschützten Inhalts ist ohne die Eingabe der personenbezogenen Daten nicht möglich.
Sofern Sie unseren Login-Bereich nutzen möchten, müssen Sie die als Pflichtangaben gekennzeichneten Felder (Benutzername und Passwort) ausfüllen.
Die Eingabe der Daten setzt das Bestehen eines Nutzerkontos voraus.
Eine Anmeldung ist nicht möglich, wenn die von Ihnen eingegebenen Daten falsch sind.
Sofern die Daten von Ihnen falsch oder nicht eingegeben werden, kann der geschützte Bereich nicht genutzt werden.
Der Rest der Seite ist jedoch weiterhin ohne Login nutzbar.</p></li>
</ul>
<h3 id="jsectionformularzurnewsletteranmeldung">Formular zur Newsletter-Anmeldung</h3>
<ul>
<li><h4 id="jsectionwelchepersonenbezogenendatenwerdenerhobenundinwelchemumfangwerdendiesebearbeitet-3">Welche personenbezogenen Daten werden erhoben und in welchem Umfang werden diese bearbeitet?</h4>
<p>Durch die Anmeldung zum Newsletter auf unserer Website erhalten wir die im Anmeldefeld durch Sie eingegebene E-Mailadresse und ggf. weitere Kontaktdaten, sofern Sie uns diese über das Newsletter-Anmeldeformular mitteilen.</p></li>
<li><h4 id="jsectionrechtsgrundlagefrdiebearbeitungpersonenbezogenerdaten-5">Rechtsgrundlage für die Bearbeitung personenbezogener Daten</h4>
<p>Die Bearbeitung personenbezogener Daten erfolgt nach dem Prinzip der Rechtmässigkeit (Art. 6 Abs. 1 DSG) und nach dem Gebot von Treu und Glauben (Art. 6 Abs. 2 DSG bzw. Art. 2 ZGB).</p></li>
<li><h4 id="jsectionzweckderdatenbearbeitung-5">Zweck der Datenbearbeitung</h4>
<p>Die in der Anmeldemaske unseres Newsletters aufgenommenen Daten werden von uns ausschliesslich für den Versand unseres Newsletters verwendet, in dem wir über alle unsere Leistungen und unsere Neuigkeiten informieren.
Wir werden Ihnen nach der Anmeldung eine Bestätigungs-E-Mail senden, in der ein Link enthalten ist, den Sie anklicken müssen, um die Anmeldung zu unserem Newsletter abzuschliessen (Double-Opt-In).
Damit geben Sie Ihre Einwilligung zur Datenbearbeitung nach Art. 6 Abs. 6 DSG.</p></li>
<li><h4 id="jsectiondauerderspeicherung-6">Dauer der Speicherung</h4>
<p>Unser Newsletter kann jederzeit durch Anklicken des Abmelde-Links, der auch in jedem Newsletter enthalten ist, abbestellt werden.
Ihre Daten werden unverzüglich nach der Abmeldung von uns gelöscht, sofern keine gesetzlichen Aufbewahrungspflichten bestehen.
Ebenso werden Ihre Daten im Falle einer nicht abgeschlossenen Anmeldung unverzüglich von uns gelöscht.
Wir behalten uns die Löschung ohne Angaben von Gründen und ohne vorherige oder nachträgliche Information vor.</p></li>
<li><h4 id="jsectioneinschrnkungswiderspruchsberichtigungsundlschungsmglichkeit-3">Einschränkungs-, Widerspruchs-, Berichtigungs- und Löschungsmöglichkeit</h4>
<p>Sie können jederzeit eine Berichtigung oder Löschung der Daten verlangen.
Welche Rechte Ihnen zustehen und wie Sie diese geltend machen, finden Sie im unteren Bereich dieser Datenschutzerklärung.</p></li>
<li><h4 id="jsectionerforderlichkeitderangabepersonenbezogenerdaten-4">Erforderlichkeit der Angabe personenbezogener Daten</h4>
<p>Sofern Sie unseren Newsletter nutzen möchten, müssen Sie die als Pflichtangaben gekennzeichneten Felder ausfüllen und uns die E-Mail-Adresse durch Anklicken des Double-Opt-In Links bestätigen.
Die Angaben zur Newsletteranmeldung sind notwendig, um vom Newsletter-Angebot Gebrauch machen zu können.
Die Angaben dienen ausschliesslich der Versendung unseres Newsletters.
Sofern Sie die Pflichtfelder nicht ausfüllen, können wir Ihnen unseren Newsletterservice nicht zur Verfügung stellen.</p></li>
</ul>
<h2 id="jsectionweitergabevonangabenandritte">Weitergabe von Angaben an Dritte</h2>
<p>Die Bearbeitung personenbezogener Daten erfolgt nach dem Prinzip der Rechtmässigkeit (Art. 6 Abs. 1 DSG) und nach dem Gebot von Treu und Glauben (Art. 6 Abs. 2 DSG bzw. Art. 2 ZGB).</p>
<p>Die Weitergabe von Angaben an Dritte richtet sich nach dem Umfang nachfolgend beschriebener Tätigkeiten oder Angebote unserer Website bzw. unseres Geschäftsmodells.</p>
<p>Grundsätzlich behalten wir Ihre Angaben nur so lange wie nötig und behandeln diese vertraulich.
Ausgenommen davon sind die Übertragung personenbezogener Daten an Inkasso-Dienstleister, an öffentliche Stellen und Behörden sowie an Privatpersonen,
die aufgrund gesetzlicher Bestimmungen, gerichtlichen Entscheidungen oder behördlichen Anordnungen einen Anspruch darauf haben
sowie die Übertragung an Behörden zum Zwecke der Einleitung rechtlicher Verfahren oder für Strafverfolgungszwecke, falls unsere gesetzlich geschützten Rechte angegriffen werden.</p>
<h2 id="jsectionstatistischeauswertungderbesuchedieserinternetseitewebtracker">Statistische Auswertung der Besuche dieser Internetseite - Webtracker</h2>
<p>Wir erheben, bearbeiten und speichern bei dem Aufruf dieser Internetseite oder einzelner Dateien der Internetseite folgende Daten:
IP-Adresse, Webseite, von der aus die Datei abgerufen wurde, Name der Datei, Datum und Uhrzeit des Abrufs, übertragene Datenmenge und Meldung über den Erfolg des Abrufs (sog. Web-Log).
Diese Zugriffsdaten verwenden wir ausschliesslich in nicht personalisierter Form für die stetige Verbesserung unseres Internetangebots und zu statistischen Zwecken.</p>
<p>Die Bearbeitung allfällig personenbezogener Daten erfolgt nach dem Prinzip der Rechtmässigkeit (Art. 6 Abs. 1 DSG) und nach dem Gebot von Treu und Glauben (Art. 6 Abs. 2 DSG bzw. Art. 2 ZGB).
Wir setzen zur Auswertung der Besuche dieser Internetseite zudem noch folgende Webtracker ein:</p>
<ul>
<li><h3 id=""><a name="tracker-102"></a> Matomo (on Premise)</h3>
<p>Wir nutzen auf unserer Website den Webanalysedienst Matomo (ehemals Piwik). Matomo ist eine Open-Source-Software zur statistischen Auswertung der Besucherzugriffe. Die Installation und der Betrieb von Matomo erfolgen ausschließlich auf unseren eigenen Servern (on-premise). Dies bedeutet, dass sämtliche Daten ausschließlich auf unseren Servern gespeichert werden und nicht an Dritte weitergegeben werden.</p>
<p>Rechtsgrundlage für die Übermittlung der personenbezogenen Daten stellt Ihre Einwilligung gem. Art. 6 Abs. 6 DSG bzw. Art. 31 Abs. 1 DSG dar, die Sie auf unserer Internetseite getätigt haben.</p>
<h4>Verarbeitete Daten</h4>
    <p>Mit Matomo werden die folgenden Daten erfasst:</p>
    <ul>
        <li>Die gekürzte IP-Adresse des Nutzers (anonymisiert)</li>
        <li>Datum und Uhrzeit des Zugriffs</li>
        <li>Titel der aufgerufenen Seite</li>
        <li>URL der aufgerufenen Seite</li>
        <li>URL der zuvor besuchten Seite (Referrer-URL)</li>
        <li>Verwendete Bildschirmauflösung</li>
        <li>Zeit in der Zeitzone des lokalen Nutzers</li>
        <li>Dateien, die angeklickt und heruntergeladen wurden</li>
        <li>Links auf eine externe Domain, die angeklickt wurden</li>
        <li>Dauer des Seitenbesuchs</li>
        <li>Herkunftsland des Nutzers</li>
        <li>Sprache des Browsers</li>
        <li>User-Agent des Browsers</li>
    </ul>
<h4>Anonymisierung der IP-Adresse</h4>
    <p>Ihre IP-Adresse wird vor der Speicherung anonymisiert, sodass eine direkte Personenbeziehbarkeit ausgeschlossen ist. Hierzu wird die IP-Adresse um die letzten beiden Bytes gekürzt (z.B. 192.168.xxx.xxx). Dadurch ist eine Zuordnung der gekürzten IP-Adresse zum aufrufenden Rechner nicht mehr möglich.</p>

    <h2>Opt-out-Möglichkeit</h2>

    <p>Wenn Sie mit der Speicherung und Nutzung Ihrer Daten durch Matomo nicht einverstanden sind, können Sie die Speicherung und Nutzung nachfolgend per Mausklick deaktivieren. In diesem Fall wird in Ihrem Browser ein Opt-out-Cookie abgelegt, der verhindert, dass Matomo Nutzungsdaten speichert. Bitte beachten Sie, dass das Löschen von Cookies zur Folge haben kann, dass auch das Opt-out-Cookie gelöscht wird und ggf. von Ihnen erneut aktiviert werden muss.</p>
                <a href="javascript:void(0);" onclick="if(window._paq) window._paq.push(['optUserOut']);alert('Tracking deaktiviert.');">Hier klicken, um Matomo-Tracking zu deaktivieren</a>

<li><h3 id="jsectionanametracker102agoogle"><a name="tracker-102"></a> Google</h3>
<p>Wir verwenden auf unserer Seite den Dienst Google des Unternehmens Google Ireland Limited, Gordon House, Barrow Street, 4 Dublin, Irland, E-Mail: <a href="mailto:support-deutschland@google.com" rel="noopener noreferrer" target="_blank">support-deutschland@google.com</a>, Website: <a href="https://www.google.com/" rel="noopener noreferrer" target="_blank">https://www.google.com/</a>.
<span>Die Bearbeitung findet gemäss Einschätzung von Schweizer Behörden in sicheren Drittländern statt.
Die Staatenliste der Schweiz sowie weiterführende Informationen finden Sie unter folgendem Link: <a href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html" rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html</a>.</span></p>
<p>Rechtsgrundlage für die Übermittlung der personenbezogenen Daten stellt Ihre Einwilligung gem. Art. 6 Abs. 6 DSG bzw. Art. 31 Abs. 1 DSG dar, die Sie auf unserer Internetseite getätigt haben.</p>
<p>Wir verwenden Google  um auf der Internetseite weitere Dienste von Google nachladen zu können. Der Dienst wird dazu verwendet weitere Google-Dienste bereitstellen zu können, wie z.B. der benötigten Datenverarbeitung bei der Bereitstellung von Streams und Schriftarten und relevante Inhalte der Google-Suche. Er wird technisch benötigt, um die bereits Google vorliegenden Informationen des Seitenbesuchers zwischen den Google-Diensten austauschen zu können und dem Seitenbesucher angepasst an sein Google-Konto individuelle Inhalte bereitstellen zu können.</p>
<p>Zur Bearbeitung selbst erhebt der Dienst bzw. wir folgende Daten:
Hintergrunddaten, die im Google-Benutzerkonto oder bei anderen Google-Diensten über den Seitenbesucher gespeichert sind, Hintergrunddaten zur Bereitstellung von Google-Diensten wie z.B. Streamingdaten oder Werbedaten, Daten über den Umgang des Seitennutzers mit der Google-Suche, Angaben zum verwendeten Endgerät, zur IP-Adresse und zum Browser des Users und weitere Daten von Google-Diensten zur Bereitstellung der Google-Dienste bezogen auf unsere Webseite.</p>
<p>Sofern der Dienst auf unserer Website aktiv geschaltet ist, stellt unsere Website eine Verbindung zu den Servern des Unternehmens Google Ireland Limited her und überträgt die benötigten Daten. Im Rahmen der Auftragsverarbeitung kann es auch zu einer Übermittlung von personenbezogenen Daten an die Server des Unternehmens Google LLC, 1600 Amphitheatre Parkway, 94043 Mountain View, Vereinigte Staaten kommen. &#10;&#10;Beim Einsatz des Google-Dienstes auf unserer Website werden von Google gegebenenfalls Informationen von weiteren Google-Diensten übermittelt und verarbeitet, um Hintergrunddienste für die Anzeige und Datenverarbeitung der von Google bereitgestellten Dienste zu erbringen. Hierzu erfolgt gegebenenfalls auch eine Datenübertragung an die Google-Dienste Google Apis, Doubleclick, Google Cloud, und Google Ads und Google Fonts gemäß der Google-Datenschutzerklärung.&#10;&#10;&#10;Die Zertifizierung des Anbieters im Rahmen des EU-US Data Privacy Frameworks können Sie unter <a href="https://www.dataprivacyframework.gov/list" rel="noopener noreferrer" target="_blank">https://www.dataprivacyframework.gov/list</a> abrufen.</p>
<p>Die Bearbeitung Ihrer Daten können Sie jederzeit widerrufen.&#10;Nähere Informationen zum Widerruf Ihrer Einwilligung finden Sie entweder bei der Einwilligung selbst oder am Ende dieser Datenschutzerklärung.</p>
<p>Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung des Anbieters unter <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">https://policies.google.com/privacy</a>.</p>
<p>Der Anbieter bietet unter <a href="https://support.google.com/My-Ad-Center-Help/answer/12155451?hl&#61;de" rel="noopener noreferrer" target="_blank">https://support.google.com/My-Ad-Center-Help/answer/12155451?hl&#61;de</a> zusätzlich eine Opt-Out Möglichkeit an.</p></li>
<li><h3 id="jsectionanametracker417agoogleads"><a name="tracker-417"></a> Google Ads</h3>
<p>Wir verwenden auf unserer Seite den Dienst Google Ads des Unternehmens Google Ireland Limited, Gordon House, Barrow Street, 4 Dublin, Irland, E-Mail: <a href="mailto:support-deutschland@google.com" rel="noopener noreferrer" target="_blank">support-deutschland@google.com</a>, Website: <a href="https://www.google.com/" rel="noopener noreferrer" target="_blank">https://www.google.com/</a>.
<span>Die Bearbeitung findet gemäss Einschätzung von Schweizer Behörden in sicheren Drittländern statt.
Die Staatenliste der Schweiz sowie weiterführende Informationen finden Sie unter folgendem Link: <a href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html" rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html</a>.</span></p>
<p>Rechtsgrundlage für die Übermittlung der personenbezogenen Daten stellt Ihre Einwilligung gem. Art. 6 Abs. 6 DSG bzw. Art. 31 Abs. 1 DSG dar, die Sie auf unserer Internetseite getätigt haben.</p>
<p>Google Ads ist ein Werbesystem, mit dem wir im Internet auf externen Webseiten Werbeanzeigen schalten können, um unsere Kunden über unsere Leistungen zu informieren. Google Ads zeigt nach von uns eingestellten Parametern auf externen Webseiten individuell auf unseren Kundenkreis zugeschnittene Werbung an, die zu unserer Webseite führt. Sofern der Seitenbesucher auf die Google Ads-Werbung klickt, gelangt er auf unsere Webseite. Um die Google Ads-Werbeanzeigen hinsichtlich ihres Erfolges und der Vergütung messen zu können, führt Google Ads beim Aufruf unserer Webseite eine Messung über den Erfolg der Werbemaßnahme durch. unsere Webseite verarbeitet die von Google Ads bereitgestellten Daten, um unsere Werbemaßnahmen analysieren und verbessern zu können, sowie um eine ggf. anfallende Vergütung zu berechnen.&#10;&#10;Eventuell werden ihre Daten bei entsprechender Einwilligung auch zum Zwecke des Remarketing eingesetzt.</p>
<p>Zur Bearbeitung selbst erhebt der Dienst bzw. wir folgende Daten:
Daten zu Werbeinteressen der Seitenbesucher, Interaktionen von Seitenbesuchern mit Werbung in Bezug auf unsere Webseite, Daten über den Aufruf unserer Webseite durch Seitenbesucher, die zuvor auf Google Ads-Werbung geklickt haben und zu unserer Webseite gelangt sind, Daten zum verwendeten Endgerät, zur IP-Adresse und zum Browser des Users und weitere Daten von Google-Diensten zur Bereitstellung und Verfeinerung der Google-Werbung bezogen auf unsere Webseite.</p>
<p>&#10;Sofern der Dienst auf unserer Website aktiv geschaltet ist, stellt unsere Website eine Verbindung zu den Servern des Unternehmens Google Ireland Limited her und überträgt die benötigten Daten. Im Rahmen der Auftragsverarbeitung kann es auch zu einer Übermittlung von personenbezogenen Daten an die Server des Unternehmens Google LLC, 1600 Amphitheatre Parkway, 94043 Mountain View, Vereinigte Staaten kommen. &#10;&#10;Beim Einsatz von Google Ads auf unserer Website werden von Google gegebenenfalls Informationen von weiteren Google-Diensten übermittelt und verarbeitet, um Hintergrunddienste für die Verbesserung und Individualisierung von Google-Werbung bereitzustellen. Hierzu erfolgt gegebenenfalls auch eine Datenverarbeitung durch weitere Google-Dienste wie Google Apis, Google Cloud, Google Ads, Google Analytics, Google Tag Manager, Google Marketing Plattform und Google Fonts gemäß der Google-Datenschutzerklärung in eigener datenschutzrechtlicher Verantwortung von Google.&#10;&#10;Die Zertifizierung des Anbieters im Rahmen des EU-US Data Privacy Frameworks können Sie unter <a href="https://www.dataprivacyframework.gov/list" rel="noopener noreferrer" target="_blank">https://www.dataprivacyframework.gov/list</a> abrufen.&#10;&#10;Weitere Informationen zum Verantwortungsvollen Umgang mit Geschäftsdaten finden Sie unter <a href="https://business.safety.google/privacy/" rel="noopener noreferrer" target="_blank">https://business.safety.google/privacy/</a>.  </p>
<p>Die Bearbeitung Ihrer Daten können Sie jederzeit widerrufen.&#10;Nähere Informationen zum Widerruf Ihrer Einwilligung finden Sie entweder bei der Einwilligung selbst oder am Ende dieser Datenschutzerklärung.</p>
<p>Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung des Anbieters unter <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">https://policies.google.com/privacy</a>.</p>
<p>Der Anbieter bietet unter <a href="https://support.google.com/My-Ad-Center-Help/answer/12155451?hl&#61;de" rel="noopener noreferrer" target="_blank">https://support.google.com/My-Ad-Center-Help/answer/12155451?hl&#61;de</a> zusätzlich eine Opt-Out Möglichkeit an.</p></li>
<li><h3 id="jsectionanametracker418agoogleanalytics"><a name="tracker-418"></a> Google-Analytics</h3>
<ul>
<li><h4 id="jsectionumfangderbearbeitungpersonenbezogenerdaten-1">Umfang der Bearbeitung personenbezogener Daten</h4>
<p>Auf unserer Seite verwenden wir den Webtracking-Service des Unternehmens Google Ireland Ltd., Gordon House, Barrow Street, 4 Dublin, Irland, E-Mail: <a href="mailto:support-deutschland@google.com" rel="noopener noreferrer" target="_blank">support-deutschland@google.com</a>, Website: <a href="https://www.google.com/" rel="noopener noreferrer" target="_blank">https://www.google.com/</a> (nachfolgend: Google-Analytics).
Google-Analytics nutzt im Rahmen des Webtrackings Cookies, die auf Ihrem Computer gespeichert werden und die eine Analyse der Benutzung unserer Website und Ihres Surfverhaltens ermöglichen (sogenanntes Tracken).
Wir führen diese Analyse auf Basis des Tracking-Services von Google-Analytics durch, um unser Internetangebot ständig zu optimieren und besser verfügbar zu machen.
Im Rahmen der Benutzung unserer Webseite werden dabei Daten, wie insbesondere Ihre IP-Adresse und Ihre Nutzeraktivitäten an Server des Unternehmens Google Ireland Limited übertragen.
Wir führen diese Analyse auf Basis des Tracking-Services von Google durch, um unser Internetangebot ständig zu optimieren und besser verfügbar zu machen.
Ebenso benötigen wir das Webtracking aus Sicherheitsgründen.
Durch das Webtracking können wir nachverfolgen, ob Dritte unsere Website angreifen.
Durch die Informationen des Webtrackers können wir wirksame Gegenmassnahmen ergreifen und die durch uns bearbeiteten personenbezogenen Daten vor diesen Cyberangriffen schützen.
Durch die Aktivierung der IP-Anonymisierung innerhalb des Google-Analytics Tracking-Codes dieser Internetseite, wird Ihre IP-Adresse von Google-Analytics vor der Übertragung anonymisiert.
Diese Website nutzt einen Google-Analytics-Tracking-Code, der um den Operator gat._anonymizeIp(); erweitert wurde, um nur eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu ermöglichen.</p></li>
<li><h4 id="jsectionrechtsgrundlagefrdiebearbeitungpersonenbezogenerdaten-6">Rechtsgrundlage für die Bearbeitung personenbezogener Daten</h4>
<p>Rechtsgrundlage für die Datenbearbeitung ist gemäss Art. 13 Abs. 1 DSG Ihre Einwilligung in unserem Hinweisbanner bezüglich der Nutzung von Cookies und Webtracking (Einwilligung durch eindeutige bestätigende Handlung bzw. Verhaltensweise).</p></li>
<li><h4 id="jsectionzweckderdatenbearbeitung-6">Zweck der Datenbearbeitung</h4>
<p>In unserem Auftrag wird Google diese Informationen nutzen, um Ihren Besuch auf dieser Internetseite auszuwerten, Reports über die Websiteaktivitäten zusammenzustellen und um weitere, mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen, gegenüber uns zu erbringen.
Ebenso benötigen wir das Webtracking aus Sicherheitsgründen.
Durch das Webtracking können wir nachverfolgen, ob Dritte unsere Website angreifen.
Durch die Informationen des Webtrackers können wir wirksame Gegenmassnahmen ergreifen und die durch uns bearbeiteten personenbezogenen Daten vor diesen Cyberangriffen schützen.</p></li>
<li><h4 id="jsectiondauerderspeicherung-7">Dauer der Speicherung</h4>
<p>Google wird die für die Bereitstellung des Webtracking relevanten Daten solange speichern, wie es notwendig ist, um den gebuchten Webservice zu erfüllen.
Die Datenerhebung und Speicherung erfolgt anonymisiert.
Soweit doch Personenbezug bestehen sollte, werden die Daten unverzüglich gelöscht, soweit diese keinen gesetzlichen Aufbewahrungspflichten unterfallen.
In jedem Fall erfolgt die Löschung nach Ablauf der Aufbewahrungspflicht.</p></li>
<li><h4 id="jsectionwiderspruchsundlschungsmglichkeiten">Widerspruchs- und Löschungsmöglichkeiten</h4>
<p>Sie können die Erfassung und Weiterleitung der personenbezogenen Daten an Google (insb. Ihrer IP-Adresse) sowie die Bearbeitung dieser Daten durch Google verhindern, indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren oder die „Do Not Track“ Einstellung Ihres Browsers aktivieren.
Sie können darüber hinaus die Erfassung der durch den Google-Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Bearbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link (<a href="http://tools.google.com/dlpage/gaoptout?hl=de" rel="noopener noreferrer" target="_blank">http://tools.google.com/dlpage/gaoptout?hl=de</a>) verfügbare Browser-Plug-In herunterladen und installieren.
Die Sicherheits- und Datenschutzgrundsätze von Google finden Sie unter <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">https://policies.google.com/privacy</a>.</p></li></ul></li>
<li><h3 id="jsectionanametrackerb26e773effd44cb48b20987f4549011dagoogleanalyticsgooglesignals"><a name="tracker-b26e773e-ffd4-4cb4-8b20-987f4549011d"></a> Google Analytics (Google Signals)</h3>
<p>Wir verwenden auf unserer Seite den Dienst Google Analytics (Google Signals) des Unternehmens Google Ireland Ltd., Gordon House, Barrow Street, 4 Dublin, Irland, E-Mail: <a href="mailto:support-deutschland@google.com" rel="noopener noreferrer" target="_blank">support-deutschland@google.com</a>, Website: <a href="https://www.google.com/" rel="noopener noreferrer" target="_blank">https://www.google.com/</a>.
<span>Die Bearbeitung findet gemäss Einschätzung von Schweizer Behörden in sicheren Drittländern statt.
Die Staatenliste der Schweiz sowie weiterführende Informationen finden Sie unter folgendem Link: <a href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html" rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html</a>.</span></p>
<p>Rechtsgrundlage für die Übermittlung der personenbezogenen Daten stellt Ihre Einwilligung gem. Art. 6 Abs. 6 DSG bzw. Art. 31 Abs. 1 DSG dar, die Sie auf unserer Internetseite getätigt haben.</p>
<p>Google Analytics ist ein Webtracker, der das Verhalten von Seitenbesuchern und deren Interaktionen mit unserer Website analysiert und uns Auswertungen und Prognosen über die Inhalte und Produkte unserer Webseite und deren Beliebtheit zur Verfügung stellt (sogenanntes Tracking). &#10;&#10;Wir haben Google Analytics eingebunden, damit der Dienst eine Analyse des Surfverhaltens der Seitennutzer zusammenstellen kann. Hierzu sammelt Google die Seiteninteraktionen von Seitenbesuchern mit unserer Website und ggf. bestehende Informationen die sich aus dem Auslesen von Cookies oder weiterer Speichertechnologien ergeben und bereitet es für uns statistisch auf. &#10;&#10;Google Analytics setzt Datenverarbeitungstechnologien ein, die eine Verfolgbarkeit von einzelnen Seitenbesuchern und deren Interaktion geräteübergreifend und sitzungsunabhängig mit anderen Google-Diensten wie zu Beispiel dem Werbenetzwerk Google Ads ermöglichen. Daten aus weiteren Google-Diensten werden ebenfalls dazu eingesetzt um mittels maschineller Lerntechnologien, modellierter Statistiken und Prognosefunktionen Datenlücken zu schließen und umfangreiche Statistiken zu den Inhalten unserer Webseite zu erstellen.&#10;&#10;Sofern Google Analytics auf unserer Website aktiv geschaltet ist, werden die von Google Analytics ermittelten Daten an Server des Unternehmens Google Ireland Limited übertragen. Im Rahmen der Auftragsverarbeitung kann es auch zu einer Übermittlung von personenbezogenen Daten an die Server des Mutterkonzerns Google LLC, 1600 Amphitheatre Parkway, 94043 Mountain View, Vereinigte Staaten kommen. Die Übermittlung personenbezogener Daten erfolgt auch in die USA.&#10;&#10;Wir führen die Analyse durch Google Analytics durch, um unser Internetangebot ständig zu optimieren und besser verfügbar zu machen. Es handelt sich hierbei um eine sog. Reichweitenmessung.</p>
<p>Zur Bearbeitung selbst erhebt der Dienst bzw. wir folgende Daten:
Daten zu den Interaktionen der Seitenbesucher mit den Inhalten der Website, Daten zum Umgang der auf unserer Website dargestellten Leistungen, Daten von externen Google-Diensten, sofern sie mit unserer Website interagieren wie z.B. Werbedaten oder Daten zum Verhalten in Bezug auf Werbung, Daten zur groben geographischen Herkunft, dem verwendeten Browser, Betriebssystem sowie weiteren Informationen zum verwendeten Endgerät gegebenenfalls geräteübergreifend und sitzungsunabhängig.</p>
<p>Google Analytics wird die für die Bereitstellung des Webtracking relevanten Daten solange speichern, wie es notwendig ist, um den gebuchten Webservice zu erfüllen. Die Datenerhebung und Speicherung erfolgt anonymisiert. Soweit durch individuelle Interaktionen von Seitenbesuchern die Möglichkeit besteht, zu konkreten Handlungen nachträglich einen Personenbezug herzustellen, werden wir die erhobenen Daten dann löschen, wenn der Zweck erreicht ist. Die Daten werden spätestens dann gelöscht, wenn diese keinen gesetzlichen Aufbewahrungspflichten unterfallen. Im Regelfall werden wir diese Daten spätestens nach 12 Monaten löschen.&#10;&#10;Die Zertifizierung des Anbieters im Rahmen des EU-US Data Privacy Frameworks können Sie unter <a href="https://www.dataprivacyframework.gov/list" rel="noopener noreferrer" target="_blank">https://www.dataprivacyframework.gov/list</a> abrufen.</p>
<p>Die Bearbeitung Ihrer Daten können Sie jederzeit widerrufen.&#10;Nähere Informationen zum Widerruf Ihrer Einwilligung finden Sie entweder bei der Einwilligung selbst oder am Ende dieser Datenschutzerklärung.</p>
<p>Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung des Anbieters unter <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">https://policies.google.com/privacy</a>.</p>
<p>Der Anbieter bietet unter <a href="https://tools.google.com/dlpage/gaoptout?hl&#61;de" rel="noopener noreferrer" target="_blank">https://tools.google.com/dlpage/gaoptout?hl&#61;de</a> zusätzlich eine Opt-Out Möglichkeit an.</p></li>
<li><h3 id="jsectionanametracker107agooglemaps"><a name="tracker-107"></a> Google Maps</h3>
<p>Wir verwenden auf unserer Seite den Dienst Google Maps des Unternehmens Google Ireland Limited, Gordon House, Barrow Street, 4 Dublin, Irland, E-Mail: <a href="mailto:support-deutschland@google.com" rel="noopener noreferrer" target="_blank">support-deutschland@google.com</a>, Website: <a href="https://www.google.com/" rel="noopener noreferrer" target="_blank">https://www.google.com/</a>.
<span>Die Bearbeitung findet gemäss Einschätzung von Schweizer Behörden in sicheren Drittländern statt.
Die Staatenliste der Schweiz sowie weiterführende Informationen finden Sie unter folgendem Link: <a href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html" rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html</a>.</span></p>
<p>Rechtsgrundlage für die Übermittlung der personenbezogenen Daten stellt Ihre Einwilligung gem. Art. 6 Abs. 6 DSG bzw. Art. 31 Abs. 1 DSG dar, die Sie auf unserer Internetseite getätigt haben.</p>
<p>In unserem Auftrag wird Google die mittels Google Maps gewonnenen Informationen nutzen, um Ihnen die Karte anzuzeigen. Mittels Google Maps finden Sie uns schneller und genauer als mit einer bloßen nicht interaktiven Anfahrtsskizze. Ebenfalls werden die entsprechenden Daten verwendet, um die Seitenbesucher, die unsere Google Maps-Karte anzeigen, ggf. unter Zuhilfenahme der Google-Werbe-ID zuzuordnen und mit physischen Besuchen und Aufrufen von weiteren bei Google gelisteten Kontaktinformationen zu verknüpfen. Auf diese Weise kann Google eine Einschätzung der zu erwartenden Besucherströme treffen.</p>
<p>Zur Bearbeitung selbst erhebt der Dienst bzw. wir folgende Daten:
Daten die zur Visualisierung und Darstellung von Standortdaten in Form einer Karte benötigt werden wie insbesondere IP-Adresse, Informationen von Google Hintergrunddiensten wie Google Apis, Suchbegriffe, IP-Adresse, Koordinaten, bei Nutzung des Routenplaners Anfangsort und Zielort, Standortdaten, Google-Werbe-ID, Android-Werbe-ID.</p>
<p>Wir haben mit Google im Hinblick auf Google Maps einen Vertrag zur gemeinsamen Verarbeitung abgeschlossen. Den Inhalt finden Sie unter <a href="https://privacy.google.com/intl/de/businesses/mapscontrollerterms/" rel="noopener noreferrer" target="_blank">https://privacy.google.com/intl/de/businesses/mapscontrollerterms/</a>. &#10;&#10;Sofern der Dienst auf unserer Website aktiv geschaltet ist, stellt unsere Website eine Verbindung zu den Servern des Unternehmens Google Ireland Limited her und überträgt die benötigten Daten. Im Rahmen der Auftragsverarbeitung kann es auch zu einer Übermittlung von personenbezogenen Daten an die Server des Unternehmens Google LLC, 1600 Amphitheatre Parkway, 94043 Mountain View, Vereinigte Staaten kommen. &#10;&#10;Beim Einsatz des Google-Dienstes auf unserer Website werden von Google gegebenenfalls Informationen von weiteren Google-Diensten übermittelt und verarbeitet, um Hintergrunddienste für die Anzeige und Datenverarbeitung der von Google bereitgestellten Dienste zu erbringen. Hierzu erfolgt gegebenenfalls auch eine Datenverarbeitung durch die Google-Dienste Google Apis, Google Cloud und Google Fonts gemäß der Google-Datenschutzerklärung in datenschutzrechtlicher Verantwortung von Google.&#10;&#10;&#10;Die Zertifizierung des Anbieters im Rahmen des EU-US Data Privacy Frameworks können Sie unter <a href="https://www.dataprivacyframework.gov/list" rel="noopener noreferrer" target="_blank">https://www.dataprivacyframework.gov/list</a> abrufen.</p>
<p>Die Bearbeitung Ihrer Daten können Sie jederzeit widerrufen.&#10;Nähere Informationen zum Widerruf Ihrer Einwilligung finden Sie entweder bei der Einwilligung selbst oder am Ende dieser Datenschutzerklärung.</p>
<p>Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung des Anbieters unter <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">https://policies.google.com/privacy</a>.</p>
<p>Der Anbieter bietet unter <a href="https://support.google.com/My-Ad-Center-Help/answer/12155451?hl&#61;de" rel="noopener noreferrer" target="_blank">https://support.google.com/My-Ad-Center-Help/answer/12155451?hl&#61;de</a> zusätzlich eine Opt-Out Möglichkeit an.</p></li>
<li><h3 id="jsectionanametracker112agoogletagmanager"><a name="tracker-112"></a> Google Tag Manager</h3>
<ul>
<li><h4 id="jsectionwelchepersonenbezogenendatenwerdenerhobenundinwelchemumfangwerdendiesebearbeitet-4">Welche personenbezogenen Daten werden erhoben und in welchem Umfang werden diese bearbeitet?</h4>
<p>Auf unserer Seite verwenden wir den Service Google Tag Manager des Unternehmens Google Ireland Ltd., Gordon House, Barrow Street, 4 Dublin, Irland, E-Mail: <a href="mailto:support-deutschland@google.com" rel="noopener noreferrer" target="_blank">support-deutschland@google.com</a>, Website: <a href="https://www.google.com/" rel="noopener noreferrer" target="_blank">https://www.google.com/</a> (nachfolgend: Google Tag Manager).
Google Tag Manager bietet eine technische Plattform um andere Webservices und Webtracking-Programme mittels sogenannter „Tags“ auszuführen und gebündelt steuern zu können.
Google Tag Manager speichert in diesem Zusammenhang Cookies auf Ihrem Computer und analysiert, soweit Webtracking Tools mittels Google-Tag-Manager ausgeführt werden, Ihr Surfverhalten (sogenanntes „tracken“).
Diese von einzelnen in Google-Tag-Manager eingebundenen Tags gesendeten Daten werden von Google Tag Manager unter einer einheitlichen Benutzeroberfläche zusammengeführt,  gespeichert und bearbeitet. Alle eingebundenen „Tags“ werden in dieser Datenschutzerklärung nochmals gesondert aufgeführt. Nähere Informationen zum Datenschutz der in Google Tag Manager eingebundenen Tools finden Sie in dem jeweiligen Abschnitt dieser Datenschutzerklärung.
Im Rahmen der Benutzung unserer Webseite bei aktivierter Einbindung von Tags von Google-Tag-Manager werden dabei Daten, wie insbesondere Ihre IP-Adresse und Ihre Nutzeraktivitäten an Server des Unternehmens Google Ireland Limited übertragen.
Bzgl. der mittels Google Tag Manager eingebundenen Webservices gelten die Regelungen in dem jeweiligen Abschnitt dieser Datenschutzerklärung.
Die in Google Tag Manager verwendeten Tracking-Tools stellen durch eine IP-Anonymisierung des Quellcodes sicher, dass die IP-Adresse von Google Tag Manager vor der Übertragung anonymisiert wird. Hierbei wird Google Tag Manager nur die anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) ermöglicht.</p></li>
<li><h4 id="jsectionrechtsgrundlagefrdiebearbeitungpersonenbezogenerdaten-7">Rechtsgrundlage für die Bearbeitung personenbezogener Daten</h4>
<p>Rechtsgrundlage für die Datenbearbeitung ist gemäss Art. 13 Abs. 1 DSG Ihre Einwilligung in unserem Hinweisbanner bezüglich der Nutzung von Cookies und Webtracking (Einwilligung durch eindeutige bestätigende Handlung bzw. Verhaltensweise).</p></li>
<li><h4 id="jsectionzweckderdatenbearbeitung-7">Zweck der Datenbearbeitung</h4>
<p>Im unserem Auftrag wird Google die mittels Google-Tag Manager gewonnenen Informationen nutzen, um Ihren Besuch auf dieser Internetseite auszuwerten, Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber uns zu erbringen.</p></li>
<li><h4 id="jsectiondauerderspeicherung-8">Dauer der Speicherung</h4>
<p>Google wird die für die Funktion von Google-Tag-Manager relevanten Daten solange speichern, wie es notwendig ist, um den gebuchten Webservice zu erfüllen.
Die Datenerhebung und Speicherung erfolgt anonymisiert. Soweit doch Personenbezug bestehen sollte, werden die Daten unverzüglich gelöscht, soweit diese keinen gesetzlichen Aufbewahrungspflichten unterfallen.
In jedem Fall erfolgt die Löschung nach Ablauf der Aufbewahrungspflicht.</p></li>
<li><h4 id="jsectionwiderspruchsundlschungsmglichkeit">Widerspruchs- und Löschungsmöglichkeit</h4>
<p>Sie können die Erfassung und Weiterleitung der personenbezogenen Daten an Google (insb. Ihrer IP-Adresse) sowie die Bearbeitung dieser Daten durch Google verhindern, indem Sie die Ausführung von Script-Code in Ihrem Browser deaktivieren, einen Script-Blocker in Ihrem Browser installieren oder die „Do Not Track" Einstellung Ihres Browsers aktivieren.
Sie können darüber hinaus die Erfassung der durch den Google-Cookie erzeugten und auf Ihre Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die Bearbeitung dieser Daten durch Google verhindern, indem Sie das unter dem folgenden Link <a href="http://tools.google.com/dlpage/gaoptout?hl=de" rel="noopener noreferrer" target="_blank">http://tools.google.com/dlpage/gaoptout?hl=de</a> verfügbare Browser-Plug-In herunterladen und installieren.
Die Sicherheits- und Datenschutzgrundsätze von Google finden Sie unter <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">https://policies.google.com/privacy</a>.</p></li></ul></li>
<li><h3 id="jsectionanametracker116agstatic"><a name="tracker-116"></a> Gstatic</h3>
<p>Wir verwenden auf unserer Seite den Dienst Gstatic des Unternehmens Google Ireland Limited, Gordon House, Barrow Street, 4 Dublin, Irland, E-Mail: <a href="mailto:support-deutschland@google.com" rel="noopener noreferrer" target="_blank">support-deutschland@google.com</a>, Website: <a href="https://www.google.com/" rel="noopener noreferrer" target="_blank">https://www.google.com/</a>.
<span>Die Bearbeitung findet gemäss Einschätzung von Schweizer Behörden in sicheren Drittländern statt.
Die Staatenliste der Schweiz sowie weiterführende Informationen finden Sie unter folgendem Link: <a href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html" rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html</a>.</span></p>
<p>Rechtsgrundlage für die Übermittlung der personenbezogenen Daten stellt Ihre Einwilligung gem. Art. 6 Abs. 6 DSG bzw. Art. 31 Abs. 1 DSG dar, die Sie auf unserer Internetseite getätigt haben.</p>
<p>Gstatic ist ein von Google verwendeter Hintergrunddienst zum Abrufen statischer Inhalte, um die Bandbreitennutzung zu reduzieren und benötigte Katalogdateien bereits vorab zu laden. Der Dienst lädt insbesondere Hintergrunddaten zu Google Fonts und Google Maps.</p>
<p>Im Rahmen der Auftragsverarbeitung kann es auch zu einer Übermittlung von personenbezogenen Daten an die Server des Unternehmens Google LLC, 1600 Amphitheatre Parkway, 94043 Mountain View, Vereinigte Staaten kommen.&#10;&#10;Die Zertifizierung des Anbieters im Rahmen des EU-US Data Privacy Frameworks können Sie unter <a href="https://www.dataprivacyframework.gov/list" rel="noopener noreferrer" target="_blank">https://www.dataprivacyframework.gov/list</a> abrufen.</p>
<p>Die Bearbeitung Ihrer Daten können Sie jederzeit widerrufen.&#10;Nähere Informationen zum Widerruf Ihrer Einwilligung finden Sie entweder bei der Einwilligung selbst oder am Ende dieser Datenschutzerklärung.</p>
<p>Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung des Anbieters unter <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">https://policies.google.com/privacy</a>.</p>
<p>Der Anbieter bietet unter <a href="https://support.google.com/My-Ad-Center-Help/answer/12155451?hl&#61;de" rel="noopener noreferrer" target="_blank">https://support.google.com/My-Ad-Center-Help/answer/12155451?hl&#61;de</a> zusätzlich eine Opt-Out Möglichkeit an.</p></li>
<li><h3 id="jsectionanametracker421ahotjar"><a name="tracker-421"></a> Hotjar</h3>
<p>Wir verwenden auf unserer Seite den Dienst Hotjar des Unternehmens Hotjar Ltd., Dragonara Business Centre,&nbsp;5th Floor, Dragonara Road, 3141 Paceville St Julian&#39;s STJ, Malta, Website: <a href="https://www.hotjar.com/" rel="noopener noreferrer" target="_blank">https://www.hotjar.com/</a>.
<span>Die Bearbeitung findet gemäss Einschätzung von Schweizer Behörden in sicheren Drittländern statt.
Die Staatenliste der Schweiz sowie weiterführende Informationen finden Sie unter folgendem Link: <a href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html" rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html</a>.</span></p>
<p>Rechtsgrundlage für die Übermittlung der personenbezogenen Daten stellt Ihre Einwilligung gem. Art. 6 Abs. 6 DSG bzw. Art. 31 Abs. 1 DSG dar, die Sie auf unserer Internetseite getätigt haben.</p>
<p>Über Hotjar führen wir eine Verhaltensanalyse über die Nutzung unserer Websites durch und werten Feedback über Tools wie Heatmaps, Sitzungsaufzeichnungen und Umfragen aus.</p>
<p>Die Bearbeitung Ihrer Daten können Sie jederzeit widerrufen.&#10;Nähere Informationen zum Widerruf Ihrer Einwilligung finden Sie entweder bei der Einwilligung selbst oder am Ende dieser Datenschutzerklärung.</p>
<p>Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung des Anbieters unter <a href="https://www.hotjar.com/legal/policies/privacy/" rel="noopener noreferrer" target="_blank">https://www.hotjar.com/legal/policies/privacy/</a>.</p>
<p>Der Anbieter bietet unter <a href="https://help.hotjar.com/hc/en-us/articles/360002735873-How-to-Stop-Hotjar-From-Collecting-your-Data" rel="noopener noreferrer" target="_blank">https://help.hotjar.com/hc/en-us/articles/360002735873-How-to-Stop-Hotjar-From-Collecting-your-Data</a> zusätzlich eine Opt-Out Möglichkeit an.</p></li>
</ul>
<h2 id="jsectioneinbindungexternerwebservicesundbearbeitungvondatenausserhalbdereu">Einbindung externer Webservices und Bearbeitung von Daten ausserhalb der EU</h2>
<p>Auf unserer Internetseite verwenden wir aktive Inhalte von externen Anbietern, sog. Webservices.
Durch Aufruf unserer Internetseite erhalten diese externen Anbieter ggf. personenbezogene Informationen über Ihren Besuch auf unserer Internetseite.
Hierbei ist ggf. eine Bearbeitung von Daten ausserhalb der Schweiz möglich.
Sie können dies verhindern, indem Sie ein entsprechendes Browser-Plugin installieren oder das Ausführen von Scripten in Ihrem Browser deaktivieren.
Hierdurch kann es zu Funktionseinschränkungen auf Internetseiten kommen, die Sie besuchen.</p>
<p>Wir verwenden folgende externe Webservices:</p>
<ul>
<li><h3 id="jsectionanametracker19aamazoncloudfrontcdn"><a name="tracker-19"></a> Amazon CloudFront  (CDN)</h3>
<p>Wir verwenden auf unserer Seite den Dienst Amazon CloudFront  (CDN) des Unternehmens Amazon Web Services EMEA SARL, 38 avenue John F. Kennedy, L-1855  Luxemburg, Luxemburg, E-Mail: <a href="mailto:privacyshield@amazon.com" rel="noopener noreferrer" target="_blank">privacyshield@amazon.com</a>, Website: <a href="https://aws.amazon.com/de/cloudfront/" rel="noopener noreferrer" target="_blank">https://aws.amazon.com/de/cloudfront/</a>.
<span>Die Bearbeitung findet gemäss Einschätzung von Schweizer Behörden in sicheren Drittländern statt.
Die Staatenliste der Schweiz sowie weiterführende Informationen finden Sie unter folgendem Link: <a href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html" rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html</a>.</span></p>
<p>Rechtsgrundlage für die Übermittlung der personenbezogenen Daten ist unser berechtigtes Interesse an der Bearbeitung welches in der Erreichung des nachfolgend geschilderten Zwecks liegt.</p>
<p>Bei Amazon CloudFront CDN handelt es sich um  ein Content Delivery Network, das unsere Inhalte über verschiedene Server spiegelt, um eine optimale Erreichbarkeit weltweit sicherzustellen.</p>
<p>Die Zertifizierung des Anbieters im Rahmen des EU-US Data Privacy Frameworks können Sie unter <a href="https://www.dataprivacyframework.gov/list" rel="noopener noreferrer" target="_blank">https://www.dataprivacyframework.gov/list</a> abrufen.</p>
<p>Welche Rechte Ihnen im Hinblick auf die Bearbeitung zustehen, finden Sie am Ende dieser Datenschutzerklärung.</p>
<p>Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung des Anbieters unter <a href="https://aws.amazon.com/de/privacy/?nc1&#61;f_pr" rel="noopener noreferrer" target="_blank">https://aws.amazon.com/de/privacy/?nc1&#61;f_pr</a>.</p></li>


<li><h3 id="jsectionanametracker1216afontawesome"><a name="tracker-1216"></a> Font Awesome</h3>
<p>Wir verwenden auf unserer Seite den Dienst Font Awesome des Unternehmens Fonticons Inc., 6 Porter Road, Apartment 3R, MA 02140 Cambridge, Vereinigte Staaten, E-Mail: <a href="mailto:hello@fontawesome.com" rel="noopener noreferrer" target="_blank">hello@fontawesome.com</a>, Website: <a href="https://fontawesome.com/" rel="noopener noreferrer" target="_blank">https://fontawesome.com/</a>.
<span>Ihre Personendaten werden in sog. unsichere Drittstaaten übermittelt, welche keinen hinreichenden Datenschutz durch ihre Gesetzgebung gewährleisten.</span>
Ihre Daten werden nur weitergegeben, sofern ein geeigneter Datenschutz gewährleistet wird. Dies kann namentlich gewährleistet werden durch:</p>
<ul>
<li>völkerrechtliche Verträge</li>
<li>Datenschutzklauseln in einem Vertrag zwischen dem Verantwortlichen oder dem Auftragsbearbeiter und seiner Vertragspartnerin oder seinem Vertragspartner, die dem EDÖB vorgängig mitgeteilt wurden</li>
<li>spezifische Garantien, die das zuständige Bundesorgan erarbeitet und dem EDÖB vorgängig mitgeteilt hat</li>
<li>Standarddatenschutzklauseln, die der EDÖB vorgängig genehmigt, ausgestellt oder anerkannt hat oder</li>
<li>verbindliche unternehmensinterne Datenschutzvorschriften, die vorgängig vom EDÖB oder von einer für den Datenschutz zuständigen Behörde eines Staates, der einen angemessenen Schutz gewährleistet, genehmigt wurden</li></ul>
<p>Sind solche Garantien nicht vorhanden, dürfen Ihre Daten nur bekanntgegeben werden, wenn Sie hierzu Ihre Einwilligung abgegeben haben, die Bekanntgabe in unmittelbarem Zusammenhang mit dem Abschluss oder der Abwicklung eines Vertrages steht, oder die Bekanntgabe im Rahmen der Durchsetzung von Ansprüchen vor Gerichten und Behörden oder zur Wahrung öffentlicher Interessen erforderlich ist.</p>
<p>Rechtsgrundlage für die Übermittlung der personenbezogenen Daten stellt Ihre Einwilligung gem. Art. 6 Abs. 6 DSG bzw. Art. 31 Abs. 1 DSG dar, die Sie auf unserer Internetseite getätigt haben.</p>
<p>Über den Dienst Font Awesome werden Schriftarten auf unserer Seite nachgeladen, um Ihnen die Seite in einer optisch besseren Version anzeigen zu können.</p>
<p>Die Bearbeitung Ihrer Daten können Sie jederzeit widerrufen.&#10;Nähere Informationen zum Widerruf Ihrer Einwilligung finden Sie entweder bei der Einwilligung selbst oder am Ende dieser Datenschutzerklärung.</p>
<p>Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung des Anbieters unter <a href="https://fontawesome.com/privacy" rel="noopener noreferrer" target="_blank">https://fontawesome.com/privacy</a>.</p></li>


<li><h3 id="jsectionanametracker111agooglecloudapis"><a name="tracker-111"></a> Google Cloud APIs</h3>
<p>Wir verwenden auf unserer Seite den Dienst Google Cloud APIs des Unternehmens Google Ireland Limited, Gordon House, Barrow Street, 4 Dublin, Irland, E-Mail: <a href="mailto:support-deutschland@google.com" rel="noopener noreferrer" target="_blank">support-deutschland@google.com</a>, Website: <a href="https://www.google.com/" rel="noopener noreferrer" target="_blank">https://www.google.com/</a>.
<span>Die Bearbeitung findet gemäss Einschätzung von Schweizer Behörden in sicheren Drittländern statt.
Die Staatenliste der Schweiz sowie weiterführende Informationen finden Sie unter folgendem Link: <a href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html" rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html</a>.</span></p>
<p>Rechtsgrundlage für die Übermittlung der personenbezogenen Daten stellt Ihre Einwilligung gem. Art. 6 Abs. 6 DSG bzw. Art. 31 Abs. 1 DSG dar, die Sie auf unserer Internetseite getätigt haben.</p>
<p>Wir verwenden Google APIs um auf der Internetseite weitere Dienste von Google nachladen zu können. Bei Google Apis handelt es sich um eine Sammlung von Schnittstellen zur Kommunikation zwischen den verschiedenen Google Diensten, die auf Ihrer Website zum Einsatz kommen. Der Dienst wird insbesondere zur Darstellung der Google Fonts-Schriftarten und zur Bereitstellung der Google Maps-Karte.</p>
<p>Zur Bearbeitung selbst erhebt der Dienst bzw. wir folgende Daten:
IP-Adresse</p>
<p>Sofern der Dienst auf unserer Website aktiv geschaltet ist, stellt unsere Website eine Verbindung zu den Servern des Unternehmens Google Ireland Limited her und überträgt die benötigten Daten. Im Rahmen der Auftragsverarbeitung kann es auch zu einer Übermittlung von personenbezogenen Daten an die Server des Unternehmens Google LLC, 1600 Amphitheatre Parkway, 94043 Mountain View, Vereinigte Staaten kommen. &#10;&#10;Beim Einsatz des Google-Dienstes auf unserer Website werden von Google gegebenenfalls Informationen von weiteren Google-Diensten übermittelt und verarbeitet, um Hintergrunddienste für die Anzeige und Datenverarbeitung der von Google bereitgestellten Dienste zu erbringen. Hierzu erfolgt gegebenenfalls auch eine Datenübertragung an die Google-Dienste Google Cloud, Google Maps, Google Ads und Google Fonts gemäß der Google-Datenschutzerklärung in datenschutzrechtlicher Verantwortung von Google.&#10;&#10;Die Zertifizierung des Anbieters im Rahmen des EU-US Data Privacy Frameworks können Sie unter <a href="https://www.dataprivacyframework.gov/list" rel="noopener noreferrer" target="_blank">https://www.dataprivacyframework.gov/list</a> abrufen.</p>
<p>Die Bearbeitung Ihrer Daten können Sie jederzeit widerrufen.&#10;Nähere Informationen zum Widerruf Ihrer Einwilligung finden Sie entweder bei der Einwilligung selbst oder am Ende dieser Datenschutzerklärung.</p>
<p>Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung des Anbieters unter <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">https://policies.google.com/privacy</a>.</p>
<p>Der Anbieter bietet unter <a href="https://support.google.com/My-Ad-Center-Help/answer/12155451?hl&#61;de" rel="noopener noreferrer" target="_blank">https://support.google.com/My-Ad-Center-Help/answer/12155451?hl&#61;de</a> zusätzlich eine Opt-Out Möglichkeit an.</p></li>
<li><h3 id="jsectionanametracker106agooglefonts"><a name="tracker-106"></a> Google Fonts</h3>
<p>Wir verwenden auf unserer Seite den Dienst Google Fonts des Unternehmens Google Ireland Limited, Gordon House, Barrow Street, 4 Dublin, Irland, E-Mail: <a href="mailto:support-deutschland@google.com" rel="noopener noreferrer" target="_blank">support-deutschland@google.com</a>, Website: <a href="https://www.google.com/" rel="noopener noreferrer" target="_blank">https://www.google.com/</a>.
<span>Die Bearbeitung findet gemäss Einschätzung von Schweizer Behörden in sicheren Drittländern statt.
Die Staatenliste der Schweiz sowie weiterführende Informationen finden Sie unter folgendem Link: <a href="https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html" rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/de/home/datenschutz/handel-und-wirtschaft/uebermittlung-ins-ausland.html</a>.</span></p>
<p>Rechtsgrundlage für die Übermittlung der personenbezogenen Daten stellt Ihre Einwilligung gem. Art. 6 Abs. 6 DSG bzw. Art. 31 Abs. 1 DSG dar, die Sie auf unserer Internetseite getätigt haben.</p>
<p>Wir verwenden den Dienst Google Fonts um auf unserer Seite attraktive Schriftarten einbinden zu können, um Ihnen unsere Webseite in einer optisch besseren Version anzeigen zu können. Ebenfalls kommt der Dienst auf unserer Webseite gegebenenfalls zum Einsatz wenn andere Google-Dienste auf unserer Webseite nachgeladen werden, die Google Fonts-Schriftarten zur Ausführung benötigen. Dies ist beispielsweise dann der Fall, sofern unsere Webseite Google-Dienste nutzt, die zur Ausführung Google Fonts zwingend benötigen.</p>
<p>Zur Bearbeitung selbst erhebt der Dienst bzw. wir folgende Daten:
Daten zu Schriftarten, IP-Adresse des Seitenbesuchers, Statistiken über den Gebrauch von Schriftarten und weitere Daten von Google-Diensten  bezogen auf unsere Webseite.</p>
<p>Sofern der Dienst auf unserer Website aktiv geschaltet ist, stellt unsere Website eine Verbindung zu den Servern des Unternehmens Google Ireland Limited her und überträgt die benötigten Daten. Im Rahmen der Auftragsverarbeitung kann es auch zu einer Übermittlung von personenbezogenen Daten an die Server des Unternehmens Google LLC, 1600 Amphitheatre Parkway, 94043 Mountain View, Vereinigte Staaten kommen. &#10;&#10;Beim Einsatz des Google-Dienstes auf unserer Website werden von Google gegebenenfalls Informationen von weiteren Google-Diensten übermittelt und verarbeitet, um Hintergrunddienste für die Anzeige und Datenverarbeitung der von Google bereitgestellten Dienste zu erbringen. Hierzu erfolgt gegebenenfalls auch eine Datenübertragung an die Google-Dienste Google Apis, Google Cloud und Google Ads gemäß der Google-Datenschutzerklärung.&#10;&#10;Die Zertifizierung des Anbieters im Rahmen des EU-US Data Privacy Frameworks können Sie unter <a href="https://www.dataprivacyframework.gov/list" rel="noopener noreferrer" target="_blank">https://www.dataprivacyframework.gov/list</a> abrufen.</p>
<p>Die Bearbeitung Ihrer Daten können Sie jederzeit widerrufen.&#10;Nähere Informationen zum Widerruf Ihrer Einwilligung finden Sie entweder bei der Einwilligung selbst oder am Ende dieser Datenschutzerklärung.</p>
<p>Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung des Anbieters unter <a href="https://policies.google.com/privacy" rel="noopener noreferrer" target="_blank">https://policies.google.com/privacy</a>.</p>
<p>Der Anbieter bietet unter <a href="https://support.google.com/My-Ad-Center-Help/answer/12155451?hl&#61;de" rel="noopener noreferrer" target="_blank">https://support.google.com/My-Ad-Center-Help/answer/12155451?hl&#61;de</a> zusätzlich eine Opt-Out Möglichkeit an.</p></li>
<li><h3 id="jsectionanametrackerc6fde9dc3b934a09924ae8592ded9709alegallyokrechtstextsnippetundmodule"><a name="tracker-c6fde9dc-3b93-4a09-924a-e8592ded9709"></a> Legally ok Rechtstextsnippet und Module</h3>
<p>Wir verwenden auf unserer Seite den Dienst Legally ok Rechtstextsnippet und Module des Unternehmens Legally ok GmbH,  Schochenmühlestrasse 6, 6340 Baar, Schweiz, E-Mail: <a href="mailto:hello@legally-ok.com" rel="noopener noreferrer" target="_blank">hello@legally-ok.com</a>, Website: <a href="https://www.legally-ok.com/" rel="noopener noreferrer" target="_blank">https://www.legally-ok.com/</a>.
Die Bearbeitung erfolgt ausschliesslich in der Schweiz gemäss dort geltender Datenschutzgesetzgebung.</p>
<p>Rechtsgrundlage für die Übermittlung und Bearbeitung sind Art. 31 Abs. 1 DSG.&#10;Der Einsatz des Dienstes unterstützt uns dabei unseren rechtlichen Verpflichtungen nachzukommen.</p>
<p>Mit Hilfe des Dienstes werden auf unserer Internetseite Inhalte unserer Rechtstexte nachgeladen. Über die vorgenommene Einbindung auf unserer Seite werden die jeweils aktuellen Rechtstexte nachgeladen. Über diese Einbindung können ggf. auch weitere technische Module im Hinblick auf die rechtlichen Texte oder rechtlich notwendigen Elemente nachgeladen werden.</p>
<p>Welche Rechte Ihnen im Hinblick auf die Bearbeitung zustehen, finden Sie am Ende dieser Datenschutzerklärung.</p>
<p>Weitere Informationen zum Handling der übertragenen Daten finden Sie in der Datenschutzerklärung des Anbieters unter <a href="https://www.legally-ok.com/datenschutz/" rel="noopener noreferrer" target="_blank">https://www.legally-ok.com/datenschutz/</a>.</p></li>
</ul>
<div id="jmd-section-cookies" data-markdown="1"><h2 id="jsectioninformationenzurverwendungvoncookies">Informationen zur Verwendung von Cookies</h2>
<h3 id="jsectionumfangderbearbeitungpersonenbezogenerdaten">Umfang der Bearbeitung personenbezogener Daten</h3>
<p>Auf verschiedenen Seiten integrieren und verwenden wir Cookies, um bestimmte Funktionen unserer Website zu ermöglichen und externe Webservices zu integrieren.
Bei den sogenannten "Cookies" handelt es sich um kleine Textdateien, die Ihr Browser auf Ihrem Zugangsgerät speichern kann.
Diese Textdateien enthalten eine charakteristische Zeichenkette, die den Browser eindeutig identifiziert, wenn Sie zu unserer Website zurückkehren.
Der Prozess des Speicherns einer Cookie-Datei wird auch als "Setzen eines Cookies" bezeichnet.
Cookies können hierbei sowohl von der Website selbst als auch von externen Webservices gesetzt werden.</p>
<h3 id="jsectionrechtsgrundlagefrdiebearbeitungpersonenbezogenerdaten">Rechtsgrundlage für die Bearbeitung personenbezogener Daten</h3>
<p>Relevant sind Art. 6 ff. DSG (Grundsätze).</p>
<p>Welche Rechtsgrundlage einschlägig ist, ergibt sich aus der später in diesem Punkt aufgeführten Cookie-Tabelle.</p>
<p>Generell gilt, dass bei Cookies, die aufgrund eines berechtigten Interesses erhoben werden, unser berechtigtes Interesse darin besteht, die Funktionalität unserer Website und der darauf eingebundenen Dienste zu gewährleisten (technisch notwendige Cookies).
Zudem kann es sein, dass die Cookies  ihre Benutzerfreundlichkeit erhöhen und eine individuellere Ansprache ermöglichen.
Hier haben wir eine Abwägung zwischen Ihren Interessen und unseren Interessen vorgenommen.</p>
<p>Mit Hilfe der Cookie-Technologie können wir einzelne Website-Besucher nur dann identifizieren, analysieren und verfolgen, wenn der Website-Besucher in die Nutzung des Cookies gem. Art. 6. Abs. 6 DSG eingewilligt hat.</p>
<h3 id="jsectionzweckderdatenbearbeitung">Zweck der Datenbearbeitung</h3>
<p>Die Cookies werden von unserer Website bzw. den externen Webservices gesetzt, um die volle Funktionalität unserer Website zu erhalten, die Benutzerfreundlichkeit zu verbessern oder um den mit Ihrer Einwilligung angegebenen Zweck zu verfolgen.
Die Cookie-Technologie ermöglicht es uns auch, einzelne Besucher anhand von Pseudonymen, z.B. einer individuellen oder zufälligen IDs, zu erkennen, so dass wir mehr individuelle Dienstleistungen anbieten können.
Details sind in der folgenden Tabelle aufgeführt.</p>
<h3 id="jsectiondauerderspeicherung">Dauer der Speicherung</h3>
<p>Die nachfolgend aufgelisteten Cookies werden in Ihrem Browser gespeichert, bis sie gelöscht werden oder, im Falle eines Session-Cookies, bis die Sitzung abgelaufen ist.
Details sind in der folgenden Tabelle aufgeführt:</p>
<table>
<thead>
<tr>
<th>Cookie-Name</th>
<th>Server</th>
<th>Anbieter</th>
<th>Zweck</th>
<th>Rechtsgrundlage</th>
<th>Speicherdauer</th>
<th>Typ</th>
</tr>
</thead>
<tbody>
<tr>
<td data-header="Cookie-Name">_ga</td>
<td data-header="Server">wös.ch</td>
<td data-header="Anbieter"><a href="#tracker-418">Google Analytics</a></td>
<td data-header="Zweck">Dieser Cookie ordnet einem User eine ID zu, damit der Webtracker die Aktionen des Nutzers unter diese ID zusammenfassen kann.</td>
<td data-header="Rechtsgrundlage">Einwilligung</td>
<td data-header="Speicherdauer">ca. 24 Monate</td>
<td data-header="Typ">Analytics</td>
</tr>
<tr>
<td data-header="Cookie-Name">_ga</td>
<td data-header="Server">wös.ch</td>
<td data-header="Anbieter"><a href="#tracker-418">Google Analytics</a></td>
<td data-header="Zweck">Dieser Cookie ordnet einem User eine ID zu, damit der Webtracker die Aktionen des Nutzers unter diese ID zusammenfassen kann.</td>
<td data-header="Rechtsgrundlage">Einwilligung</td>
<td data-header="Speicherdauer">ca. 24 Monate</td>
<td data-header="Typ">Analytics</td>
</tr>
<tr>
<td data-header="Cookie-Name">_ga_</td>
<td data-header="Server">wös.ch</td>
<td data-header="Anbieter"><a href="#tracker-418">Google Analytics</a></td>
<td data-header="Zweck">Dieses Cookie speichert im Zusammenhang mit Google Analytics bzw. Google Tag Manager eine eindeutige ID für einen Website-Besucher und verfolgt, wie der Besucher die Website nutzt.</td>
<td data-header="Rechtsgrundlage">Einwilligung</td>
<td data-header="Speicherdauer">ca. 24 Monate</td>
<td data-header="Typ">Analytics</td>
</tr>
<tr>
<td data-header="Cookie-Name">_gat_UA-</td>
<td data-header="Server">wös.ch</td>
<td data-header="Anbieter"><a href="#tracker-418">Google Analytics</a></td>
<td data-header="Zweck">Dieses Cookie ist das Tracking-Cookie von Google-Analytics. Dieses Cookie speichert eine eindeutige Besucher-ID, das Datum und die Zeit des ersten Besuches, der Anfangszeitpunkt des aktiven Besuchs, sowie die Anzahl aller Besucher welche ein eindeutiger Besucher auf der Webseite gemacht hat.</td>
<td data-header="Rechtsgrundlage">Einwilligung</td>
<td data-header="Speicherdauer">ca. 60 Sekunden</td>
<td data-header="Typ">Analytics</td>
</tr>
<tr>
<td data-header="Cookie-Name">_gcl_au</td>
<td data-header="Server">wös.ch</td>
<td data-header="Anbieter"><a href="#tracker-112">Google Tag Manager</a></td>
<td data-header="Zweck">Dieses Cookie wird von Google AdSense verwendet um die Effizienz der Werbung zu erhöhen.</td>
<td data-header="Rechtsgrundlage">Einwilligung</td>
<td data-header="Speicherdauer">ca. 3 Monate</td>
<td data-header="Typ">Marketing</td>
</tr>
<tr>
<td data-header="Cookie-Name">_gid</td>
<td data-header="Server">wös.ch</td>
<td data-header="Anbieter"><a href="#tracker-418">Google Analytics</a></td>
<td data-header="Zweck">Dieser Cookie ordnet einem User eine ID zu, damit der Webtracker die Aktionen des Nutzers unter diese ID zusammenfassen kann.</td>
<td data-header="Rechtsgrundlage">Einwilligung</td>
<td data-header="Speicherdauer">ca. 24 Stunden</td>
<td data-header="Typ">Analytics</td>
</tr>
<tr>
<td data-header="Cookie-Name">_hjSessionUser_</td>
<td data-header="Server">.wös.ch</td>
<td data-header="Anbieter"><a href="#responsible-entity">Webseitenbetreiber</a></td>
<td data-header="Zweck">Das Cookie vergibt eine Nutzer-ID. Es wird gesetzt, wenn ein Nutzer zum ersten Mal eine Website mit Hotjar-Skript aufruft. Dadurch wird sichergestellt, dass das Nutzungsverhalten auch bei späteren Seitenbesuchen derselben Nutzer-ID zugeordnet wird</td>
<td data-header="Rechtsgrundlage">Einwilligung</td>
<td data-header="Speicherdauer">ca. 12 Monate</td>
<td data-header="Typ">Analytics</td>
</tr>
<tr>
<td data-header="Cookie-Name">_hjSession_</td>
<td data-header="Server">.wös.ch</td>
<td data-header="Anbieter"><a href="#responsible-entity">Webseitenbetreiber</a></td>
<td data-header="Zweck">Das Cookie speichert die aktuellen Sitzungsdaten. Dadurch wird sichergestellt, dass Anfragen im Sitzungszeitraum derselben Hotjar-Sitzung zugeordnet werden-</td>
<td data-header="Rechtsgrundlage">Einwilligung</td>
<td data-header="Speicherdauer">ca. 30 Minuten</td>
<td data-header="Typ">Analytics</td>
</tr>
<tr>
<td data-header="Cookie-Name">_hjTLDTest</td>
<td data-header="Server">.wös.ch</td>
<td data-header="Anbieter"><a href="#tracker-421">Hotjar</a></td>
<td data-header="Zweck">Wenn das Hotjar-Skript ausgeführt wird, versuchen wir, den allgemeinsten Cookie-Pfad zu ermitteln, den wir anstelle des Hostnamens der Seite verwenden sollten. Dies geschieht, damit Cookies über Subdomains hinweg gemeinsam genutzt werden können (falls zutreffend). Um dies zu ermitteln, versuchen wir, das _hjTLDTest-Cookie für verschiedene URL-Substring-Alternativen zu speichern, bis dies fehlschlägt. Nach dieser Prüfung wird das Cookie entfernt.</td>
<td data-header="Rechtsgrundlage">Einwilligung</td>
<td data-header="Speicherdauer">Sitzung</td>
<td data-header="Typ">Analytics</td>
</tr>
<tr>
<td data-header="Cookie-Name">_pk_id</td>
<td data-header="Server">.wös.ch</td>
<td data-header="Anbieter"><a href="#tracker-421">Matomo</a></td>
<td data-header="Zweck">Speichert ein paar Informationen über die Benutzer:in, z.B. unique ID (anonnym)</td>
<td data-header="Rechtsgrundlage">Einwilligung</td>
<td data-header="Speicherdauer">3 Monate</td>
<td data-header="Typ">Analytics</td>
</tr>
<tr>
<td data-header="Cookie-Name">_pk_ses, _pk_cvar, _pk_hsr</td>
<td data-header="Server">.wös.ch</td>
<td data-header="Anbieter"><a href="#tracker-421">Matomo</a></td>
<td data-header="Zweck">Kurzlebiges Cookie mit Infos über den aktuellen Besuch (anonym)</td>
<td data-header="Rechtsgrundlage">Einwilligung</td>
<td data-header="Speicherdauer">30 Minuten</td>
<td data-header="Typ">Analytics</td>
</tr>
<tr>
<td data-header="Cookie-Name">_pk_ref</td>
<td data-header="Server">.wös.ch</td>
<td data-header="Anbieter"><a href="#tracker-421">Matomo</a></td>
<td data-header="Zweck">Speichert aktuellen Referrer</td>
<td data-header="Rechtsgrundlage">Einwilligung</td>
<td data-header="Speicherdauer">6 Monate</td>
<td data-header="Typ">Analytics</td>
</tr>
<tr>
<td data-header="Cookie-Name">matomo_sessid</td>
<td data-header="Server">.wös.ch</td>
<td data-header="Anbieter"><a href="#tracker-421">Matomo</a></td>
<td data-header="Zweck">Enthält keine Daten zur Identifierzung der User, essential cookie.</td>
<td data-header="Rechtsgrundlage">Einwilligung</td>
<td data-header="Speicherdauer">14 Tage</td>
<td data-header="Typ">Analytics</td>
</tr>
</tbody>
</table>
<p id="jsectioncookiebannerconfig" style="display: none;">
Sofern Cookies auf Basis Ihrer Einwilligung gesetzt wurden, können Sie Ihre Einwilligung jederzeit in den Cookie-Einstellungen des Cookie-Banners widerrufen.
Die Cookie-Einstellungen können Sie <a href="javascript:window.document.cookieBanner.show()" class="jmdCookieConsentInjectShow">hier</a> anpassen.
</p>
<h3 id="jsectionwiderspruchsmglichkeitwiderrufdereinwilligungundlschung">Widerspruchsmöglichkeit, Widerruf der Einwilligung und Löschung</h3>
<p>Sie können Ihren Browser nach Ihren Wünschen so einstellen, dass das Setzen von Cookies generell verhindert wird.
Sie können dann von Fall zu Fall über die Annahme von Cookies entscheiden oder Cookies grundsätzlich akzeptieren.
Cookies können für verschiedene Zwecke verwendet werden, z.B. um zu erkennen, dass Ihr Zugangsgerät bereits mit unserer Website verbunden ist (permanente Cookies) oder um zuletzt angesehene Angebote zu speichern (Session-Cookies).
Wenn Sie uns ausdrücklich die Erlaubnis erteilt haben, Ihre personenbezogenen Daten zu bearbeiten können Sie diese Einwilligung jederzeit widerrufen.
Bitte beachten Sie, dass die Rechtmässigkeit der auf der Grundlage der Einwilligung bis zum Widerruf vorgenommenen Bearbeitung davon nicht berührt wird.</p></div>
<h2 id="jsectiondatensicherheitunddatenschutzkommunikationperemail">Datensicherheit und Datenschutz, Kommunikation per E-Mail</h2>
<p>Ihre personenbezogenen Daten werden durch technische und organisatorische Massnahmen so bei der Erhebung, Speicherung und Bearbeitung geschützt, dass sie für Dritte nicht zugänglich sind.
Bei einer unverschlüsselten Kommunikation per E-Mail kann die vollständige Datensicherheit auf dem Übertragungsweg zu unseren IT-Systemen von uns nicht gewährleistet werden, sodass wir bei Informationen mit hohem Geheimhaltungsbedürfnis eine verschlüsselte Kommunikation oder den Postweg empfehlen.</p>
<h2 id="jsectiondauerderspeicherungderdatenundrechtederbetroffenenperson">Dauer der Speicherung der Daten und Rechte der betroffenen Person</h2>
<h3 id="jsectiondauerderspeicherung-1">Dauer der Speicherung</h3>
<p>Wir speichern die Personendaten nur in dem Umfang und so lange, wie dies für die Erfüllung der Zwecke, für die die Personendaten erhoben wurden,
erforderlich ist, wir an der Aufbewahrung ein berechtigtes überwiegendes Interesse haben oder dazu gesetzlich verpflichtet sind.</p>
<h3 id="jsectionauskunftsanspruch">Auskunftsanspruch</h3>
<p>Sie haben das Recht, eine Bestätigung darüber zu verlangen, ob wir personenbezogene Daten von Ihnen bearbeiten.
Sofern dies der Fall ist, haben Sie ein Recht auf Auskunft über die in 25 ff. DSG benannten Informationen,
soweit die Auskunft durch den Inhaber der Datensammlung nicht verweigert, eingeschränkt oder aufgeschoben werden kann (vgl. Art. 26 f. DSG).
Gerne stellen wir Ihnen auch eine Kopie der Daten zur Verfügung.</p>
<h3 id="jsectionberichtigungsanspruch">Berichtigungsanspruch</h3>
<p>Sie haben gem. Art. 32 Abs. 1 DSG das Recht zu verlangen, dass unrichtig hinterlegte personenbezogene Daten (wie z.B. Adresse, Name, etc.) berichtigt werden, sofern diesem Anspruch keine gesetzliche Pflicht entgegensteht.
Auch können Sie jederzeit eine Vervollständigung der bei uns gespeicherten Daten verlangen.
Eine entsprechende Anpassung erfolgt unverzüglich.</p>
<h3 id="jsectionrechtauflschung">Recht auf Löschung</h3>
<p>Sie haben das Recht, dass wir die über Sie erhobenen personenbezogenen Daten löschen, wenn</p>
<ul>
<li>die Daten entweder nicht mehr benötigt werden;</li>
<li>aufgrund des Widerrufs Ihrer Einwilligung die Rechtsgrundlage der Bearbeitung ersatzlos entfallen ist;</li>
<li>keine berechtigten Gründe für die Bearbeitung mehr vorliegen;</li>
<li>Ihre Daten unrechtmässig bearbeitet werden;</li>
<li>eine rechtliche Verpflichtung dies erfordert.</li>
</ul>
<p>Das Recht besteht dann nicht, wenn</p>
<ul>
<li>die Bearbeitung zur Ausübung des Rechtes auf freie Meinungsäusserung und Information erforderlich ist;</li>
<li>Ihre Daten auf Grundlage einer rechtlichen Verpflichtung erhoben worden sind;</li>
<li>die Bearbeitung aus Gründen des öffentlichen Interesses erforderlich ist;</li>
<li>die Daten zur Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich sind.</li>
</ul>
<h3 id="jsectionrechtaufwiderruf">Recht auf Widerruf</h3>
<p>Sofern Sie uns eine ausdrückliche Einwilligung in die Bearbeitung Ihrer personenbezogenen Daten erteilt haben (Art. 6 Abs. 6 DSG und Art. 31 Abs. 1 DSG) können Sie diese jederzeit widerrufen.
Bitte beachten Sie, dass die Rechtmässigkeit der aufgrund der Einwilligung bis zum Widerruf erfolgten Bearbeitung hierdurch nicht berührt wird.
Angaben, für welche wir gesetzlich zur Aufbewahrung verpflichtet sind, werden nach Fristablauf gelöscht.</p>
<h3 id="jsectionwienehmensieihrerechtewahr">Wie nehmen Sie Ihre Rechte wahr?</h3>
<p>Ihre Rechte können Sie jederzeit wahrnehmen, indem Sie sich an die untenstehenden Kontaktdaten wenden:</p>
<p>wös.ch GmbH<br />
Hohlstrasse 192<br />
8004 Zürich<br />
Schweiz<br />
E-Mail: <a href="mailto:info@woes.ch" rel="noopener noreferrer" target="_blank">info@woes.ch</a><br />
Tel.: 044 304 22 98  </p>
<h2 id="jsectionrechtaufdatenbertragbarkeit">Recht auf Datenübertragbarkeit</h2>
<p>Wir stellen Ihnen auf Anfrage folgende Daten bereit:</p>
<ul>
<li>Daten, die aufgrund einer Einwilligung (Art. 31 Abs. 1 DSG) erhoben wurden;</li>
<li>Daten, die wir im Rahmen bestehender Verträge von Ihnen erhalten haben (Art. 31 Abs. 2 Bst. a DSG);</li>
<li>Daten, die im Rahmen eines automatisierten Verfahrens bearbeitet worden sind.</li>
</ul>
<p>Die Übertragung der personenbezogenen Daten direkt an einen von Ihnen gewünschten Verantwortlichen werden wir vornehmen, soweit dies technisch machbar ist.
Bitte beachten Sie, dass wir Daten, die in überwiegende Interessen Dritter eingreifen gem. Art 26 Abs. 1 Bst. b DSG nicht oder nur eingeschränkt übertragen dürfen.</p>
<h2 id="jsectionmitteilungenandenedbundklagemglichkeit">Mitteilungen an den EDÖB und Klagemöglichkeit</h2>
<p>Betroffene Personen können gemäss Art. 49 DSG bei der Aufsichtsbehörde eine Anzeige machen, wenn genügend Anzeichen bestehen, dass eine Datenbearbeitung gegen die Datenschutzvorschriften verstossen könnte.
Aufsichtsbehörde für den Datenschutz in der Schweiz ist der Eidgenössische Datenschutz- und Öffentlichkeitsbeauftragte (EDÖB).</p>
<p>Weiteres entnehmen Sie dem Kontaktformular des EDÖB: <a href="https://www.edoeb.admin.ch/edoeb/de/home/deredoeb/kontakt.html" rel="noopener noreferrer" target="_blank">https://www.edoeb.admin.ch/edoeb/de/home/deredoeb/kontakt.html</a></p>
<p>Sofern Sie den Verdacht haben, dass auf unserer Seite Ihre Daten rechtswidrig bearbeitet werden, können Sie gem. Art. 32 DSG eine gerichtliche Klärung der Problematik herbeiführen.
In der Regel ist hierzu eine Klage nach Art. 28 ff. ZGB anzustreben.
Sind Sie von einer Bearbeitung der Daten durch Bundesorgane betroffen richtet sich die Vorgehensweis nach Art. 41 DSG.
Auch diesfalls können Sie den EDÖB kontaktieren (siehe Hinweis auf das Kontaktformular oben).</p></div>
<br/>
Die Datenschutzerklärung dieser Website wurden kostenlos mit der All-in- One Website Datenschutzlösung <a target="_blank" href="https://www.legally-ok.com/">Legally ok</a> erstellt.
<br/><br/><br/>
                `}}>

            

            </div>

        </div >
    </div >
)

export default Datenschutz