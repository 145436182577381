import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingBasket } from '@fortawesome/free-solid-svg-icons';
import LanguageSwitcher from './LanguageSwitcher';
import { withTranslation } from 'react-i18next';

class LinksModal extends Component {
    handleLanguageSwitcherClick = (e) => {
        // Prevent event from bubbling up to modal
        e.stopPropagation();
    };

    render() {
        const { i18n, t } = this.props;
        const langPrefix = i18n.language === 'en' ? '/en' : '';

        return (
            <Modal 
                isOpen={this.props.show} 
                toggle={this.props.toggle} 
                wrapClassName="modal-left links-modal"
                modalTransition={{ timeout: 0 }}
            >
                <ModalHeader toggle={this.props.toggle}>
                    <NavLink to="/" className="navbar-brand" style={{ marginRight: "30px" }}>
                        <img style={{ height: '34px' }} src={require("../img/wos_logo.png")} alt="wös.ch" />
                    </NavLink>
                </ModalHeader>
                <ModalBody>
                    <div>
                        <ul className="navbar-nav" onClick={this.props.toggle}>
                            <li className="nav-item">
                                <NavLink to="/" className="nav-link" activeClassName="active" exact>
                                    Home
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`${langPrefix}/how-it-works`} className="nav-link" activeClassName="active">{t('header.nav.howItWorks')}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`${langPrefix}/services`} className="nav-link" activeClassName="active">{t('header.nav.services')}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`${langPrefix}/price`} className="nav-link" activeClassName="active">{t('header.nav.prices')}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`${langPrefix}/for-companies`} className="nav-link" activeClassName="active">{t('header.nav.forCompanies')}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`${langPrefix}/eco-friendly`} className="nav-link" activeClassName="active">{t('header.nav.ecoFriendly')}</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={`${langPrefix}/contact`} className="nav-link" activeClassName="active">{t('header.nav.contact')}</NavLink>
                            </li>
                            <li className="nav-item" style={{ border: "0", paddingLeft: "0px", textAlign: "center" }}>
                                <NavLink to={`${langPrefix}/products`} className="nav-link ml-auto mr-auto" activeClassName="active" style={{
                                    border: "1.4px solid",
                                    borderRadius: "25px",
                                    paddingTop: "5px",
                                    paddingBottom: "3px",
                                    textAlign: "center",
                                    verticalAlign: "middle",
                                    marginTop: "20px",
                                    width: "251px"
                                }}>
                                    {t('header.showProducts')}<FontAwesomeIcon className="ml-2" icon={faShoppingBasket} />
                                </NavLink>
                            </li>

                            <div className="language-switcher-container" onClick={this.handleLanguageSwitcherClick}>
                                <LanguageSwitcher />
                            </div>
                        </ul>
                    </div>
                </ModalBody>
            </Modal>
        )
    }
}

export default withTranslation()(LinksModal);