import React from 'react'
import { Row, Col } from 'reactstrap'
import { Form as FinalForm, Field } from 'react-final-form'
import { TextField } from 'final-form-material-ui';
import { withTranslation } from 'react-i18next';

const GiftCardItem = ({ message, email, name, t }) => {
    return (
        <>
            <FinalForm
                onSubmit={() => { }}
                initialValues={{
                    message: message,
                    email: email,
                    emailCheck: email,
                    name: name
                }}
                render={() => (
                    <>
                        <Col lg className="mt-3 mb-3 ml-2">
                            <div className="controls">
                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <Field
                                                fullWidth
                                                name="name"
                                                component={TextField}
                                                type="text"
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                label={t('cart.giftCard.recipientName')}
                                            />
                                        </div>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <Field
                                                fullWidth
                                                name="email"
                                                component={TextField}
                                                type="text"
                                                label={t('cart.giftCard.email')}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                            />
                                        </div>
                                    </Col>
                                </Row>                            
                                <Row>
                                    <Col>
                                        <div className="form-group">
                                            <div className="form-group">
                                                <Field
                                                    fullWidth
                                                    name="message"
                                                    component={TextField}
                                                    multiline
                                                    rows="3"
                                                    type="text"
                                                    label={t('cart.giftCard.message')}
                                                    InputProps={{
                                                        readOnly: true,
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                        <Col lg className="mb-3 ml-2" style={{ marginTop: "26px" }}>
                        </Col>
                    </>
                )}
            />
            <Col>
            </Col>
        </>
    )
}

export default withTranslation()(GiftCardItem)