import React from 'react'
import MetaTags from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Faq = () => {
    const { t, i18n } = useTranslation();
    
    return (
        <div className="container mx-auto">
            <MetaTags>
                <title>{t('faq.meta.title')}</title>
                <meta name="description" content={t('faq.meta.description')} />
                <meta name="image" content="https://www.wös.ch/images/logo.png" />
                <meta itemProp="name" content={t('faq.meta.title')} />
                <meta itemProp="description" content={t('faq.meta.description')} />
                <meta itemProp="image" content="https://www.wös.ch/images/logo.png" />
                <meta name="og:title" content={t('faq.meta.title')} />
                <meta name="og:description" content={t('faq.meta.description')} />
                <meta name="og:image" content="https://www.wös.ch/images/logo.png" />
                <meta name="og:url" content="https://www.wös.ch/faq" />
                <meta name="og:site_name" content="wös.ch" />
                <meta name="og:locale" content="de_CH" />
                <meta name="og:type" content="website" />
            </MetaTags>
            <Helmet>
                {i18n.language === 'de' ? (
                    <link rel="alternate" href="https://wös.ch/en/faq" hreflang="en-gb" />
                ) : (
                    <link rel="alternate" href="https://wös.ch/faq" hreflang="de" />
                )}
            </Helmet>
            <div data-packed="false" data-min-height="91"
                style={{
                    minHeight: "91px",
                    marginTop: "85px",
                    marginBottom: "0px",
                    verticalAlign: "bottom"
                }}>
                <h3 className="font_3" style={{ textAlign: "center", marginBottom: "0px", marginTop: "0px", verticalAlign: "bottom", color: "rgb(0, 0, 0)" }}>
                    {t('faq.pageTitle')}
                </h3>
            </div>
            <div data-packed="false" data-min-height="392" className="mx-auto"
                style={{
                    maxWidth: "747px",
                    minHeight: "392px",
                    marginTop: "0px",
                    marginBottom: "80px"
                }} >
                <p className="font_8" style={{
                    color: "#000000",
                    fontSize: "20px",
                    textAlign: "center",
                    fontFamily: "Brendon-Grotseque-light"
                }}>
                    {t('faq.sections.general')}
                </p>
                <p className="font_8" style={{ textAlign: "center" }}>&nbsp;</p>

                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.whatIsWoesch.question')}</p>
                <p className="font_8">{t('faq.questions.whatIsWoesch.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.processingTime.question')}</p>
                <p className="font_8">{t('faq.questions.processingTime.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.howToRegister.question')}</p>
                <p className="font_8">{t('faq.questions.howToRegister.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.isWoeschOfferedInYourArea.question')}</p>
                <p className="font_8">{t('faq.questions.isWoeschOfferedInYourArea.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.howToPlaceAnOrder.question')}</p>
                <p className="font_8">{t('faq.questions.howToPlaceAnOrder.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.howToChangePickupAndDeliveryTime.question')}</p>
                <p className="font_8">{t('faq.questions.howToChangePickupAndDeliveryTime.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.canYouUseTheServiceEveryWeekAtTheSameTime.question')}</p>
                <p className="font_8">{t('faq.questions.canYouUseTheServiceEveryWeekAtTheSameTime.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.whatHappensIfTheWoeschCourierIsLate.question')}</p>
                <p className="font_8">{t('faq.questions.whatHappensIfTheWoeschCourierIsLate.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.whatToDoIfYouHaveTechnicalDifficulties.question')}</p>
                <p className="font_8">{t('faq.questions.whatToDoIfYouHaveTechnicalDifficulties.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.howToContactWoesch.question')}</p>
                <p className="font_8">{t('faq.questions.howToContactWoesch.answer')}</p>
                <p className="font_8" style={{
                    color: "#000000",
                    fontSize: "20px",
                    textAlign: "center",
                    fontFamily: "Brendon-Grotseque-light"
                }}>
                    {t('faq.sections.beforePickup')}
                </p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.doYouNeedToSortYourClothes.question')}</p>
                <p className="font_8">{t('faq.questions.doYouNeedToSortYourClothes.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.canYouSpecifySpecialRequests.question')}</p>
                <p className="font_8">{t('faq.questions.canYouSpecifySpecialRequests.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.canThePickupOrDeliveryTakePlaceIfYouAreAbsent.question')}</p>
                <p className="font_8">{t('faq.questions.canThePickupOrDeliveryTakePlaceIfYouAreAbsent.answer')}</p>
                <p className="font_8" style={{
                    color: "#000000",
                    fontSize: "20px",
                    textAlign: "center",
                    fontFamily: "Brendon-Grotseque-light"
                }}>
                    {t('faq.sections.pricesAndBilling')}
                </p>

                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.howToPay.question')}</p>
                <p className="font_8">{t('faq.questions.howToPay.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.isThereAMinimumOrderValue.question')}</p>
                <p className="font_8">{t('faq.questions.isThereAMinimumOrderValue.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.isThereAPickupOrDeliveryFee.question')}</p>
                <p className="font_8">{t('faq.questions.isThereAPickupOrDeliveryFee.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.howToRedeemARabattCoupon.question')}</p>
                <p className="font_8">{t('faq.questions.howToRedeemARabattCoupon.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.howToUseAGiftCard.question')}</p>
                <p className="font_8">{t('faq.questions.howToUseAGiftCard.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.howMuchDoesTheWoeschServiceCost.question')}</p>
                <p className="font_8">{t('faq.questions.howMuchDoesTheWoeschServiceCost.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.whereCanYouFindYourOrders.question')}</p>
                <p className="font_8">{t('faq.questions.whereCanYouFindYourOrders.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.whatHappensIfYouForgetToPickUp.question')}</p>
                <p className="font_8">{t('faq.questions.whatHappensIfYouForgetToPickUp.answer')}</p>
                <p className="font_8" style={{
                    color: "#000000",
                    fontSize: "20px",
                    textAlign: "center",
                    fontFamily: "Brendon-Grotseque-light"
                }}>
                    {t('faq.sections.service')}
                </p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.whatWashingProductsAreUsed.question')}</p>
                <p className="font_8">{t('faq.questions.whatWashingProductsAreUsed.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.isTheWoeschServiceEcological.question')}</p>
                <p className="font_8">{t('faq.questions.isTheWoeschServiceEcological.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.areThereMaterialsYouCannotHandle.question')}</p>
                <p className="font_8">{t('faq.questions.areThereMaterialsYouCannotHandle.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.whatHappensIfAnAnnoyingStainCannotBeRemoved.question')}</p>
                <p className="font_8">{t('faq.questions.whatHappensIfAnAnnoyingStainCannotBeRemoved.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.liabilityForButtonsAndAccessories.question')}</p>
                <p className="font_8">{t('faq.questions.liabilityForButtonsAndAccessories.answer')}</p>
                <p className="font_8" style={{
                    color: "#000000",
                    fontSize: "20px",
                    textAlign: "center",
                    fontFamily: "Brendon-Grotseque-light"
                }}>
                    {t('faq.sections.washingAndDrycleaning')}
                </p>

                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.whatIsRegularWashing.question')}</p>
                <p className="font_8">{t('faq.questions.whatIsRegularWashing.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.whatIsDryCleaning.question')}</p>
                <p className="font_8">{t('faq.questions.whatIsDryCleaning.answer')}</p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.doYouNeedToSpecifyWhenSortingClothes.question')}</p>
                <p className="font_8">{t('faq.questions.doYouNeedToSpecifyWhenSortingClothes.answer')}</p>
                <p className="font_8" style={{
                    color: "#000000",
                    fontSize: "20px",
                    textAlign: "center",
                    fontFamily: "Brendon-Grotseque-light"
                }}>
                    {t('faq.sections.accountIssues')}
                </p>
                <p className="font_8" style={{ fontWeight: "bold" }}>{t('faq.questions.whatToDoIfYouHaveProblemsWithYourAccountOrPassword.question')}</p>
                <p className="font_8">{t('faq.questions.whatToDoIfYouHaveProblemsWithYourAccountOrPassword.answer')}</p>

                <p className="font_8" style={{
                    color: "#000000",
                    fontSize: "20px",
                    textAlign: "center",
                    fontFamily: "Brendon-Grotseque-light"
                }}>
                    {t('faq.sections.customerService.title')}
                </p>

                <p className="font_8">{t('faq.sections.customerService.contact')}</p>
            </div>
        </div>
    )
}

export default Faq