import React, { Component } from 'react'
import { ListGroup, ListGroupItem } from 'reactstrap'
import { connect } from 'react-redux'
import { formatNumber } from '../../Utils'
import { withTranslation } from 'react-i18next';

import * as actions from '../../store/actions'

class LeatherItem extends Component {
    render() {
        const {
            napaPrice,
            napaSelected,
            velourPrice,
            velourSelected,
            OnLeatherTypeCheck,
            itemIndex,
            t
        } = this.props

        return (
            <ListGroup flush className="shopping-item-section">
                <ListGroupItem className="item-main-header">
                    <strong>{t('cart.sections.laundry')}</strong>
                </ListGroupItem>
                {
                    (napaPrice || 0) > 0 &&
                    <ListGroupItem className="item-button" active={napaSelected}
                        onClick={() => {
                            if ((velourPrice || 0) > 0) {
                                OnLeatherTypeCheck(itemIndex)
                            }
                        }}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">{t('cart.options.napa')}</div>
                            <div className="small-price">
                                {formatNumber(napaPrice)}
                            </div>
                        </div>
                    </ListGroupItem>
                }
                {
                    (velourPrice || 0) > 0 &&
                    <ListGroupItem className="item-button" active={velourSelected}
                        onClick={() => {
                            if ((napaPrice || 0) > 0) {
                                OnLeatherTypeCheck(itemIndex)
                            }
                        }}>
                        <div className="d-flex item-header-font">
                            <div className="item-header">{t('cart.options.velour')}</div>
                            <div className="small-price">
                                {formatNumber(velourPrice)}
                            </div>
                        </div>
                    </ListGroupItem>
                }
            </ListGroup>
        )
    }
}

const mapDispatchToProps = dispatch => {
    return {
        OnLeatherTypeCheck: (itemIndex) => dispatch(actions.leatherTypeCheck(itemIndex))
    };
};

export default connect(null, mapDispatchToProps)(withTranslation()(LeatherItem));