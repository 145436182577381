import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { UncontrolledPopover, PopoverHeader, PopoverBody } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import {
    faPlus,
    faMinus
} from '@fortawesome/free-solid-svg-icons';

const QuantityField = ({ quantity, add, remove, lg, quantityType, perKilo, itemIndex, t }) =>
    (
        <div style={!lg ? { display: "flex" } : {}}>
            <span className="quantity-label">
                {quantityType === "m2" ? t('cart.quantity.m2') : t('cart.quantity.label')}
                {
                    perKilo && 
                    <React.Fragment>
                        <FontAwesomeIcon className="ml-1" style={{ color: "#2a4a91" }} icon={faInfoCircle} id={`perkilo_${itemIndex}`} />
                        <UncontrolledPopover trigger="hover" placement="right" target={`perkilo_${itemIndex}`}>
                            <PopoverHeader>{t('cart.quantity.noScale.title')}</PopoverHeader>
                            <PopoverBody>{t('cart.quantity.noScale.description')}</PopoverBody>
                        </UncontrolledPopover>
                    </React.Fragment>
                }
            </span>
            <div className="quantity-control">
                <button className="quantity-button ripple" disabled={quantity === 1} onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    remove()
                }}>
                    <FontAwesomeIcon size="sm" icon={faMinus} />
                </button>
                <span className="quantity-number">{quantity}</span>
                <button className="quantity-button ripple" onClick={(e) => {
                    e.stopPropagation()
                    e.preventDefault()
                    add()
                }}>
                    <FontAwesomeIcon size="sm" icon={faPlus} />
                </button>
            </div>
        </div>
    )

export default withTranslation()(QuantityField);