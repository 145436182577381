import React, { useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import Background2 from '../img/home/warum_wosch_centrata.jpg'
import fontThirsty from '../fonts/Heinberg-Textured.otf';
import fontHeinberg from '../fonts/ThirstyRoughRegularOne-webfont.woff2';
import { Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Helmet } from 'react-helmet';

const Home = () => {

    const [randomImage, setRandomImage] = useState('');
    const [isLoaded, setIsLoaded] = useState(false);
    const { t, i18n } = useTranslation();

    const getRandomImage = () => {
        const images = [
          '/images/1920/hero-1.webp 1920w, /images/1400/hero-1.webp 1400w, /images/900/hero-1.webp 900w',
          '/images/1920/hero-2.webp 1920w, /images/1400/hero-2.webp 1400w, /images/900/hero-2.webp 900w',
          '/images/1920/hero-3.webp 1920w, /images/1400/hero-3.webp 1400w, /images/900/hero-3.webp 900w',
          '/images/1920/hero-3.webp 1920w, /images/1400/hero-3.webp 1400w, /images/900/hero-3.webp 900w',
          '/images/1920/hero-4.webp 1920w, /images/1400/hero-4.webp 1400w, /images/900/hero-4.webp 900w',
          '/images/1920/hero-5.webp 1920w, /images/1400/hero-5.webp 1400w, /images/900/hero-5.webp 900w',
        ];
        const randomIndex = Math.floor(Math.random() * images.length);
        return { images: images[randomIndex], preview: images[randomIndex].split(' ')[0].replace('1920', '16')};
      }
    

    useEffect(() => {
        // Set a random image on component mount
        setRandomImage(getRandomImage());
    }, []);

    const handleImageLoad = () => {
        setIsLoaded(true);
      };

    const handleSmoothScroll = (e, targetId) => {
        e.preventDefault(); // Prevent the default anchor behavior
        const targetElement = document.getElementById(targetId);
        if (targetElement) {
         targetElement.scrollIntoView({ behavior: "smooth" });
        }
      };
      
      return (
        <div>
            <MetaTags>
                <link rel="preload" href={fontHeinberg} as="font" crossOrigin="anonymous" />
                <link rel="preload" href={fontThirsty} as="font" crossOrigin="anonymous" />
                {randomImage && (
                <>
                    <link rel="preload" as="image" href={randomImage.images.split(' ')[0]} imagesrcset={randomImage.images} imagesizes="100vw" />
                    <link rel="preload" as="image" href={randomImage.preview} />
                </>
                )}
                <title>{t('general.siteTitle')}</title>
                <meta name="description" content={t('home.metaDescription')}/>
                <meta name="image" content="https://www.wös.ch/images/logo.png" />
                <meta itemProp="name" content={t('home.metaTitle')}/>
                <meta itemProp="description" content={t('home.metaDescription')}/>
                <meta itemProp="image" content="https://www.wös.ch/images/logo.png" />
                <meta name="og:title" content={t('home.metaTitle')}/>
                <meta name="og:description" content={t('home.metaDescription')}/>
                <meta name="og:image" content="https://www.wös.ch/images/logo.png" />
                <meta name="og:url" content="https://www.wös.ch/"/>
                <meta name="og:site_name" content="wös.ch"/>
                <meta name="og:type" content="website"/>
                
            </MetaTags>
            <Helmet>
                {i18n.language === 'de' ? (
                    <link rel="alternate" href="https://wös.ch/en" hreflang="en-gb" />
                ) : (
                    <link rel="alternate" href="https://wös.ch" hreflang="de" />
                )}
            </Helmet>

            <div className="home-bg-image" style={{ minHeight: 'calc(100vh)'  }} >
                <img className={`home-bg-image-img ${isLoaded ? 'loaded' : ''}`} src={randomImage.preview} srcSet={randomImage.images} alt={t('home.heroImageAlt')} onLoad={handleImageLoad}></img>
                <div className="home-bg-image-bg" style={{ zIndex: 0, backgroundImage: `url(${randomImage.preview})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat'  }}></div>
                <h1 className="home-title">
                    <span className="thirsty-rough"><span>{t('home.heroTitle')}</span></span><br/>
                    <span className="heinberg"><span>{t('home.heroSubtitle')}</span></span>
                </h1>
                <div className="home-first-image-div t1">
                    <p className="home-first-image-p" style={{ textAlign: "center" }}>{t('home.heroText1')}</p>
                    <p className="home-first-image-p" style={{ textAlign: "center" }}>{t('home.heroText2')}</p>
                </div>
                <div className="home-actions">
                    <Link to={i18n.language === 'en' ? '/en/products' : '/products'} role="button" className="">
                        {t('home.ctaBasket')}
                    </Link>
                    <a href="#how-it-works" role="button" className='' onClick={(e) => handleSmoothScroll(e, 'how-it-works')}>
                        {t('home.ctaHowItWorks')}
                    </a>
                </div>
                <img alt={t('home.logoAlt')} src={require('../img/logo_white.png')} className="home-logo" />
            </div>

            <div className="pricing-page-steps-block" id="how-it-works">
                <div className="container">
                    <h2 className="carousel-text-header h1" style={{ textAlign: 'center', position: 'relative', paddingTop: "60px" }}>{t('home.howItWorksTitle')}</h2>
                    <div className="row" style={{ paddingBottom: "60px", paddingTop: "40px" }}>
                        <div className="col-lg-3 services-box" style={{ textAlign: "center" }}>
                            <img alt="" src={require('../img/how-it-works/1.png')} style={{ height: "140px", paddingLeft: "46px" }} />
                            <h2 className="service-box-header-text h2" style={{ lineHeight: "50px" }}>{t('home.howItWorksStep1Title')}</h2>
                            <p className="service-box-text t2">{t('home.howItWorksStep1Text')}</p>
                        </div>
                        <div className="col-lg-3 services-box">
                            <img alt="" src={require('../img/how-it-works/24.png')} style={{ height: "140px" }} />
                            <h2 className="service-box-header-text h2" style={{ lineHeight: "50px" }}>{t('home.howItWorksStep2Title')}</h2>
                            <p className="service-box-text t2">{t('home.howItWorksStep2Text')}</p>
                        </div>
                        <div className="col-lg-3 services-box">
                            <img alt="" src={require('../img/how-it-works/3.png')} style={{ height: "140px" }} />
                            <h2 className="service-box-header-text h2" style={{ lineHeight: "50px" }}>{t('home.howItWorksStep3Title')}</h2>
                            <p className="service-box-text t2">{t('home.howItWorksStep3Text')}</p>
                        </div>
                        <div className="col-lg-3 services-box">
                            <img alt="" src={require('../img/how-it-works/24.png')} style={{ height: "140px" }} />
                            <h2 className="service-box-header-text h2" style={{ lineHeight: "50px" }}>{t('home.howItWorksStep4Title')}</h2>
                            <p className="service-box-text t2">{t('home.howItWorksStep4Text')}</p>
                        </div>
                        <div className="col-lg-12">
                            <div className="home-actions">
                                <a href="/products" role="button" className="btn-blue">{t('home.showProducts')}</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ background: `url(${Background2}) no-repeat`, backgroundSize: 'cover', minHeight: "800px", position: 'relative', zIndex: '2', backgroundPosition: "50% 50%" }} >
                <div className="container" style={{ textAlign: "center" }}>
                    <h2 className="carousel-text-header h1" style={{ position: 'relative', paddingTop: "120px" }}>{t('home.whyTitle')}</h2>
                    <p className="carousel-text-content with-bg t1">{t('home.whySubtitle')}</p>
                    <picture>
                        <source srcSet={require('../img/home/stitching_warum_mobile.png')} media="(max-width: 590px)"></source>
                        <img alt={t('home.whyImageAlt')} src={require('../img/home/stitching_warum_wösch.png')} className="img-why-wos" />
                    </picture>
                    <br />
                    <p className="carousel-text-content t1" style={{ paddingTop: "155px" }}>{t('home.whyText1')}</p>
                    <br />
                    <p className="carousel-text-content t1">{t('home.whyText2')}</p>
                    <br />
                    <p className="carousel-text-content t1" style={{ paddingBottom: "50px"}}>{t('home.whyText3')}</p>
                </div>
            </div>

            <div className="container" style={{ paddingTop: "40px", paddingBottom: "40px" }}>
                <h2 className="home-page-header h1" style={{paddingBottom: "30px"}}>{t('home.serviceTitle')}</h2>
                <p className="home-page-text t1">{t('home.serviceDescription')}</p>
            </div>

            <div className="services-block container" style={{ paddingTop: "40px", paddingBottom: "30px" }}>
                <div className="row">
                    <div className="col-lg-3 services-box">
                        <img className="services-icon" alt="Services" src={require('../img/wos/qualität.png')} />
                        <h3 className="service-box-header-text h2">{t('home.features.quality.title')}</h3>
                        <p className="service-box-text t2">{t('home.features.quality.text')}</p>
                    </div>
                    <div className="col-lg-3 services-box">
                        <img className="services-icon" alt="Services" src={require('../img/wos/green.png')} />
                        <h3 className="service-box-header-text h2">{t('home.features.green.title')}</h3>
                        <p className="service-box-text t2">{t('home.features.green.text')}</p>
                    </div>
                    <div className="col-lg-3 services-box">
                        <img className="services-icon" alt="Services" src={require('../img/wos/smart.png')} />
                        <h3 className="service-box-header-text h2">{t('home.features.smart.title')}</h3>
                        <p className="service-box-text t2">{t('home.features.smart.text')}</p>
                    </div>
                    <div className="col-lg-3 services-box">
                        <img className="services-icon" alt="Services" src={require('../img/wos/24Hours.png')} />
                        <h3 className="service-box-header-text h2">{t('home.features.hours24.title')}</h3>
                        <p className="service-box-text t2">{t('home.features.hours24.text')}</p>
                    </div>
                </div>
            </div>

            <div style={{backgroundColor: "#ececea" }}>
                <div className="container" style={{ paddingTop: "40px"}}>
                    <h2 className="home-page-header h1" style={{ paddingBottom: "30px"}}>{t('home.history.title')}</h2>
                    <p className="home-page-text t2">{t('home.history.text')}</p>
                </div>
                <br/>
                <div className="container" style={{ marginTop: "55px", position: "relative" }}>
                    <h2 className="home-page-header h1" style={{ paddingBottom: "30px"}}>{t('home.values.title')}</h2>
                    <p className="home-page-text t2">{t('home.values.description')}</p>
                    <div className="services-block container t2" style={{ marginTop: "55px", paddingBottom: "40px" }}>
                        <div className="row">
                            <div className="col-lg-3">
                                <p className='home-page-text-small' style={{ textAlign: "left", fontSize:"16px" }}>
                                    <span style={{ fontFamily: "Brendon-Grotseque-medium" }}>* {t('home.values.value1.title')}</span>
                                    <br />
                                    {t('home.values.value1.text')}
                                </p>
                            </div>
                            <div className="col-lg-3">
                                <p className='home-page-text-small' style={{ textAlign: "left", fontSize:"16px" }}>
                                    <span style={{ fontFamily: "Brendon-Grotseque-medium" }}>* {t('home.values.value2.title')}</span>
                                    <br />
                                    {t('home.values.value2.text')}
                                </p>
                            </div>
                            <div className="col-lg-3">
                                <p className="home-page-text-small" style={{ textAlign: "left", fontSize:"16px" }}>
                                    <span style={{ fontFamily: "Brendon-Grotseque-medium" }}>* {t('home.values.value3.title')}</span>
                                    <br />
                                    {t('home.values.value3.text')}
                                </p>
                            </div>
                            <div className="col-lg-3">
                                <p className="home-page-text-small" style={{ textAlign: "left", fontSize:"16px" }}>
                                    <span style={{ fontFamily: "Brendon-Grotseque-medium" }}>* {t('home.values.value4.title')}</span>
                                    <br />
                                    {t('home.values.value4.text')}
                                </p>
                            </div>
                            <img alt={t('home.values.valuesImageAlt')} src={require('../img/home/stitching_unsere_werte.png')} className="img-our-values" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
        
export default Home