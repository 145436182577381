import React, { Component } from 'react';
import MetaTags from 'react-meta-tags';
import Background from '../img/firmen.png';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';

const delay = t => new Promise(resolve => setTimeout(resolve, t));

class ForCompanies extends Component {
    state = {
        email: '',
        sendButtonText: 'SEND'
    }

    onSubmit = async () => {
        const { t } = this.props;
        
        // eslint-disable-next-line no-useless-escape
        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))) {
            this.setState({ sendButtonText: t('forCompanies.invalid') })
            await delay(500)
            this.setState({ sendButtonText: t('forCompanies.send') })
        } else {
            this.setState({ sendButtonText: t('forCompanies.sending') })
            try {
                await fetch('/api/newsletters', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ email: this.state.email })
                });
                this.setState({ sendButtonText: t('forCompanies.sent') })
                await delay(500)
                this.setState({ sendButtonText: t('forCompanies.send'), email: '' })
            } catch (error) {
                this.setState({ sendButtonText: t('forCompanies.error') })
                await delay(500)
                this.setState({ sendButtonText: t('forCompanies.send') })
            }
        }
    }

    render() {
        const { t } = this.props;

        return (
            <div>
                <MetaTags>
                    <title>{t('forCompanies.metaTitle')}</title>
                    <meta name="description" content={t('forCompanies.metaDescription')} />
                    <meta name="image" content="https://www.wös.ch/images/naehmaschine.png" />
                    <meta itemProp="name" content={t('forCompanies.metaTitle')} />
                    <meta itemProp="description" content={t('forCompanies.metaDescription')} />
                    <meta itemProp="image" content="https://www.wös.ch/images/naehmaschine.png" />
                    <meta name="og:title" content={t('forCompanies.metaTitle')} />
                    <meta name="og:description" content={t('forCompanies.metaDescription')} />
                    <meta name="og:image" content="https://www.wös.ch/images/naehmaschine.png" />
                    <meta name="og:url" content="https://www.wös.ch/for-companies" />
                </MetaTags>
                <Helmet>
                    {i18next.language === 'de' ? (
                        <link rel="alternate" href="https://wös.ch/en/for-companies" hreflang="en-gb" />
                    ) : (
                        <link rel="alternate" href="https://wös.ch/for-companies" hreflang="de" />
                    )}
                </Helmet>

                <div style={{ background: `url(${Background}) no-repeat`, backgroundSize: 'cover', minHeight: "calc(100vh - 65px)", backgroundPosition: "50% 50%" }} >
                    <div className="container" style={{ paddingBottom: "10px", textAlign: "center", maxWidth: "900px" }}>
                        <h1 className="carousel-text-header h1" style={{ textAlign: "center", paddingTop: "140px" }}>
                            {t('forCompanies.title')}
                        </h1>
                        <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                            {t('forCompanies.subtitle')}
                        </p>
                        <img alt="" src={require('../img/stitching_fur_firmen.png')} className="img-company" />
                        <p className="carousel-text-content t1" style={{ textAlign: "center", paddingTop: "100px" }}>
                            {t('forCompanies.description1')}
                        </p>
                        <br />
                        <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                            {t('forCompanies.description2')}
                        </p>
                        <br />
                        <p className="carousel-text-content t1" style={{ textAlign: "center" }}>
                            {t('forCompanies.requestAgreement')}
                        </p>
                        <div className="newsletter">
                            <input type="email"
                                className="for-companies-mail-input"
                                placeholder={t('forCompanies.emailPlaceholder')}
                                pattern="^.+@.+\.[a-zA-Z]{2,63}$"
                                value={this.state.email}
                                onChange={(e) => this.setState({ email: e.target.value })} />
                            <button
                                className="for-companies-mail-input-btn"
                                onClick={this.onSubmit}>
                                <span>{t(this.state.sendButtonText)}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default withTranslation()(ForCompanies);