import React from 'react'
import { Row, Col } from 'reactstrap'
import { formatNumber } from '../../../Utils'
import LeatherItem from './LeatherItem'
import PerKiloItem from './PerKiloItem'
import LanudryItem from './LaundryItem'
import TailoringItem from './TailoringItem'
import DeliveryItem from './DeliveryItem'
import AdditionalItem from './AdditionalItem'
import GiftCardItem from './GiftCardItem'
import { useTranslation } from 'react-i18next';

export default ({ item }) => {
    const { i18n } = useTranslation();
    const currentLanguage = i18n.language.split('-')[0];
    return (
        <div style={{
            marginBottom: "5px",
            border: "1px solid #686a6a",
            marginLeft: "2px",
            marginRight: "2px"
        }}>
            <Row className="justify-content-md-center cart-item" style={{
                marginLeft: "0px",
                marginRight: "0px"
            }}>
                <Col lg className="image-price-container">
                    <img src={item.imgUrl}
                        alt="product"
                        className="image-price-container-image" width="200" height="200" />
                    <div style={{ width: "100%" }}>
                        <div>
                            <div style={{ textTransform: "uppercase", fontWeight: "600" }}>{currentLanguage === 'en' ? item.nameEn : item.name}</div>
                            <div style={{ paddingTop: "10px" }}>
                                {currentLanguage === 'en' ? item.descriptionEn : item.description}
                                {
                                    item.category == '5c98b952d2f2fd13b47044ae' &&
                                    (item.description ? ' (' : '') + (item.category == '5c98b952d2f2fd13b47044ae' ? (currentLanguage === 'en' ? 'Kids' : 'Kinder') : '') + (item.description ? ')' : '')
                                }
                            </div>
                        </div>
                        <div>
                            <div className="quantity-price-box">
                                <div className="quantity-price-part">
                                    Menge: {item.itemQuantity}
                                </div>
                                <div className="price-cart-item price-cart-item-left">
                                    {formatNumber(item.sumPrice)}
                                    <span className="ml-1" style={{ fontSize: "12px" }}>&#67;&#72;&#70;</span></div>
                            </div>
                        </div>
                    </div>
                </Col>
                {
                    item.giftCard && <GiftCardItem {...item.giftCard} />
                }
                {
                    !item.giftCard &&
                    <>
                        <Col lg>
                            {
                                item.leather &&
                                <LeatherItem {...item.leather} />
                            }
                            {
                                item.laundry &&
                                item.laundry.perKilo &&
                                <PerKiloItem {...item.laundry} />
                            }
                            {
                                item.laundry &&
                                !item.laundry.perKilo &&
                                <LanudryItem cleaningNotWashing={item.description.indexOf('Seide') > -1 || item.description.indexOf('Silk') > -1} {...item.laundry} />
                            }
                        </Col>
                        <Col lg>
                            {
                                item.tailoring &&
                                <TailoringItem {...item.tailoring} />
                            }
                        </Col>
                        <Col lg>
                            {
                                item.delivery &&
                                <DeliveryItem {...item.delivery} />
                            }
                        </Col>
                        <Col lg>
                            {
                                item.additional &&
                                <AdditionalItem {...item.additional} />
                            }
                        </Col>
                    </>
                }
                <Col lg className="price-section">
                    <div className="price-cart-item price-cart-item-right">
                        {formatNumber(item.sumPrice)}
                        <span className="ml-1" style={{ fontSize: "12px" }}>&#67;&#72;&#70;</span></div>
                </Col>
            </Row>
            {
                item.notes && <Row className="justify-content-md-center cart-item" style={{
                    marginLeft: "0px",
                    marginRight: "0px"
                }}>
                    <Col lg className="image-price-container" style={{ minHeight: "unset" }}>
                    </Col>
                    <Col className="m-0">
                        <Row className="d-flex justify-content-end" style={{ backgroundColor: "#b0c8db", padding: "5px" }}>
                            Hinweis: {item.notes}
                        </Row>
                    </Col>
                    <Col lg className="price-section">
                    </Col>
                </Row>
            }
        </div>
    )
}