import * as actionTypes from '../actions/actionTypes'

const initialState = {
    loading: false,
    categories: [
        { "_id": "0", "name": "all" },
        { "_id": "5c98b94fd2f2fd13b47044a5", "name": "clothing" },
        { "_id": "5c98b950d2f2fd13b47044a6", "name": "outdoor-sports" },
        { "_id": "5c98b950d2f2fd13b47044a7", "name": "festive-wear" },
        { "_id": "5c98b950d2f2fd13b47044a8", "name": "accessories" },
        { "_id": "5c98b950d2f2fd13b47044a9", "name": "home-textiles" },
        { "_id": "5c98b951d2f2fd13b47044aa", "name": "leather" },
        { "_id": "5c98b951d2f2fd13b47044ab", "name": "curtain-cleaning" },
        { "_id": "5c98b951d2f2fd13b47044ac", "name": "pets" },
        { "_id": "5c98b951d2f2fd13b47044ad", "name": "working-clothes" },
        { "_id": "5c98b952d2f2fd13b47044ae", "name": "childrens-clothing" },
        { "_id": "5e3ff9a54a23e834dc60b58e", "name": "gift-card" }
    ],
    loaded: true,
    selectedCategory: ""
}

const selectCategory = (state, { categories, categoryId, selectedCategory }) => {
    const category = categories.find(t => t._id === categoryId)
    const categoryName = category ? category.name : ""
    if (selectedCategory !== categoryName)
        return ({
            ...state,
            selectedCategory: category ? (category.name === "all" ? "" : category.name) : "",
            categories: [...state.categories]
        })

    return ({
        ...state,
        categories: [...state.categories]
    })
}

export default (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.SELECT_CATEGORY:
            return selectCategory(state, action)
        default:
            return state;
    }
};