import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navbar } from "reactstrap";
import { Link, Route, Switch } from "react-router-dom";
import MetaTags from 'react-meta-tags';
import ShoppingCart from "./ShoppingCart";
import CheckoutPage from './CheckoutPage';
import pickupIconActive from '../../img/checkoutPage/pickup-active.png';
import pickUpIconInactive from '../../img/checkoutPage/pickup-inactive.png';
import deleveryIconActive from '../../img/checkoutPage/delivery-active.png';
import deleveryIconInactive from '../../img/checkoutPage/delivery-inactive.png';
import { showOnlyPaymentOnCheckout } from '../../store/reducers/shoppingCart';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';
class ShoppingCartWrapper extends Component {

    render() {

        const { t } = this.props
        const {
            showOnlyPayment,
            pickupLocationConfirmed,
            deliveryLocationConfirmed
        } = this.props
        const pickupIconSrc = pickupLocationConfirmed === true ? pickupIconActive : pickUpIconInactive;
        const deleviryIcon = deliveryLocationConfirmed === true ? deleveryIconActive : deleveryIconInactive;

        const step1 = window.location.pathname.indexOf('page1') > -1;
        
        return (
            <div>
                <MetaTags>
                    <title>{t('cart.title')} | WÖS.CH</title>
                    <meta name="description" content={t('cart.emptyCart')} />
                    <meta name="image" content="https://www.wös.ch/images/logo.png" />
                    <meta itemProp="name" content={`${t('cart.title')} | WÖS.CH`} />
                    <meta itemProp="description" content={t('cart.emptyCart')} />
                    <meta itemProp="image" content="https://www.wös.ch/images/logo.png" />
                    <meta name="og:title" content={`${t('cart.title')} | WÖS.CH`} />
                    <meta name="og:description" content={t('cart.emptyCart')} />
                    <meta name="og:image" content="https://www.wös.ch/images/logo.png" /> 
                    <meta name="og:url" content="https://www.wös.ch/shopping-cart/page1" />
                </MetaTags>
                <Helmet>
                    {i18next.language === 'de' ? (
                        <link rel="alternate" href="https://wös.ch/en/shopping-cart/page1" hreflang="en-gb" />
                    ) : (
                        <link rel="alternate" href="https://wös.ch/shopping-cart/page1"hreflang="de" />
                    )}
                </Helmet>
                <Navbar color="light" light expand="md" className="checkout-navbar">
                    <button className="progress-bar-btn">
                        <span>
                            <img style={{ width: '44px', height: '44px' }} src={require('../../img/checkoutPage/cart-active.png')} alt="" />
                        </span>
                        <span className={'label ' + (step1 ? 'active' : '')}>{t('cart.title')}</span>
                    </button>
                    {
                        !showOnlyPayment &&
                        <>
                            <div className="progress-bar-btn">
                                <span>
                                    <img style={{ width: '44px', height: '44px' }}
                                        src={pickupIconSrc}
                                        alt=""
                                    />
                                </span>

                                <span className={'label ' + (!step1 ? 'active' : '')}>{t('cart.checkout.steps.pickup')}</span>
                            </div>
                            <div className="progress-bar-btn">
                                <span>
                                    <img
                                        style={{ width: '44px', height: '44px' }}
                                        src={deleviryIcon}
                                        alt=""
                                    />
                                </span>
                                <span className={'label ' + (!step1 ? 'active' : '')}>{t('cart.checkout.steps.delivery')}</span>
                            </div>
                        </>
                    }
                    <div className="progress-bar-btn">
                        <span>
                            <img style={{ width: '44px', height: '44px' }} src={require('../../img/checkoutPage/payment-inactive.png')} alt="" />
                        </span>
                        <span className={'label visible-desktop ' + (!step1 ? 'active' : '')}>{t('cart.checkout.steps.payment')}</span>
                    </div>
                    <span className="ml-auto">
                        <Link to={this.props.i18n.language === 'en' ? '/en/products' : '/products'} style={{ textAlign: "center", verticalAlign: "center", color: "#2a4a9a" }}>
                           
                        </Link>
                    </span>
                </Navbar>
                <Switch>
                    <Route path={"/en/shopping-cart/page1"} component={ShoppingCart} />
                    <Route path={"/shopping-cart/page1"} component={ShoppingCart} />
                    <Route path={"/en/shopping-cart/page2"} component={CheckoutPage} />
                    <Route path={"/shopping-cart/page2"} component={CheckoutPage} />
                </Switch>
            </div >
        )
    }
}

const mapStateToProps = state => ({
    pickupLocationConfirmed: state.shoppingCart.pickupLocationConfirmed,
    deliveryLocationConfirmed: state.shoppingCart.deliveryLocationConfirmed,
    showOnlyPayment: showOnlyPaymentOnCheckout(state)
});

export default connect(mapStateToProps)(withTranslation()(ShoppingCartWrapper));