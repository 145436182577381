/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Col } from 'reactstrap'
import { Form as FinalForm, Field } from 'react-final-form'
import { TextField, Select } from 'final-form-material-ui';
import MenuItem from '@material-ui/core/MenuItem';
import { FORM_ERROR } from "final-form";
import MetaTags from 'react-meta-tags';
import Background from '../img/contact/photo_laundry.png';
import { withTranslation } from 'react-i18next';
import i18next from 'i18next';
import { Helmet } from 'react-helmet';


const validate = (values, t) => {
    const errors = {};

    if (!values.lastName) {
        errors.lastName = t('contact.form.errors.lastNameRequired')
    }

    if (!values.email) {
        errors.email = t('contact.form.errors.emailRequired')
    } else {
        // eslint-disable-next-line no-useless-escape, no-control-regex
        const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
        if (!expression.test(String(values.email).toLowerCase())) {
            errors.email = t('contact.form.errors.invalidEmail')
        }
    }
    if (!values.name) {
        errors.name = t('contact.form.errors.nameRequired')
    }
    if (!values.message) {
        errors.message = t('contact.form.errors.messageRequired')
    }

    return errors;
}

const delay = t => new Promise(resolve => setTimeout(resolve, t));

class Contact extends Component {
    state = {
        buttonText: 'contact.form.requestQuote'
    }

    onSubmit = async (values) => {
        try {
            await fetch('/api/messages', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Accept-Language": i18next.language.split('-')[0]
                },
                body: JSON.stringify(values)
            });
            await delay(1000);
        } catch (error) {
            return { [FORM_ERROR]: this.props.t('contact.form.errors.submitError') };
        }
    }

    render() {
        const { buttonText } = this.state;
        const { t } = this.props;

        return (
            <React.Fragment>
                <div className="" style={{ marginBottom: "71px" }}>
                    <MetaTags>
                        <title>{t('contact.metaTitle')}</title>
                        <meta name="description" content={t('contact.metaDescription')} />
                        <meta name="image" content="https://www.wös.ch/images/logo.png" />
                        <meta itemProp="name" content={t('contact.metaTitle')} />
                        <meta itemProp="description" content={t('contact.metaDescription')} />
                        <meta itemProp="image" content="https://www.wös.ch/images/logo.png" />
                        <meta name="og:title" content={t('contact.metaTitle')} />
                        <meta name="og:description" content={t('contact.metaDescription')} />
                        <meta name="og:image" content="https://www.wös.ch/images/logo.png" />
                        <meta name="og:url" content="https://www.wös.ch/contact" />
                    </MetaTags>
                    <Helmet>
                        {i18next.language === 'de' ? (
                            <link rel="alternate" href="https://wös.ch/en/contact" hreflang="en-gb" />
                        ) : (
                            <link rel="alternate" href="https://wös.ch/contact" hreflang="de" />
                        )}
                    </Helmet>

                    <div className="services-page jumbotron" style={{ textAlign: 'center', backgroundColor: "white" }}>
                        <h1 className="carousel-text-header h1" style={{ paddingBottom: "20px", paddingTop: "15px" }}>
                            {t('contact.title')}
                        </h1>
                        <p className="carousel-text-content py-2 t1">
                            {t('contact.description')}
                        </p>
                    </div>
                    <img alt="" src={require('../img/stitching_kontakt.png')} className="img-contact" />
                    <div className="row p-0 m-0">
                        <Col md="6" style={{ minHeight: "402px", background: `url(${Background}) no-repeat`, backgroundSize: 'cover', backgroundPosition: "50% 50%" }}>
                        </Col>
                        <Col md="6" style={{ paddingLeft: "25px", paddingRight: "15px" }}>
                            <h2 style={{
                                fontFamily: "'holtzman-textured'",
                                lineHeight: "17px",
                                margin: "0px",
                                textAlign: "start"
                            }} className="h2">{t('contact.form.title')}</h2>
                            <FinalForm
                                onSubmit={this.onSubmit}
                                validate={(values) => validate(values, t)}
                                initialValues={{ service: t('contact.form.services.laundry') }}
                                subscription={{
                                    submitting: true,
                                    pristine: true,
                                    dirtySinceLastSubmit: true,
                                    submitSucceeded: true,
                                    submitError: true,
                                    submitErrors: true,
                                    hasValidationErrors: true
                                }}
                                render={({
                                    handleSubmit, submitting, pristine, validating, hasValidationErrors, form
                                }) => (
                                    <form
                                        onSubmit={event => {
                                            handleSubmit(event).then(
                                                async () => {
                                                    form.restart();
                                                    this.setState({ buttonText: 'contact.form.sent' });
                                                    await delay(5000);
                                                    this.setState({ buttonText: 'contact.form.requestQuote' });
                                                })
                                        }}
                                        className="custom-form form"
                                        style={{ maxWidth: "477px" }}>
                                        <div className="controls">
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <Field
                                                            fullWidth
                                                            name="name"
                                                            component={TextField}
                                                            type="text"
                                                            placeholder={t('contact.form.firstName')}
                                                            label={t('contact.form.firstName')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <Field
                                                        fullWidth
                                                        name="lastName"
                                                        component={TextField}
                                                        type="text"
                                                        placeholder={t('contact.form.lastName')}
                                                        label={t('contact.form.lastName')}
                                                    />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <Field
                                                            fullWidth
                                                            name="email"
                                                            component={TextField}
                                                            type="text"
                                                            placeholder={t('contact.form.email')}
                                                            label={t('contact.form.email')}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <Field
                                                            fullWidth
                                                            name="phone"
                                                            component={TextField}
                                                            type="text"
                                                            placeholder={t('contact.form.phone')}
                                                            label={t('contact.form.phone')}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-sm-6">
                                                    <div className="form-group">
                                                        <Field
                                                            name="service"
                                                            label={t('contact.form.selectService')}
                                                            formControlProps={{ fullWidth: true }}
                                                            component={Select}
                                                            placeholder={t('contact.form.selectService')}
                                                        >
                                                            <MenuItem value={t('contact.form.services.laundry')}>{t('contact.form.services.laundry')}</MenuItem>
                                                            <MenuItem value={t('contact.form.services.tailoring')}>{t('contact.form.services.tailoring')}</MenuItem>
                                                            <MenuItem value={t('contact.form.services.other')}>{t('contact.form.services.other')}</MenuItem>
                                                        </Field>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <div className="form-group">
                                                    <Field
                                                        fullWidth
                                                        name="message"
                                                        component={TextField}
                                                        multiline
                                                        rows="5"
                                                        type="text"
                                                        placeholder={t('contact.form.comment')}
                                                        label={t('contact.form.comment')}
                                                    />
                                                </div>
                                            </div>
                                            <button
                                                disabled={submitting || validating || pristine || hasValidationErrors}
                                                type="submit"
                                                className='btn message-btn' 
                                                style={{ width: "100%", height: "39px" }}>
                                                {submitting ? t('contact.form.sending') : t(buttonText)}
                                            </button>
                                        </div>
                                    </form>
                                )}
                            />
                        </Col>
                    </div>
                </div>
                <div style={{ marginBottom: "-7px" }}>
                    <div className="mapouter">
                        <div className="gmap_canvas">
                            <iframe title="Map" width="100%" height="600" id="gmap_canvas"
                                src="https://maps.google.com/maps?q=Hohlstrasse%20192%208004%20Z%C3%BCrich&t=&z=15&ie=UTF8&iwloc=&output=embed"
                                frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0"></iframe>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

export default withTranslation()(Contact);