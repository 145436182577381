import React, { Component } from 'react'
import { Form as FinalForm, Field } from 'react-final-form'
import { Form, FormGroup, Button } from 'reactstrap'
import { connect } from 'react-redux';
import { FORM_ERROR } from "final-form";
import { TextField } from 'final-form-material-ui';
import { withTranslation } from 'react-i18next';

import * as actions from '../../store/actions'

const emailExists = async (value, t) => {
    if (!value) {
        return t('userProfile.validation.emailRequired')
    }

    // eslint-disable-next-line no-useless-escape, no-control-regex
    const expression = /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;

    if (!expression.test(String(value).toLowerCase())) {
        return t('userProfile.validation.invalidEmail')
    }
}

class ResetPassword extends Component {
    onSubmit = async (values) => {
        const { OnSendPasswordReset, t } = this.props
        try {
            await new Promise((resolve, reject) => {
                OnSendPasswordReset(values.email, resolve, reject);
            });
        } catch (error) {
            switch (error.error) {
                case 1:
                    return { [FORM_ERROR]: t('auth.modal.resetPassword.errors.emailNotFound') };
                case 2:
                    return { [FORM_ERROR]: t('auth.modal.resetPassword.errors.passwordMismatch') };
                default:
                    return { [FORM_ERROR]: t('userProfile.validation.generalError') };
            }
        }
    }

    render() {
        const { token, OnResetPasswordModalToggle, t } = this.props

        return (
            <React.Fragment>
                <div style={{ height: "10px" }}></div>
                <FinalForm
                    onSubmit={this.onSubmit}
                    initialValues={{ token: token }}
                    render={({
                        submitError,
                        handleSubmit,
                        submitting,
                        pristine,
                        validating,
                        hasValidationErrors
                    }) => (
                        <Form onSubmit={handleSubmit}>
                            {validating && <p>{t('auth.modal.resetPassword.checkingEmail')}</p>}
                            <FormGroup>
                                <Field
                                    validate={(value) => emailExists(value, t)}
                                    fullWidth
                                    required
                                    name="email"
                                    component={TextField}
                                    type="text"
                                    placeholder={t('userProfile.form.email')}
                                    label={t('userProfile.form.email')}
                                    autoComplete="username"
                                />
                            </FormGroup>
                            {submitError && <div className="error">{submitError}</div>}
                            <br />
                            <div className="row justify-content-between">
                                <Button
                                    className="ml-3"
                                    color="secondary"
                                    type="button"
                                    onClick={(e) => {
                                        e.preventDefault()
                                        e.stopPropagation()
                                        OnResetPasswordModalToggle()
                                    }}>
                                    {t('auth.modal.resetPassword.buttons.cancel')}
                                </Button>
                                <Button
                                    className="mr-3"
                                    color="primary"
                                    type="submit"
                                    disabled={submitting || validating || pristine || hasValidationErrors}>
                                    {submitting ? t('auth.modal.resetPassword.buttons.submitting') : t('auth.modal.resetPassword.buttons.submit')}
                                </Button>
                            </div>
                        </Form>
                    )}
                />
            </React.Fragment>)
    }
}

const mapDispatchToProps = dispatch => {
    return {
        OnSendPasswordReset: (email, resolve, reject) => dispatch(actions.sendPasswordReset(email, resolve, reject)),
        OnResetPasswordModalToggle: () => dispatch(actions.resetPasswordModalToggle())
    };
};

export default connect(null, mapDispatchToProps)(withTranslation()(ResetPassword))